import { Form } from '../../common'
import { useTranslation } from 'react-i18next'

const StepForm = ({ step, submit, close }) => {
  const { t } = useTranslation()
  const isNew = !step
  const formConfig = {
    fields: [
      {
        name: 'Name',
        type: 'text',
        placeholder: 'Enter Step Name',
        required: true,
        label: 'Name',
        defaultValue: isNew ? '' : step.name,
        // disabled: isFetch,
        maxLength: 30,
      },
      {
        name: 'description',
        type: 'richtext',
        placeholder: 'Enter Description',
        required: false,
        label: 'Description (optional)',
        defaultValue: isNew ? '' : step.description,
        maxLength: 400,
        // disabled: isFetch,
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject) => {
      submit(data).catch((err) => {
        reject({ global: t(err.message) })
      })
    },
    submitSlot: (
      <>
        {!isNew && (
          <button className="btn-primary" onClick={close}>
            {t('c-form-cancel-btn')}
          </button>
        )}
        <button type="submit" className="btn-secondary">
          {t(isNew ? 'c-form-create-btn' : 'c-form-update-btn')}
        </button>
      </>
    ),
    requiredMessage: 'Field required',
  }

  return (
    <div className="c-form">
      <div className="t-title c-primary">
        {t(isNew ? 'c-create-step-title' : 'c-edit-step-title')}
      </div>

      <Form config={formConfig} />
    </div>
  )
}

export default StepForm
