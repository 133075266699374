import { makeAutoObservable, computed } from 'mobx'
import { CASES_API, ANALYTICS_API } from '../../api'
import Notifications from '../MessagesStore'
class CasePreviewStore {
  activeCase = null
  isFetch = false

  // Scenario control
  activeStage = null
  activeStep = null
  activeComment = null

  isFinished = false
  isPreview = true
  // user session id
  session = null

  stepTimestamp = null

  constructor() {
    makeAutoObservable(this, {
      stage: computed,
      step: computed,
      comment: computed,
    })
  }
  /**
   * Fetch demo case, set up stage, step and comment from URL params
   * @param {String} id - demo case identifier
   * @param {object} [params] - params from URL (stage, step, comment)
   */
  async fetchUseCase(id, s) {
    this.isFetch = true
    this.resetCase()
    let search = new URLSearchParams(s)
    this.isPreview = !Boolean(search.get('session'))
    try {
      let data = null
      if (this.isPreview) {
        data = await CASES_API.getOneCase(id)
      } else {
        const ipResponse = await fetch(
          'https://api.ipify.org?format=json'
        ).then((res) => res.json())
        data = await CASES_API.getOneCaseBySessionId(id, ipResponse.ip)
        this.session = data.userSession
      }
      this.activeCase = data
      this.stepTimestamp = new Date()
    } catch (err) {
      console.log(err.message)
    }
  }

  handleImageLoaded() {
    this.isFetch = false
  }

  resetCase() {
    this.activeCase = null
    this.activeStage = null
    this.activeStep = null
    this.activeComment = null

    this.isFinished = false
    this.isPreview = true
    this.session = null
  }

  /**
   * Go to next stage or step
   * @param {String} [stageId] - stage id
   * @param {String} [stepId] - step id
   */
  next(stageId, stepId) {
    // Skip if case finished
    if (this.isFinished) return

    if (!this.isPreview) this.handleAnalytic(this.activeStage, this.activeStep)

    // Go to first stage from preview
    if (!this.activeStage) {
      this.activeStage = this.activeCase.stages[0].id
      this.activeStep = this.activeCase.stages[0].steps[0].id
      return
    }

    this.stepTimestamp = new Date()
    // got to new stage and step if has transition params
    if (stageId) {
      this.activeStage = stageId
      if (stepId) {
        this.activeStep = stepId
      } else {
        this.activeStep = this.stage.steps[0].id
      }
      return
    }

    // in other ways go to end
    this.finish()
  }

  finish() {
    if (!this.isFinished) this.handleAnalytic(this.activeStage, this.activeStep)
    this.isFinished = true

    if (this.isPreview) window.close()
  }

  unfinish() {
    this.isFinished = false
  }

  /**
   * Send analytic
   */
  async handleAnalytic(stage, step) {
    if (!stage || this.isFinished) stage = null
    try {
      await ANALYTICS_API.saveTransitionAnalytic({
        session: this.session,
        step: step,
        start: this.stepTimestamp,
        end: new Date(),
      })
    } catch (error) {
      console.log('Analytic error: ' + error.message)
    }
  }

  /**
   * Toggle comment card
   * @param {string} commentId - id or null
   */
  toggleComment(commentId) {
    this.activeComment = this.activeComment === commentId ? null : commentId
  }

  sendFeedback(data) {
    return CASES_API.sendFeedback({
      rate: data.rate,
      text: data.feedback,
      session: this.session,
      useCase: this.activeCase.id,
    }).then((res) => {
      this.activeStage = null
      this.isFinished = false
      Notifications.addSuccess('Thanks for the feedback!')
    })
  }
  sendCallRequest(data) {
    return CASES_API.requestCall({
      email: data.email,
      phone: data.phone,
      session: this.session,
      useCase: this.activeCase.id,
    })
  }

  get stage() {
    if (this.activeStage === 'preview' || this.activeStage === 'end')
      return null

    return this.activeCase.stages.find((stage) => stage.id === this.activeStage)
  }

  get step() {
    let stage = this.stage
    if (!stage || !this.activeStep) return null

    return stage.steps.find((step) => step.id === this.activeStep)
  }

  get comment() {
    let step = this.step
    if (!step || !this.activeComment) return null

    return step.comments.find((comment) => comment.id === this.activeComment)
  }
}

const store = new CasePreviewStore()

export default store
