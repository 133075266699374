import { useState, useMemo, useEffect, useRef } from 'react'
import notifications from '../../../store/modules/MessagesStore'
import { Modal, Icon, Form } from '../../common'
import { CASES_CONSTRUCTOR_API as API } from '../../../store/api'
import UploadingIndicator from '../UploadingIndicator'
import VideoRecorder from '../VideoRecorder'

const VideoUploader = ({
  value,
  onChange,
  width,
  height,
  placeholder,
  hideRemove,
  videoHolder,
  showIndicatorInside,
  haveBrowseButton,
}) => {
  const [video, setVideo] = useState(value)
  const [preview, setPreview] = useState(null)
  const [uploadingProgress, setUploadingProgress] = useState(100)

  useEffect(() => {
    setVideo(value)
    setPreview(null)
  }, [value])

  const onSubmit = async (videoName) => {
    let data = new FormData()
    data.append('files', preview)

    if (videoHolder) {
      data.append('ref', videoHolder.ref)
      data.append('refId', videoHolder.refId)
      data.append('field', videoHolder.field)
    }

    try {
      setPreview(null)

      const res = await API.loadFile(data, (progress) => {
        setUploadingProgress(progress)
      })

      onChange({ ...res, isUpdated: true, videoName })
    } catch (err) {
      console.log(err.message)
    }
  }

  const onRemove = () => {
    onChange(null)
    setVideo(null)
  }

  const videoSrc = useMemo(() => {
    if (video instanceof File) {
      return URL.createObjectURL(video)
    } else if (video) {
      return process.env.REACT_APP_SERVER_URL + video.url
    }
    return null
  }, [video])

  const previewSrc = useMemo(() => {
    if (preview instanceof File) {
      return URL.createObjectURL(preview)
    } else if (preview) {
      return process.env.REACT_APP_SERVER_URL + preview.url
    }
    return null
  }, [preview])

  const uploadingFilename = useMemo(() => {
    if (video instanceof File) {
      return video.name
    } else if (video) {
      const urlElements = video.url.split('/')
      return urlElements[urlElements.length - 1]
    }
    return null
  }, [video])

  const styles = {
    width: width ? width + 'px' : 'auto',
    height: height ? height + 'px' : '15rem',
  }

  return (
    <>
      {(!video || uploadingProgress !== 100) ? (
        <Uploader
          onChange={setPreview}
          styles={styles}
          uploadingProgress={uploadingProgress}
          filename={uploadingFilename}
          showIndicatorInside={showIndicatorInside}
          haveBrowseButton={haveBrowseButton}
        >
          {placeholder}
        </Uploader>
      ) : (
        <Preview
          video={videoSrc}
          onRemove={onRemove}
          styles={styles}
          hideRemov={hideRemove}
        />
      )}

      {preview && (
        <UploadingPreview
          video={previewSrc}
          onClose={() => setPreview(null)}
          onSubmit={onSubmit}
          width={width}
          height={height}
        />
      )}
    </>
  )
}

export default VideoUploader

const Preview = ({ video, onRemove, styles, hideRemove }) => {
  if (video) {
    video += '#chache-breaker=' + new Date().getTime()
  }

  return (
    <div className="file-uploader preview" style={styles} onClick={onRemove}>
      <video style={styles} src={video} controls />

      {!hideRemove && (
        <button
          className="btn btn-icon remove-btn"
          type="button"
          onClick={onRemove}
        >
          <Icon icon="close" size="1.5rem" />
        </button>
      )}
    </div>
  )
}

const Uploader = ({
  children,
  onChange,
  styles,
  uploadingProgress,
  filename,
  showIndicatorInside,
  haveBrowseButton,
}) => {
  const showIndicator = uploadingProgress !== 100

  const fileInputRef = useRef(null)

  const indicator = useMemo(() => (
    showIndicator && (
      <UploadingIndicator
        filename={filename}
        progress={uploadingProgress}
      />
    )
  ), [showIndicator, uploadingProgress, filename])

  const onFileChange = (e) => {
    const video = e.target.files[0]
    onChange(video)
  }

  const onRecordingFinished = (video) => {
    onChange(video)
  }

  const onRecordingCanceled = (error) => {
    if (error) {
      notifications.addError(`An error occurred while recording: ${error}`)
    }
  }

  return (
    <>
      <div className="file-uploader record-starter">
        <VideoRecorder
          onFinished={onRecordingFinished}
          onCancel={onRecordingCanceled}
        >
          <button
            className="btn btn-secondary file-uploader record-starter__btn"
          >
            Start recording
          </button>
        </VideoRecorder>
        <span className="file-uploader record-starter__or">or</span>
      </div>
      <div className="file-uploader form" style={styles}>
        <input
          ref={fileInputRef}
          className="drag-only"
          type="file"
          accept="video/mp4,video/quicktime"
          onChange={onFileChange}
          onClick={(e) => {
            if (e.isTrusted) {
              e.preventDefault()
            }
          }}
        />
        {showIndicator && showIndicatorInside ? indicator : (
          <>
            {children}
            {haveBrowseButton && (
              <div className="file-uploader form__browse-prologue">
                <span>Or, if you prefer</span>
                <button
                  className="btn btn-secondary file-uploader form__browse-btn"
                  onClick={() => fileInputRef.current.click()}
                >
                  <span>Choose a video to upload</span>
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {!showIndicatorInside && indicator}
    </>
  )
}

const UploadingPreview = ({ onSubmit, onClose, video }) => {
  const formConfig = {
    fields: [
      {
        name: 'name',
        label: 'Name',
        type: 'text',
        placeholder: 'Enter video name',
        required: true,
        maxLength: 30,
      },
      {
        name: 'video',
        customRender: () => (
          <video controls src={video} />
        )
      }
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject, onSuccess) => {
      onSubmit(data.name)
    },
    submitSlot: (
      <div className="submit-row">
        <button
          type="button"
          className="btn-white-text"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn-primary"
        >
          Upload
        </button>
      </div>
    ),
  }

  return (
    <div className="video-preview">
      <Modal
        close={onClose}
        show
        title="Preview video"
        text={
          <Form config={formConfig} />
        }
      />
    </div>
  )
}
