import { makeAutoObservable, computed } from 'mobx'
import { getStrapiError } from '../../utils'
import { AUTH_API } from '../api'
import { eraseToken, setToken, USER_STORE_NAME } from '../axios'
import notifications from './MessagesStore'

class AuthStore {
  currentUser = null
  isFetch = false

  permissions = {
    analytics: null, // all, my, null
    users: false,
    editCase: false,
    rentCase: false,
  }
  expandedRoles = false

  constructor() {
    makeAutoObservable(this, {
      role: computed,
    })
  }

  async login({ email, password }) {
    this.isFetch = true
    try {
      let res = await AUTH_API.login(email, password)
      if (res) {
        setToken(res.jwt)
        localStorage.setItem(USER_STORE_NAME, JSON.stringify(res.user))
        this.logUser(res)
      }
      this.isFetch = false
    } catch (error) {
      this.isFetch = false
      return Promise.reject(getStrapiError(error.response.data))
    }
  }

  async register({
    company,
    email,
    password,
    name: firstName,
    surname: lastName,
    plan,
  }) {
    this.isFetch = true
    try {
      let res = await AUTH_API.registerClient({
        firstName,
        lastName,
        company,
        identifier: email,
        password,
        plan,
      })
      if (res) await this.login({ email, password })
      this.isFetch = false
    } catch (error) {
      this.isFetch = false
      return Promise.reject(error)
    }
  }

  logUser(res) {
    this.currentUser = JSON.parse(JSON.stringify(res.user))
    // set up permissions
    const userType = this.currentUser.client?.clientType
    this.expandedRoles = userType?.name === 'Enterprise'
    // Platform admin
    if (this.currentUser.role.name === 'Admin') {
      this.permissions = {
        analytics: 'all',
        users: false,
        editCase: true,
        rentCase: false,
      }
      return
    }
    // Company admin
    if (this.currentUser.role.name === 'Administrator') {
      this.permissions = {
        analytics: 'all',
        users: true,
        editCase: userType?.isCustomCases,
        rentCase: true,
      }
      return
    }
    // Contributor
    if (this.currentUser.role.name === 'Contributor') {
      this.permissions = {
        analytics: 'all',
        users: false,
        editCase: userType?.isCustomCases,
        rentCase: false,
      }
      return
    }
    // User
    if (this.currentUser.role.name === 'User') {
      this.permissions = {
        analytics: 'one',
        users: false,
        editCase: false,
        rentCase: false,
      }
      return
    }
  }

  logOut = () => {
    eraseToken()
    localStorage.removeItem(USER_STORE_NAME)
    this.currentUser = null
  }

  relogUser = async () => {
    let user = localStorage.getItem(USER_STORE_NAME)
    if (user) this.logUser({ user: JSON.parse(user) })
    return this.currentUser
  }

  async finishRegistration(data, code) {
    this.isFetch = true
    try {
      let res = await AUTH_API.resetPassword(data.password, code)
      this.logUser(res)
      this.isFetch = false
    } catch (error) {
      this.isFetch = false
      notifications.addError('Error')
      return Promise.reject(error)
    }
  }

  async forgetPassword(data) {
    this.isFetch = true
    try {
      await AUTH_API.forgetPassword(data.email)
      this.isFetch = false
    } catch (error) {
      this.isFetch = false
      return Promise.reject(error)
    }
  }

  async resetPassword(data, code) {
    this.isFetch = true
    try {
      let res = await AUTH_API.resetPassword(data.password, code)
      this.logUser(res)
      this.isFetch = false
    } catch (error) {
      this.isFetch = false
      return Promise.reject(error)
    }
  }

  async updateUser(data) {
    let user = { ...data }

    if (!user.repeatpassword) {
      delete user.password
      delete user.repeatpassword
    }

    user.firstName = user.firstname
    user.lastName = user.lastname
    delete user.firstname
    delete user.lastname

    let res = await AUTH_API.updateUser(this.currentUser.id, user)
    delete res.client
    this.currentUser = {
      ...this.currentUser,
      ...res,
    }
    localStorage.setItem(USER_STORE_NAME, JSON.stringify(this.currentUser))
  }

  get role() {
    return this.currentUser && this.currentUser.role
      ? this.currentUser.role.name
      : null
  }
}

const store = new AuthStore()

export default store
