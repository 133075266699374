import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const RichText = ({ name, value, onChange, disabled, placeholder }) => {
  return (
    <ReactQuill
      modules={{ toolbar: null }}
      theme="snow"
      value={value}
      onChange={(v) => onChange(v)}
      placeholder={placeholder}
    />
  )
}

export default RichText
