import { useEffect } from 'react'
import { useScrollLock } from '@mantine/hooks'
import { useParams, useHistory } from 'react-router-dom'
import { CaseConstructorStore as store } from '../../store'
import { observer } from 'mobx-react-lite'

import ContsructorControl from './ContsructorControl'
import CaseNavigation from './CaseNavigation'

import CaseForm from './forms/CaseForm'
import StepForm from './forms/StepForm'

import CaseView from './views/CaseView'
import StageView from './views/StageView'

import StepView from './views/StepView'

import Loader from './Loader'

const Constructor = observer(() => {
  const { id } = useParams()
  const history = useHistory()
  useEffect(
    () =>
      store.startConstructor(
        id,
        new URLSearchParams(history.location.search),
        (search) => {
          history.replace(`${history.location.pathname}?${search}`)
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  )
  const renderContent = () => {
    // Render form if activeForm
    switch (store.activeForm) {
      case 'case-info':
        return (
          <CaseForm
            useCase={store.activeCase}
            submit={(data) => {
              store.saveCase(data).then((id) => {
                if (id) history.push(`/constructor/${id}`)
              })
            }}
            close={() => store.closeForm()}
          />
        )
      case 'step-info':
        return (
          <StepForm
            step={store.step.step}
            submit={(data) => store.step.saveStep(data)}
            close={() => store.closeForm()}
          />
        )
      // comment-info and link-info forms render inside Step view
      default:
        break
    }

    if (store.stage === null) return <CaseView />

    if (store.step === null) return <StageView />

    if (store.step) return <StepView type={store.activeCase.type} store={store.step} />
  }

  useScrollLock(store.isFetch)

  if (store.isFetch) {
    return (
      <div className="constructor">
        <Loader show />
      </div>
    )
  }

  return (
    <div className="constructor">
      <ContsructorControl />

      <div className="constructor-content">
        <CaseNavigation />
        <div className="constructor-content-right">{renderContent()}</div>
      </div>
    </div>
  )
})

export default Constructor
