import { useState, useCallback, useRef } from 'react'
import Icon from '../Icon'
import { debounce } from 'lodash'

const Search = ({ onSearch }) => {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let debounceLog = useCallback(debounce(onSearch, 500), [onSearch])

  const handleChange = (e) => {
    setValue(e.target.value)
    debounceLog(e.target.value)
  }

  const searchRef = useRef()
  const startSearch = () => {
    setOpen(true)
    searchRef.current.focus()
  }

  return (
    <div className={`search ${open ? 'active' : ''}`} onClick={startSearch}>
      <Icon icon="search" size="1.5rem" />
      <input
        ref={searchRef}
        placeholder="search..."
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={() => setOpen(false)}
      />
    </div>
  )
}

export default Search
