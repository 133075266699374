import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { HomeStore, CasesStore, AuthStore, ResponsiveStore } from '../../store'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

import { CaseCardRent, CaseCardAvailable, CaseCardInProgress } from '../cases/CaseCard'
import { List, CarouselList, Icon, Skeleton, Responsive } from '../common'
import ShareForm from './ShareForm'
import { useConfirmDialog } from '../../utils/hooks'
import { getArrayWithKeys } from '../../utils'

const CasesBlock = observer(({ isCasesPage }) => {
  const store = isCasesPage ? CasesStore : HomeStore
  const tabs = store.tabs
  const activeTab = store.tab
  const changeTab = (tab) => store.changeTab(tab)
  useEffect(() => store.init(), [])

  useEffect(() => {
    const newMode = ResponsiveStore.breakpoint === 'desktop' ? 'pages' : 'cards'

    if (activeTab && newMode !== activeTab.store.mode) {
      activeTab.store.changeMode(newMode)
    }
  }, [ResponsiveStore.breakpoint, activeTab])

  const { modal: removeModal, action: onRemoveCase } = useConfirmDialog(
    (activeCase) => store.handleRemoveDemoCase(activeCase.id),
    {
      title: 'Delete Demo case',
      text: (
        <>
          <div className="t-lg c-primary">
            Do you really want to delete the demo case ?
          </div>
          <div className="t-md c-grey">
            This demo case will be impossible to restore later.
          </div>
        </>
      ),
      confirmText: 'Delete',
    }
  )
  const { modal: rentModal, action: showRentCaseModal } = useConfirmDialog(
    () => {},
    {
      title: 'Thank you for the request!',
      text: 'A member of our staff will contact you shortly for ....',
      confirmText: 'Okay',
    }
  )
  const onRentCase = (caseItem) => {
    store.rentCase(caseItem).then((res) => {
      if (res) showRentCaseModal()
    })
  }
  const { modal: shareModal, action: onShareCase } = useConfirmDialog(
    () => {},
    {
      title: 'Share link',
      text: (props) => (
        <ShareForm
          generateLink={(data) => store.handleSendShare(data, props)}
          copyLink={(link) => store.handleCopyShareLink(link)}
          close={() => store.closeShareDialog()}
        />
      ),
    }
  )

  if (!tabs || !activeTab) return null

  const titleText = activeTab?.title
  const {
    data,
    cardsModeOffset: dataOffset,
    changePage,
    changeCard,
    filter: { page, card, limit },
    allDataCount: count,
    isFetchingTable: isFetch,
  } = activeTab.store

  const canEditCaseByRole = AuthStore.permissions.editCase
  const currentUserId = AuthStore.currentUser.id
  const showPreloader = isCasesPage && isFetch

  const renderCaseCard = (caseItem) => {
    const contributorId = caseItem.contributor
    const isEditCase = canEditCaseByRole && contributorId === currentUserId

    if (isCasesPage && activeTab.key === 'available-cases') {
      return (
        <CaseCardAvailable
          key={caseItem.id}
          type={caseItem.type}
          data={caseItem}
          isEditCase={isEditCase}
          onShareCase={() => onShareCase(caseItem)}
        />
      )
    }

    if (isCasesPage && activeTab.key === 'in-progress-cases') {
      return (
        <CaseCardInProgress
          key={caseItem.id}
          type={caseItem.type}
          data={caseItem}
          isEditCase={isEditCase}
          onRemoveCase={() => onRemoveCase(caseItem)}
        />
      )
    }

    if (!isCasesPage && activeTab.key === 'platform-cases') {
      return (
        <CaseCardRent
          key={caseItem.id}
          type={caseItem.type}
          data={caseItem}
          onRentCase={() => onRentCase(caseItem)}
        />
      )
    }
  }

  return (
    <div
      className={`home-cases ${isCasesPage ? 'cases-page' : ''}`}
      id="home-cases"
    >
      {!isCasesPage && <div className="t-title">{titleText}</div>}

      <div className="home-cases-tabs-ctrl">
        <div className="home-cases-tabs-ctrl-section">
          {tabs.length > 1 &&
            tabs.map((tab) => {
              return (
                <TabBtn
                  key={tab.key}
                  title={t(tab.title)}
                  action={() => changeTab(tab.key)}
                  isActive={activeTab?.key === tab.key}
                />
              )
            })
          }
        </div>

        <Responsive breakpoints={['desktop']}>
          <div className="home-cases-tabs-ctrl-section ml-auto">
            {isCasesPage && canEditCaseByRole && (
              <Link
                as="a"
                className="home-cases-tabs-ctrl__item active"
                to="/constructor/new"
              >
                <Icon icon="plus" size="1.5rem" right="1rem" />
                Create Demo Case
              </Link>
            )}
          </div>
        </Responsive>
      </div>

      <Responsive breakpoints={['desktop']}>
        <List
          isFetch={isFetch}
          data={data}
          page={page}
          maxPage={Math.ceil(count / limit)}
          changePage={(page) => changePage(page)}
          renderItem={renderCaseCard}
        />
      </Responsive>

      <Responsive breakpoints={['mobile', 'tablet']}>
        <CarouselList
          isFetch={isFetch}
          data={data}
          dataOffset={dataOffset}
          card={card}
          maxCard={count}
          changeCard={(card) => changeCard(card)}
          renderItem={renderCaseCard}
        />
      </Responsive>

      {removeModal}
      {rentModal}
      {shareModal}
      {showPreloader && <Loader isCasesPage={isCasesPage} />}
    </div>
  )
})

const Loader = ({ isCasesPage }) => {
  const renderCaseCard = (item) => {
    return (
      <div key={item.key} className="case-card">
        <div className="case-card-top">
          <Skeleton width="2.75rem" height="2.75rem" radius="50%" />

          <div className="case-card-rate">
            <Skeleton width="2.75rem" height="1.4rem" radius="4rem" />
          </div>
        </div>

        <div className="case-card__title">
          <Skeleton
            width="8rem"
            height="1rem"
            radius="4rem"
            top="0.6rem"
            bottom="1rem"
          />
        </div>

        <div className="case-card__text">
          <Skeleton
            width="100%"
            height="0.8rem"
            radius="4rem"
            bottom="0.7rem"
          />
          <Skeleton
            width="100%"
            height="0.8rem"
            radius="4rem"
            bottom="0.7rem"
          />
          <Skeleton width="100%" height="0.8rem" radius="4rem" />
        </div>

        <div className="case-card-bottom ml-auto">
          <Skeleton width="12rem" height="2.75rem" radius="4rem" />
        </div>
      </div>
    )
  }

  return (
    <div className="wrapper skeleton-wrapper">
      <div data-aos="fade-down">
        <Skeleton width="100%" height="3.5rem" />
      </div>

      <div className="content home-cases">
        <div className="home-cases-tabs-ctrl">
          <Responsive breakpoints={['desktop']}>
            <Skeleton width="20rem" height="2.5rem" radius="4rem" bottom="1rem" />

            {isCasesPage && (
              <Skeleton
                width="25rem"
                height="2.5rem"
                radius="4rem"
                left="auto"
                bottom="1rem"
              />
            )}
          </Responsive>

          <Responsive breakpoints={['mobile', 'tablet']}>
            <Skeleton
              width="100%"
              height="2.5rem"
              radius="4rem"
              left="1rem"
              bottom="1rem"
            />
            <Skeleton
              width="100%"
              height="2.5rem"
              radius="4rem"
              left="1rem"
              right="1rem"
              bottom="1rem"
            />
          </Responsive>
        </div>

        <Responsive breakpoints={['desktop']}>
          <List
            isFetch={false}
            data={getArrayWithKeys(6)}
            page={1}
            maxPage={10}
            changePage={() => {}}
            renderItem={renderCaseCard}
          />
        </Responsive>

        <Responsive breakpoints={['mobile', 'tablet']}>
          <CarouselList
            isFetch={false}
            data={getArrayWithKeys(10)}
            dataOffset={0}
            card={1}
            maxCard={10}
            changeCard={() => {}}
            renderItem={renderCaseCard}
          />
        </Responsive>
      </div>

      <div className="mb-auto" data-aos="fade-up" data-aos-offset="-1000">
        <Skeleton width="100%" height="2.25rem" />
      </div>
    </div>
  )
}

const TabBtn = ({ title, isActive, action }) => {
  return (
    <button
      className={`home-cases-tabs-ctrl__item ${isActive ? 'active' : ''}`}
      onClick={action}
    >
      {title}
    </button>
  )
}

export default CasesBlock
