import { Link } from 'react-router-dom'
import Icon from './Icon'

const OverlayLink = ({
  children,
  className = '',
  linkText = '',
  ...linkProps
}) => {
  return (
    <Link {...linkProps} className={`overlay-link ${className}`}>
      {children}
      <div className="overlay-link-content">
        <p className="overlay-link-content__text">{linkText}</p>
        <Icon icon="arrowRight" />
      </div>
    </Link>
  )
}

export default OverlayLink
