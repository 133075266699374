import { useState } from 'react'
import { Icon } from '../common'

const ToolBar = ({ items }) => {
  const [active, setActive] = useState(null)
  const label = active !== null ? items[active].label : null
  return (
    <div className="toolbar">
      <div className="toolbar-btns">
        {items.map((item, i) => (
          <button
            className="toolbar__btn"
            key={i}
            onClick={item.onClick}
            onMouseEnter={() => setActive(i)}
            onMouseLeave={() => setActive(null)}
          >
            <Icon icon={item.icon} size="1.5rem" />
          </button>
        ))}
      </div>
      {label && <div className="toolbar__label">{label}</div>}
    </div>
  )
}

export default ToolBar
