import { Icon } from '../../common'

const CommentArea = ({ comment, onOpen }) => {
  const getRelative = (v) => {
    return Number(v.toFixed(4)) + '%'
  }
  return (
    <div
      className="comment"
      style={{
        top: comment.y && getRelative(comment.y),
        left: comment.x && getRelative(comment.x),
        width: comment.width && getRelative(comment.width),
        height: comment.height && getRelative(comment.height),
      }}
    >
      <div className="comment-activator" onClick={onOpen}>
        <Icon icon="info" />
      </div>
    </div>
  )
}

export default CommentArea
