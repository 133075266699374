import { formatDistance, format } from 'date-fns'
import sanitizeHtml from 'sanitize-html'

export const doNothing = () => {}

export const getTime = (time) =>
  formatDistance(new Date(new Date().getTime() - time), new Date())

export const getDate = (date) => format(new Date(date), 'd MMMMMM')

export const getDateYear = (date) => format(new Date(date), 'dd.MM.yyyy')

export const getFullDate = (date) =>
  format(new Date(date), "dd.MM.yy, HH:mm")

export const getStrapiError = (response) => {
  return response.message[0].messages[0].message
}

export const getArrayWithKeys = (length) => {
  return Array(length)
    .fill(null)
    .map((_, index) => ({ key: index }))
}

export const truncateString = (string, limit = Infinity) => {
  if (string.length > limit) {
    return string.slice(0, limit) + '...'
  } else {
    return string
  }
}

export const getHtmlContent = (html) => {
  const span = document.createElement('span')
  span.innerHTML = sanitizeHtml(html)
  return span.textContent || span.innerText
}

export const isImageCached = (src) => {
  const imageElement = document.createElement('img')
  imageElement.src = src
  return imageElement.complete || imageElement.width + imageElement.height > 0
}

export const preloadImages = (images) => {
  const imagesHandlers = images.map((image) => {
    const img = new Image()
    img.src = image
    return img
  })

  // Cancel preloading
  return () => {
    for (const img of imagesHandlers) {
      img.src = ''
    }
  }
}

export const secondsToTimeStr = (seconds) => {
  const date = new Date(0)
  date.setSeconds(seconds)
  return date.toISOString().substring(14, 19)
}
