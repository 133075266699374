import { makeAutoObservable } from 'mobx'
import AuthStore from '../AuthStore'
import { USERS_API, AUTH_API } from '../../api'

class UserStore {
  constructor() {
    makeAutoObservable(this)
  }

  // Get check read/write mode and getUser
  async getUser(userId) {
    if (userId !== 'new') {
      let user = await USERS_API.getUser(userId)
      return user
    }
    return {}
  }

  async createUser(user) {
    let res = await USERS_API.addUser(user)
    return res
  }
  async updateUser(id, user) {
    let res = await AUTH_API.updateUser(id, user)
    return res
  }

  async removeUser(id) {
    let res = await USERS_API.removeUser(id)
    return res
  }
}

const store = new UserStore()

export default store
