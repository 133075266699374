import { observer } from 'mobx-react-lite'
import { forwardRef, useRef, useState, useMemo } from 'react'
import { motion } from 'framer-motion'
import DatePicker from 'react-datepicker'
import { getFullDate, getTime } from '../../utils'
import { Table, Icon, Responsive, ToolPositioner, Lightbox } from '../common'
import SessionFeedbackCard from '../analytics/SessionFeedbackCard'

const SessionsTable = observer(({
  data,
  linkName,
  filterByDate,
  changePage,
  page,
  maxPage,
}) => {
  const selectedFeedbackDeckRef = useRef(null)
  const [selectedSession, setSelectedSession] = useState(null)

  return (
    <Responsive breakpoints={['mobile', 'tablet', 'desktop']}>
      {(size) => (
        <Table
          className="sessions-table"
          data={data}
          changePage={changePage}
          page={page}
          maxPage={maxPage}
          columns={[
            {
              name: 'Location',
              key: 'location',
              hidden: size !== 'desktop',
              render: (row) => {
                if (!row.country) {
                  return <span className="c-grey">Undefined</span>
                }

                if (!row.city) {
                  return row.country
                }

                return `${row.country}, ${row.city}`
              },
            },
            {
              name: 'Date',
              key: 'date',
              filter: <DateFilter onChangeDates={filterByDate} />,
              render: (row) => (
                row.lastActivity ? (
                  <span
                    className="sessions-widget-list-item-identity-time"
                  >
                    {getFullDate(row.lastActivity)}
                  </span>
                ) : (
                  <span className="c-grey">Undefined</span>
                )
              ),
            },
            {
              name: 'Content views',
              key: 'content-views',
              align: 'center',
              render: (row) => `${row.contentViewCounter} / ${row.allContent}`,
            },
            {
              name: 'Session time',
              key: 'time',
              align: 'center',
              hidden: size !== 'desktop',
              render: (row) => getTime(row.totalTime),
            },
            {
              name: 'Feedback',
              key: 'feedback',
              align: 'center',
              render: (row) => (
                <div style={{ position: 'relative' }}>
                  <FeedbackDock
                    ref={(feedbackDock) => {
                      if (selectedSession === row.id) {
                        selectedFeedbackDeckRef.current = feedbackDock
                      }
                    }}
                    id={row.id}
                    callRequest={row.callRequest}
                    feedback={row.feedback}
                    setSelectedSession={setSelectedSession}
                  />

                  <Lightbox
                    anchorRef={selectedFeedbackDeckRef}
                    active={selectedSession === row.id}
                  />

                  <ToolPositioner anchorRef={selectedFeedbackDeckRef}>
                    {(selectedSession === row.id && size === 'desktop') && (
                      <SessionFeedbackCard
                        linkName={linkName}
                        callRequest={row.callRequest}
                        feedback={row.feedback}
                        sessions={data}
                        selectedSession={selectedSession}
                        setSelectedSession={setSelectedSession}
                        onClose={() => setSelectedSession(null)}
                      />
                    )}
                  </ToolPositioner>
                </div>
              ),
            },
            {
              name: '',
              key: 'more',
              width: 'auto',
              hidden: size === 'desktop',
              render: (row) => (
                <DetailsBtn
                  id={row.id}
                  selectedSession={selectedSession}
                  setSelectedSession={setSelectedSession}
                />
              ),
            },
          ]}
          subRow={(row) => {
            if (row.id !== selectedSession || size === 'desktop') return null

            return (
              <div className="feedback-widget">
                <div className="feedback-widget-location">
                  <span>Location</span>
                  {row.country ? (
                    row.city ? (
                      <span>{row.country}, {row.city}</span>
                    ) : (
                      <span>{row.country}</span>
                    )
                  ) : (
                    <span className="c-grey">Undefined</span>
                  )}

                  <span>Session Time</span>
                  <span>{getTime(row.totalTime)}</span>
                </div>

                {row.callRequest && (
                  <div className="feedback-widget-contacts">
                    <Icon icon="mail" size="1rem" />
                    <span>{row.callRequest.email}</span>

                    <Icon icon="phone" size="1rem" />
                    <span>{row.callRequest.phone}</span>
                  </div>
                )}

                {row.feedback && (
                  <div className="feedback-widget-feedback">
                    <Icon icon="star" size="1rem" />
                    <span>{Number(row.feedback.rate).toFixed(1)}</span>

                    <Icon icon="comment" size="1rem" />
                    <span>{row.feedback.text}</span>
                  </div>
                )}
              </div>
            )
          }}
        />
      )}
    </Responsive>
  )
})

export default SessionsTable

const DateFilterOpener = forwardRef(({ onClick }, ref) => {
  return (
    <div ref={ref} className="calendar-opener" onClick={onClick}>
      <Icon icon="calendar" size="1.5rem" />
    </div>
  )
})

const DateFilter = ({ onChangeDates }) => {
  const todayMidnightUTC = useMemo(() => {
    return new Date(new Date().setHours(0, 0, 0, 0))
  }, [])

  const [dates, setDates] = useState([todayMidnightUTC, null])
  const [startDate, endDate] = dates

  const onChange = (dates) => {
    setDates(dates)

    if (dates[0] && dates[1]) {
      onChangeDates?.(dates)
    }
  }

  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      useWeekdaysShort
      customInput={<DateFilterOpener />}
    />
  )
}

const FeedbackDock = forwardRef((
  { id, callRequest, feedback, setSelectedSession },
  ref
) => {
  const showDecorations = callRequest && feedback

  return (
    <div
      ref={ref}
      className={`feedback-dock ${showDecorations ? 'decorations' : ''}`}
      onClick={() => setSelectedSession(id)}
    >
      {callRequest && (
        <div className="feedback-dock__btn">
          <Icon icon="mail" size="100%" />
        </div>
      )}
      {showDecorations && (
        <div className="feedback-dock__separator" />
      )}
      {feedback && (
        <div className="feedback-dock__btn">
          <Icon icon="comment" size="100%" />
        </div>
      )}
    </div>
  )
})

const DetailsBtn = ({ id, selectedSession, setSelectedSession, }) => {
  const open = selectedSession === id
  const toggle = () => setSelectedSession(open ? null : id)

  return (
    <motion.div
      onClick={toggle}
      animate={{ rotate: open ? '90deg' : '-90deg' }}
      transition={{ type: 'spring', duration: 0.5 }}
    >
      <Icon icon="chevron-left" size="1.5rem" />
    </motion.div>
  )
}
