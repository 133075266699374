import { Responsive } from '../common'
import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'

const Header = () => {
  return (
    <>
      <Responsive breakpoints={['mobile', 'tablet']}>
        <MobileHeader />
      </Responsive>

      <Responsive breakpoints={['desktop']}>
        <DesktopHeader />
      </Responsive>
    </>
  )
}

export default Header
