import { CaseConstructorStore as store } from '../../store'
import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { Icon, Switch } from '../common'
import { Link } from 'react-router-dom'

const ContsructorControl = observer(() => {
  const getBreadcrumbs = () => {
    let breadcrumbs = []
    let activeCase = store.activeCase
    let activeStage = store.activeStage
    let activeStep = store.activeStep

    if (activeCase !== null) {
      breadcrumbs.push({ name: activeCase.name, onClick: null })

      if (activeStage !== null && activeCase.type !== 'video') {
        breadcrumbs[0].onClick = () => store.changeNavigation('stage', null)
        breadcrumbs.push({
          name: activeCase.stages[activeStage].name,
          onClick: null,
        })

        if (activeStep !== null) {
          breadcrumbs[1].onClick = () => store.changeNavigation('step', null)
          breadcrumbs.push({
            name: activeCase.stages[activeStage].steps[activeStep].name,
            onClick: null,
          })
        }
      }
    }

    return breadcrumbs
  }
  const breadcrumbs = getBreadcrumbs()
  return (
    <div className="constructor-top">
      <div className="constructor-top__logo">Demo Case Designer</div>

      <div className="constructor-top-breadcrumbs">
        {breadcrumbs.map((link, i) => (
          <Fragment key={i}>
            <button
              key={link.name}
              onClick={() => link.onClick()}
              disabled={!link.onClick}
            >
              {link.name}
            </button>
            {i !== breadcrumbs.length - 1 && <span>/</span>}
          </Fragment>
        ))}
      </div>

      {/* RIGHT CONTROL */}
      <div className="delim"></div>
      {store.activeCase && (
        <>
          <a
            href={`/cases/preview/${store.activeCase.id}`}
            target="_blank"
            rel="noreferrer"
            className="btn"
            style={{ marginRight: '1rem' }}
          >
            Preview <Icon icon="preview" size="1.5rem" left="0.5rem" />
          </a>
          <Switch
            value={store.activeCase?.isPublished}
            onChange={() => store.publicateCase()}
            label="Publish"
          />
          <Link
            tag="button"
            to="/cases"
            className="btn"
            style={{ margin: '0 5rem 0 8.1875rem' }}
          >
            Save and exit
          </Link>
        </>
      )}
    </div>
  )
})

export default ContsructorControl
