import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ResponsiveProvider } from './components/common'
import App from './App'
import AOS from 'aos'
import './styles/index.scss'
import 'aos/dist/aos.css'
import './i18n'
import 'react-image-crop/dist/ReactCrop.css'
import 'swiper/css'

AOS.init()
ReactDOM.render(
  <BrowserRouter>
    <ResponsiveProvider>
      <App />
    </ResponsiveProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
