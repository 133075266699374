import { computed, makeAutoObservable } from 'mobx'
import { CASES_CONSTRUCTOR_API as API } from '../../api'
// import authStore from '../AuthStore'
import notifications from '../MessagesStore'
import StepStore from './Step'
import { t } from 'i18next'

class Store {
  activeCase = null
  activeStage = null // index of active stage
  activeStep = null // index of active step
  step = null
  // activeComment = null // index of active comment
  // activeLink = null // index of active link

  activeForm = null // case-info | step-info
  isFetch = false

  lastAddedStage = null
  lastAddedStep = null

  newComment = null
  newLink = null

  query = () => {}

  constructor() {
    makeAutoObservable(this, {
      stage: computed,
    })
  }

  /**
   * Init store, load case or new case template
   * @param id - Use case identifier.
   */
  startConstructor = async (id, query, changeQuery) => {
    this.activeCase = null
    this.activeStage = query.get('stage') || null
    this.activeStep = query.get('step') || null
    this.activeForm = null
    this.query = changeQuery

    if (id && id !== 'new') {
      this.isFetch = true
      // load use case
      try {
        this.activeCase = await API.getUseCase(id)

        if (this.activeCase.type === 'video') {
          const videoQuery = new URLSearchParams({
            stage: '0',
            step: '0',
          })
          this.query(videoQuery.toString())
          this.activeStage = 0
          this.activeStep = 0
        }

        if (this.activeStep !== null) this.initStepStore()
      } catch (err) {
        console.log(err)
      }
      this.isFetch = false
    } else {
      // show create use case form
      this.activeForm = 'case-info'
    }
  }
  /**
   * On navigation link click, change active elements
   * @param {string} type -  type of navigation link (use-case | stage | step | comment)
   * @param {string} activeValue - value of active element or null
   */
  changeNavigation(type, activeValue) {
    ;(() => {
      this.closeForm()

      if (type === 'step') {
        this.activeStep = this.activeStep === activeValue ? null : activeValue
        if (this.activeStep !== null) {
          this.initStepStore()
        } else {
          this.step = null
        }
        return
      }

      this.activeStep = null
      this.step = null

      if (type === 'stage') {
        this.activeStage = this.activeStage === activeValue ? null : activeValue
      }
    })()
    let search = new URLSearchParams()
    if (this.activeStage !== null) search.set('stage', this.activeStage)
    if (this.activeStep !== null) search.set('step', this.activeStep)
    this.query(search.toString())
  }
  /**
   * Close active form
   */
  closeForm() {
    this.activeForm = null

    // Cleanup
    this.lastAddedStage = null
    this.lastAddedStep = null
  }
  initStepStore() {
    const onStepChange = (updatedStep) => {
      let steps = [...this.stage.steps]
      let stepIndex = steps.findIndex((step) => step.id === updatedStep.id)
      steps[stepIndex] = updatedStep
      this.activeCase.stages[this.activeStage].steps = steps
    }
    this.step = new StepStore(
      this,
      this.stage?.steps[Number(this.activeStep)],
      onStepChange
    )
  }

  /**
   * Open Use Case Edit form
   */
  editCase() {
    // show  use case form
    this.activeForm = 'case-info'
  }
  /**
   * Save use Case (create or update)
   * @param {Object} - use case edited data
   */
  async saveCase(data) {
    if (data.logo) data.logo = data.logo.id
    if (data.presentationImage)
      data.presentationImage = data.presentationImage.id

    if (!this.activeCase || !this.activeCase.id) {
      // create new use case
      return this.API_CALL(
        API.createCase({
          ...data,
        }),
        (newCase) => {
          this.closeForm()
          this.activeCase = newCase
          return this.activeCase.id
        },
        'c-case-create-error'
      )
    } else {
      // edit use case
      return this.API_CALL(
        API.editCase(this.activeCase.id, data),
        (updatedCase) => {
          this.closeForm()
          this.activeCase = {
            ...this.activeCase,
            name: updatedCase.name,
            shortDescription: updatedCase.shortDescription,
            description: updatedCase.description,
            userStory: updatedCase.userStory,
            worths: updatedCase.worths,
            logo: updatedCase.logo,
            presentationImage: updatedCase.presentationImage,
          }
        },
        'c-case-edit-error'
      )
    }
  }
  /**
   * Remove Case
   */
  removeCase() {
    return this.API_CALL(
      API.deleteCase(this.activeCase.id),
      () => {
        this.changeNavigation('stage', null)
        this.activeCase = null
        return
      },
      'c-case-remove-error',
      'Case removed'
    )
  }

  /**
   * Create stage with prefilled name
   */
  async createStage(isChangeNav = false) {
    let stagesCount = this.activeCase.stages.length
    this.API_CALL(
      API.createStage({
        name: 'Stage ' + (stagesCount + 1),
        useCase: this.activeCase.id,
      }),
      (newStage) => {
        this.activeCase.stages = [...this.activeCase.stages, newStage]
        this.lastAddedStage = newStage.id
        if (isChangeNav) this.changeNavigation('stage', stagesCount)
      },
      'c-stage-create-error',
      'New Stage Added'
    )
  }
  /**
   * Update active stage
   * @param {Object} data - stage edited data
   */
  async saveStage(data) {
    this.API_CALL(
      API.editStage(this.stage.id, data),
      (updatedStage) => {
        this.closeForm()
        this.activeCase.stages[this.activeStage] = updatedStage
      },
      'c-stage-edit-error',
      'Stage updated'
    )
  }
  /**
   * Remove Stage
   */
  async removeStage() {
    if (this.stage) {
      const stageId = this.stage.id
      await API.deleteStage(stageId)
      this.changeNavigation('stage', null)
      this.activeCase.stages = this.activeCase.stages.filter(
        (stage) => stage.id !== stageId
      )
    }
  }
  /**
   * Create Step with prefilled name
   */
  createStep(stageId, isChangeNav = false, defaultOrder) {
    // if stage is provided, create step in provided stage
    // else create step in active stage
    const stage =
      stageId !== undefined
        ? this.activeCase.stages.find((stage) => stage.id === stageId)
        : this.stage
    const stepsCount = stage.steps.length
    this.API_CALL(
      API.createStep({
        name: 'Step ' + (stepsCount + 1),
        stage: stage.id,
        useCase: this.activeCase.id,
        order: defaultOrder,
      }),
      (newStep) => {
        const steps = [...stage.steps]
        steps.splice(newStep.order - 1, 0, newStep)
        API.changeStepsOrder(steps.map((s) => s.id))

        const stages = [...this.activeCase.stages]
        const stageIndex = stages.findIndex(({ id }) => id === stage.id)
        stages[stageIndex].steps = steps

        this.activeCase.stages = stages
        this.lastAddedStage = newStep.stage.id
        this.lastAddedStep = newStep.id

        if (isChangeNav) this.changeNavigation('step', stepsCount)
      },
      'c-step-create-error',
      'Step Created'
    )
  }
  /**
   * Update active step
   * @param {object} data - step edited data
   * @param {string} [id]  - step id
   */
  async saveStep(data, id) {
    const stepId = id ? id : this.step?.step.id
    this.API_CALL(
      API.editStep(stepId, data),
      (updatedStep) => {
        let steps = [...this.stage.steps]
        let stepIndex = steps.findIndex((step) => step.id === stepId)
        steps[stepIndex] = updatedStep
        this.activeCase.stages[this.activeStage] = { ...this.stage, steps }
        this.closeForm()
      },
      'c-step-edit-error',
      'Step updated'
    )
  }
  /**
   * Remove Step
   */
  async removeStep(id) {
    if (id) {
      await this.API_CALL(
        API.deleteStep(id),
        () => {
          let steps = [...this.activeCase.stages[this.activeStage].steps]
          steps = steps.filter((step) => step.id !== id)
          this.activeCase.stages[this.activeStage].steps = steps
        },
        'Step delete error',
        'Step removed'
      )
    }
  }

  /**
   * Reorder steps
   * @param {object[]} steps Steps array with new order
   * @returns {Promise<void>}
   */
  async reorderSteps(steps) {
    this.activeCase.stages[this.activeStage].steps = steps
    const stepIds = steps.map((step) => step.id)

    await this.API_CALL(
      API.changeStepsOrder(stepIds),
      () => {},
      'c-step-reorder-error',
      'Steps reordered'
    )
  }

  /**
   * Remove image
   */
  async removeImage(image) {
    await API.removeFile(image.id)
  }

  async publicateCase() {
    const newValue = !this.activeCase.isPublished
    this.API_CALL(
      API.editCase(this.activeCase.id, {
        isPublished: newValue,
        published_at: new Date(),
      }),
      () => {
        this.activeCase.isPublished = newValue
      },
      'c-case-validation-error',
      newValue ? 'Publicated' : 'In Draft'
    )
  }

  get stage() {
    if (this.activeCase && this.activeStage !== null)
      return this.activeCase.stages[this.activeStage]
    return null
  }
  async API_CALL(apiCall, callback, errorCode, message) {
    this.isFetch = true
    return apiCall
      .then((res) => {
        notifications.addSuccess(message || 'Saved')
        return res
      })
      .then((res) => callback(res))
      .catch((error) => {
        const errorMessage = error.response?.data?.message || error.message
        if (typeof errorMessage === 'string') {
          notifications.addError(errorMessage)
        } else {
          notifications.addError(
            errorMessage.code
              ? t(errorMessage.code) +
                  (errorMessage.payload ? ' ' + errorMessage.payload : '')
              : t(errorCode)
          )
        }
      })
      .finally(() => {
        this.isFetch = false
      })
  }

  navCache = null
}

const store = new Store()

export default store
