import { useClickOutside } from '@mantine/hooks'
import CommentCard from './CommentCard'
import { ToolPositioner } from '../../common'

const Comment = ({ comment, commentsCount, close, remove, edit, children, changePage }) => {
  const getRelative = (v) => {
    return Number(v.toFixed(4)) + '%'
  }

  const isRight = (comment.x || 0) < 50
  const isTop = (comment.y || 0) < 50

  const lightboxRef = useClickOutside(() => {
    if (close) close()
  })

  return (
    <div
      ref={lightboxRef}
      className={`comment active ${isRight ? 'right' : 'left'} ${
        isTop ? 'top' : 'bottom'
      }`}
      style={{
        top: comment.y && getRelative(comment.y),
        left: comment.x && getRelative(comment.x),
        width: comment.width && getRelative(comment.width),
        height: comment.height && getRelative(comment.height),
      }}
    >
      <ToolPositioner anchorRef={lightboxRef}>
        {!children ? (
          <CommentCard
            comment={comment}
            commentsCount={commentsCount}
            close={close}
            edit={edit}
            remove={remove}
            changePage={changePage}
          />
        ) : (
          children
        )}
      </ToolPositioner>
      <div
        className="comment-active-area"
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        data-aos="zoom-out"
      />
    </div>
  )
}

export default Comment
