import { useScrollLock } from '@mantine/hooks'
import Breadcrumbs from '../components/layout/Breadcrumbs'
import UsersTable from '../components/users/UsersTable'
import { Pagination, Search, Icon, Skeleton, Responsive } from '../components/common'
import { useConfirmDialog } from '../utils/hooks'
import { UsersStore, UserStore } from '../store'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { useEffect } from 'react'

const UsersPage = observer(() => {
  useEffect(() => {
    UsersStore.fetchTable()
    UsersStore.getDataCount()
  }, [])

  const {
    data: users,
    changePage,
    filter: { page, limit },
    allDataCount: count,
    isFetchingTable: isFetch,
  } = UsersStore

  const maxPage = Math.ceil(count / limit)
  const onSearch = (v) => UsersStore.setSearch(v)

  const { modal: deleteModal, action: removeUser } = useConfirmDialog(
    (id) =>
      UserStore.removeUser(id).then(() => {
        UsersStore.fetchTable()
        UsersStore.getDataCount()
      }),
    {
      title: 'Delete',
      text: (
        <>
          <div className="t-lg c-primary">
            Do you really want to delete this user?
          </div>
        </>
      ),
      confirmText: 'Delete',
    },
    []
  )

  useScrollLock(isFetch)

  return (
    <div className="users-page">
      <Breadcrumbs path={[t('nav-users')]}>
        <Search onSearch={onSearch} />
        <CreateBtn />
      </Breadcrumbs>

      <UsersTable
        users={users}
        removeUser={removeUser}
        changePage={changePage}
        page={page}
        maxPage={maxPage}
      />

      {deleteModal}
      {isFetch && <Loader />}
    </div>
  )
})

export default UsersPage

const Loader = () => {
  return (
    <div className="wrapper skeleton-wrapper">
      <div data-aos="fade-down">
        <Skeleton width="100%" height="3.5rem" />
      </div>

      <div className="content users-page">
        <div className="breadcrumbs" data-aos="fade-down" data-aos-delay="150">
          <div className="breadcrumbs__back">
            <Skeleton width="1.5rem" height="1.5rem" radius="50%" />
          </div>

          <Skeleton width="4.5rem" height="1.5rem" radius="4rem" />

          <div className="breadcrumbs-inner">
            <div className="search">
              <Skeleton width="1.5rem" height="1.5rem" radius="50%" />
            </div>
            <Responsive breakpoints={['mobile', 'tablet', 'desktop']}>
              {(size) => (
                <Skeleton
                  width={size === 'desktop' ? '8rem' : '1.5rem'}
                  height="1.5rem"
                  radius="4rem"
                  left="1.5rem"
                />
              )}
            </Responsive>
          </div>
        </div>

        <div className="users-list" data-aos="zoom-out">
          <Skeleton
            width="100%"
            height="2.7rem"
            radius="4rem"
            bottom="0.8rem"
          />

          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
          <Skeleton width="100%" height="3rem" radius="4rem" />
        </div>

        <Pagination changePage={() => {}} page={1} maxPage={10} />
      </div>

      <div className="mb-auto" data-aos="fade-up" data-aos-offset="-1000">
        <Skeleton width="100%" height="2.25rem" />
      </div>
    </div>
  )
}

const CreateBtn = () => (
  <Link className="btn-white-text" to="/users/new?edit">
    <Icon icon="plus" size="1.5rem" right="0.5rem" />
    <Responsive breakpoints={['desktop']}>
      {t('btn-create-user')}
    </Responsive>
  </Link>
)
