import Icon from './Icon'
import { useClickOutside } from '@mantine/hooks'

const Modal = ({
  confirm: confirmAction,
  cancelText,
  confirmText,
  close,
  show,
  title,
  text,
}) => {
  const ref = useClickOutside(close)
  if (!show) return null

  return (
    <div className="modal-wrapper">
      <div className="modal" ref={ref}>
        <div className="modal__close" onClick={close}>
          <Icon icon="close" />
        </div>
        <div className="modal__title">{title}</div>
        <div className="modal__text">{text}</div>

        <div className="modal-bottom">
          {cancelText && (
            <button
              type="button"
              className="btn-white-text"
              onClick={close}
            >
              {cancelText}
            </button>
          )}
          {confirmText && (
            <button
              type="button"
              className="btn-primary"
              onClick={() => {
                confirmAction()
              }}
            >
              {confirmText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
