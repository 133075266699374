import { MessagesStore as store } from '../../store'
import { observer } from 'mobx-react-lite'
import { Icon } from '../common'

const Notifications = observer(() => {
  const notifications = store.messages

  return (
    <div className="notifications-wrapper">
      {notifications &&
        notifications.map((msg) => (
          <div
            key={msg.id}
            data-aos="fade-left"
            className={`notification ${msg.type || 'info'}`}
          >
            <div className="notification__icon">{icons[msg.type]}</div>
            <div className="notification-content">
              <div className="notification__title">{msg.message.title}</div>
              <div className="notification__message">{msg.message.message}</div>
            </div>
            <button
              className="notification__close"
              onClick={() => store.removeMessage(msg.id)}
            >
              <Icon icon="close" size="1.5rem" />
            </button>
          </div>
        ))}
    </div>
  )
})

export default Notifications

const icons = {
  info: (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2394_71880)">
        <rect x="4" width="30" height="30" rx="15" fill="#1F4F80" />
        <path
          d="M17.5 8.25H20.5V16.5H17.5V8.25ZM17.5 21.75V18.75H20.5V21.75H17.5Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2394_71880"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2394_71880"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2394_71880"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  success: (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2309_69028)">
        <rect x="4" width="30" height="30" rx="15" fill="#1F8034" />
        <path
          d="M27.5 9.41L15.5 21.41L10 15.91L11.41 14.5L15.5 18.58L26.09 8L27.5 9.41Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2309_69028"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2309_69028"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2309_69028"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  warning: (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2394_71094)">
        <rect x="4" width="30" height="30" rx="15" fill="#FF7844" />
        <path
          d="M19 4L10 8V14C10 19.55 13.84 24.74 19 26C24.16 24.74 28 19.55 28 14V8L19 4ZM19 8C19.7956 8 20.5587 8.31607 21.1213 8.87868C21.6839 9.44129 22 10.2044 22 11C22 11.7956 21.6839 12.5587 21.1213 13.1213C20.5587 13.6839 19.7956 14 19 14C18.2044 14 17.4413 13.6839 16.8787 13.1213C16.3161 12.5587 16 11.7956 16 11C16 10.2044 16.3161 9.44129 16.8787 8.87868C17.4413 8.31607 18.2044 8 19 8ZM24.13 20C22.92 21.85 21.11 23.24 19 23.92C16.89 23.24 15.08 21.85 13.87 20C13.53 19.5 13.24 19 13 18.47C13 16.82 15.71 15.47 19 15.47C22.29 15.47 25 16.79 25 18.47C24.76 19 24.47 19.5 24.13 20Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2394_71094"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2394_71094"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2394_71094"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  error: (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2309_69058)">
        <rect x="4" width="30" height="30" rx="15" fill="#EA4C4C" />
        <g clipPath="url(#clip0_2309_69058)">
          <path
            d="M26 9.41L24.59 8L19 13.59L13.41 8L12 9.41L17.59 15L12 20.59L13.41 22L19 16.41L24.59 22L26 20.59L20.41 15L26 9.41Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_2309_69058"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2309_69058"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2309_69058"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2309_69058">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(10 6)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
}
