import { Icon, Text } from '../../common'

const CommentCard = ({
  comment,
  commentsCount,
  close,
  edit,
  remove,
  changePage,
}) => {
  return (
    <div className="comment-card viewer">
      <div className="comment-card__top">
        <div className="comment-card__title">{comment.title}</div>
        {remove && (
          <button
            className="btn btn-icon"
            onClick={remove}
            title="Remove Comment"
          >
            <Icon icon="trash" size="1.5rem" />
          </button>
        )}
        {edit && (
          <button className="btn btn-icon" onClick={edit} title="Edit Comment">
            <Icon icon="edit" size="1.5rem" />
          </button>
        )}
        {close && (
          <button className="btn btn-icon" onClick={close} title="Close">
            <Icon icon="close" size="1.5rem" />
          </button>
        )}
      </div>
      <div className="comment-card__content">
        <Text html={comment.description} />
      </div>
      <div className="comment-card-bottom">
        <Pagination
          page={comment.order}
          maxPage={commentsCount}
          changePage={changePage}
        />
        <button className="btn-primary" onClick={close}>
          OK
        </button>
      </div>
    </div>
  )
}

const Pagination = ({ page, maxPage, changePage }) => {
  return (
    <div className="comments-pagination">
      <button
        disabled={page === 1}
        className="comments-pagination__arrow left"
        onClick={() => changePage(page - 1)}
      >
        <svg
          width="5"
          height="10"
          viewBox="0 0 5 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 0L0 5L5 10V0Z" fill="#1F4F80"/>
        </svg>
      </button>

      <p className="comments-pagination-pages">
        <span className="comments-pagination__page_active">{page}</span>
        <span className="comments-pagination__page">of</span>
        <span className="comments-pagination__page">{maxPage}</span>
      </p>

      <button
        disabled={page === maxPage}
        className="comments-pagination__arrow right"
        onClick={() => changePage(page + 1)}
      >
        <svg
          width="5"
          height="10"
          viewBox="0 0 5 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 10L5 5L0 0L0 10Z" fill="#1F4F80"/>
        </svg>

      </button>
    </div>
  )
}

export default CommentCard
