import { useDisclosure } from '@mantine/hooks'
import { t } from 'i18next'
import { useState, useEffect } from 'react'
import Icon from '../Icon'
import RichText from './RichText'
import PhoneInput from './PhoneInput'
import { MessagesStore } from '../../../store'

const Form = ({ config }) => {
  const [state, setState] = useState({
    fields: {
      ...config.fields.reduce((fields, field) => {
        let defaultValue = field.defaultValue
          ? field.defaultValue
          : ['radio', 'checkbox'].includes(field.type)
          ? false
          : ''
        return { ...fields, [field.name.toLowerCase()]: defaultValue }
      }, {}),
    },
    errors: {},
    submited: false,
  })

  const setField = (name, value) => {
    if (state.fields.hasOwnProperty(name.toLowerCase())) {
      let newState = { ...state, errors: {} }
      newState.fields[name.toLowerCase()] = value
      setState(newState)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    let data = state.fields
    let errors = {}

    config.fields.forEach((field) => {
      if (field.required && !data[field.name.toLowerCase()])
        errors[field.name.toLowerCase()] = t('answer-required')

      if (
        field.type === 'email' &&
        !validateEmail(data[field.name.toLowerCase()])
      ) {
        errors[field.name.toLowerCase()] = t('invalid-email')
      }

      if (
        field.maxLength !== undefined &&
        data[field.name.toLowerCase()].replace(/<\/?[^>]+(>|$)/g, '').length >
          field.maxLength
      )
        errors[field.name.toLowerCase()] = t('Max length exceeded')
    })
    if (Object.keys(errors).length > 0) {
      setState({ ...state, errors })
      return
    }

    const reject = (errors) => {
      setState({ ...state, errors })

      if (errors.global) {
        MessagesStore.addError(errors.global)
      }
    }

    const accept = async () => {
      if (!config.submit) return

      const onSuccess = () => setState({ ...state, submited: true })
      config.submit(data, reject, onSuccess)
    }

    config.validate(data, accept, reject)
  }

  const renderField = (field, fieldError) => {
    const value = state.fields[field.name.toLowerCase()]

    if (['text', 'email', 'password'].includes(field.type)) {
      return <Input value={value} onChange={setField} error={fieldError} {...field} />
    }
    if (field.type === 'phone') {
      return <PhoneInput value={value} onChange={setField} error={fieldError} {...field} />
    }

    if (field.type === 'textarea') {
      return <Textarea value={value} onChange={setField} error={fieldError} {...field} />
    }

    if (field.type === 'richtext') {
      return (
        <div className={`form__richtext-input ${fieldError ? 'error' : ''}`}>
          <RichText
            id={field.name}
            name={field.name}
            value={value}
            onChange={(value) => setField(field.name, value)}
            disabled={field.disabled}
            placeholder={field.placeholder}
          ></RichText>
          {field.maxLength && (
            <div
              className={`form__maxlength ${
                value.replace(/<\/?[^>]+(>|$)/g, '').length > field.maxLength
                  ? 'error'
                  : ''
              }`}
            >
              {value.replace(/<\/?[^>]+(>|$)/g, '').length}/{field.maxLength}
            </div>
          )}
        </div>
      )
    }

    if (field.type === 'radio')
      return field.options.map((op, i) => {
        const opTitle = typeof op === 'object' ? op.title : op
        const opValue = typeof op === 'object' ? op.value : op

        return (
          <div
            key={opValue + i}
            className={`form__radio-wrapper ${field.outlined ? 'outlined' : ''}`}
          >
            <input
              className="form__radio"
              type="radio"
              id={field.name + '-option-' + i}
              name={field.name + '-option-' + i}
              disabled={field.disabled}
              checked={value === opValue}
              value={opValue}
              onChange={(e) => setField(field.name, e.target.value)}
            />
            <label htmlFor={field.name + '-option-' + i}>{opTitle}</label>
          </div>
        )
      })
    if (field.type === 'checkbox')
      return (
        <div className="form__checkboxWrapper">
          <input
            className="form__checkbox"
            id={field.name}
            name={field.name}
            disabled={field.disabled}
            type="checkbox"
            value={value}
            onChange={(e) => setField(field.name, e.target.value)}
          />
          <label htmlFor={field.name}>{field.placeholder}</label>
        </div>
      )
    if (field.type === 'select')
      return (
        <select
          id={field.name}
          name={field.name}
          className="form__input"
          disabled={field.disabled}
          value={value}
          onChange={(e) => setField(field.name, e.target.value)}
        >
          {field.noneLabel && <option value="">{field.noneLabel}</option>}
          {field.options &&
            field.options.length > 0 &&
            field.options.map((op, i) => (
              <option key={op + i} value={op}>
                {op}
              </option>
            ))}
        </select>
      )

    if (field.customRender) {
      return (
        <field.customRender
          field={field}
          value={value}
          onChange={(value) => setField(field.name, value)}
        />
      )
    }

    return field.defaultValue
  }

  /**
   * Fetch select options if needed
   */
  let selectsWithFetchOptions = config.fields.filter((field) =>
    field.hasOwnProperty('fetchOptions')
  )
  useEffect(() => {
    for (let i = 0; i < selectsWithFetchOptions.length; i++) {
      selectsWithFetchOptions[i].fetchOptions().then((options) => {
        let fieldName = selectsWithFetchOptions[i].name
        let index = config.fields.findIndex((field) => field.name === fieldName)
        config.fields[index].options = options.map((o) => o.name)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      onSubmit={onSubmit}
      className={`form ${
        state.submited && config.submitedSlot ? 'submitted' : ''
      }`}
      autoComplete={config.autocomplete}
      noValidate
    >
      {/* RENDER FORM FIELDS */}
      {config.fields.map((field) => {
        const fieldError = state.errors[field.name.toLowerCase()]

        return (
          <div className="form__field" style={field.style} key={field.name}>
            {field.label && (
              <label htmlFor={field.name} className="form__label">
                {field.label}
              </label>
            )}
            <div className="form__input-wrapper">
              {renderField(field, fieldError)}
            </div>
            {field.message && !fieldError && (
              typeof field.message === 'function'
                ? <field.message />
                : <span className="form__message">{field.message}</span>
            )}
            {(fieldError) && (
              <span className="form__message form__message--error">
                {fieldError}
              </span>
            )}
          </div>
        )
      })}
      {/* SUBMIT BTN SLOT */}
      {config.submitSlot}
      {/* SUCCESSFULL SUBMITED FORM SLOT */}
      {state.submited && config.submitedSlot && (
        <div className="form__submitedSlot">{config.submitedSlot}</div>
      )}
    </form>
  )
}

export default Form

export const Input = (props) => {
  const [showPassword, { toggle: toggleShowPassword }] = useDisclosure(false)
  let type = props.type || 'text'

  if (type === 'password' && showPassword) {
    type = 'text'
  }

  return (
    <>
      <div className="form__input-icon-wrapper">
        <input
          id={props.name}
          type={type}
          placeholder={props.placeholder || ''}
          className={`form__input ${props.error ? 'error' : ''} ${props.type === 'password' ? 'password' : ''}`}
          name={props.name}
          value={props.value}
          disabled={props.disabled}
          onChange={(e) => props.onChange(props.name, e.target.value)}
        />
        {props.type === 'password' && (
          <div className="show-password-btn" onClick={toggleShowPassword}>
            <Icon icon={showPassword ? 'eye' : 'eye-off'} />
          </div>
        )}
      </div>
      {props.maxLength && (
        <div
          className={`form__maxlength ${
            props.value.length > props.maxLength ? 'error' : ''
          }`}
        >
          {props.value.length}/{props.maxLength}
        </div>
      )}
    </>
  )
}

export const Textarea = (props) => {
  return (
    <>
      <textarea
        id={props.name}
        placeholder={props.placeholder || ''}
        className={`form__input ${props.error ? 'error' : ''}`}
        name={props.name}
        value={props.value}
        disabled={props.disabled}
        onChange={(e) => props.onChange(props.name, e.target.value)}
      ></textarea>
      {props.maxLength && (
        <div
          className={`form__maxlength ${
            props.value.length > props.maxLength ? 'error' : ''
          }`}
        >
          {props.value.length}/{props.maxLength}
        </div>
      )}
    </>
  )
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
