import { Form, Icon } from '../../common'
import { useTranslation } from 'react-i18next'
import ImageUploader from './ImageUploader'

const CaseForm = ({ useCase, submit, close, loadImage }) => {
  const { t } = useTranslation()
  const isNew = !useCase
  const formConfig = {
    fields: [
      {
        name: 'type',
        type: 'radio',
        required: true,
        outlined: true,
        label: 'Choose type of Demo case',
        defaultValue: isNew ? 'image' : (useCase.type || 'image'),
        disabled: !isNew,
        options: [
          { title: 'Video', value: 'video' },
          { title: 'Click through', value: 'image' },
        ],
      },
      {
        name: 'Name',
        type: 'text',
        required: true,
        label: 'Name Demo case',
        defaultValue: isNew ? '' : useCase.name,
        maxLength: 20,
      },
      {
        name: 'Logo',
        type: null,
        label: 'Choose a logo for your demo case',
        defaultValue: isNew ? '' : useCase.logo,
        customRender: ({ field, value, onChange }) => (
          <ImageUploader
            value={value}
            width={64}
            height={64}
            placeholder={
              <>
                <Icon icon="plus" size="1.5rem" />
                <div className="t-md c-primary">Logo</div>
              </>
            }
            onChange={onChange}
            isCrop
            imageHolder={isNew ? null : { ref: 'use-case', refId: useCase.id, field: 'logo' }}
          />
        ),
      },
      {
        name: 'separator',
        customRender: () => (
          <div className="constructor-form-separator">
            <label className="form__label">
              Description of the demo case on a separate frame
            </label>
          </div>
        ),
      },
      {
        name: 'PresentationImage',
        label: (
          <span style={{ fontWeight: '400' }}>Product presentation image</span>
        ),
        defaultValue: isNew ? '' : useCase.presentationImage,
        customRender: ({ field, value, onChange }) => (
          <ImageUploader
            value={value}
            width={475}
            height={860}
            placeholder={
              <>
                <Icon icon="file" size="2.5rem" bottom="0.5rem" />
                <div className="t-md c-primary t-center">
                  Drag an image here
                </div>
              </>
            }
            onChange={onChange}
            isCrop
            haveBrowseButton
            imageHolder={isNew ? null : { ref: 'use-case', refId: useCase.id, field: 'presentationImage' }}
          />
        ),
      },
      {
        name: 'Description',
        type: 'richtext',
        required: false,
        label: 'Description',
        defaultValue: isNew ? '' : useCase.description,
        maxLength: 500,
      },
      {
        name: 'UserStory',
        type: 'richtext',
        required: false,
        label: (
          <>
            User Story
            <span className="label-optional">*Not necessary</span>
          </>
        ),
        defaultValue: isNew ? '' : useCase.userStory,
        maxLength: 400,
      },
      {
        name: 'Worths',
        type: 'richtext',
        required: false,
        label: (
          <>
            Values
            <span className="label-optional">*Not necessary</span>
          </>
        ),
        defaultValue: isNew ? '' : useCase.worths,
        maxLength: 350,
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject) => {
      const cmp = (data_key, store_key) =>
        data[data_key] && (!useCase || data[data_key] !== useCase[store_key])
          ? data[data_key]
          : undefined
      let formatedData = {
        type: cmp('type', 'type'),
        name: cmp('name', 'name'),
        description: cmp('description', 'description'),
        userStory: cmp('userstory', 'userStory'),
        worths: cmp('worths', 'worths'),

        logo: !data.logo || data.logo.isUpdated ? data.logo : undefined,
        presentationImage:
          !data.presentationimage || data.presentationimage.isUpdated
            ? data.presentationimage
            : undefined,
      }
      formatedData = Object.keys(formatedData).reduce(
        (acc, key) =>
          formatedData[key] !== undefined
            ? { ...acc, [key]: formatedData[key] }
            : acc,
        {}
      )

      submit(formatedData)
    },
    submitSlot: (
      <>
        <button type="submit" className="btn-primary ml-auto">
          {t(isNew ? 'c-form-create-btn' : 'c-form-update-btn')}
        </button>
      </>
    ),
    requiredMessage: 'Field required',
  }

  return (
    <div className="c-form">
      <div className="t-title c-primary">
        {t(isNew ? 'create-use-case-title' : 'edit-use-case-title')}
      </div>

      <Form config={formConfig} />
    </div>
  )
}

export default CaseForm
