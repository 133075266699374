import Breadcrumbs from '../components/layout/Breadcrumbs'
import UserForm from '../components/users/UserForm'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { UserStore, AuthStore } from '../store'
import { t } from 'i18next'

const User = observer(() => {
  const { id } = useParams()
  return (
    <div className="users-page">
      <Breadcrumbs
        back="/users"
        path={[{ text: t('nav-users'), url: '/users' }, t('nav-user')]}
      />

      <UserForm
        id={id}
        onGet={(id) => UserStore.getUser(id)}
        onUpdate={(id, user) => UserStore.updateUser(id, user)}
        onCreate={(user) => UserStore.createUser(user)}
        onDelete={(id) => UserStore.removeUser(id)}
        roles={
          AuthStore.expandedRoles
            ? ['Administrator', 'Contributor', 'User']
            : ['Administrator', 'User']
        }
      />
    </div>
  )
})

export default User
