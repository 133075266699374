const { axios, securedFetchOptions, getToken } = require('./axios')

export const AUTH_API = {
  login: (identifier, password) =>
    axios.post('/auth/local', { identifier, password }),

  registerClient: (data) => axios.post('/clients', data),

  updateUser: (id, user) =>
    axios.put('/users/' + id, user, securedFetchOptions()),

  forgetPassword: (email) => axios.post('/auth/forgot-password', { email }),
  resetPassword: (password, code) =>
    axios.post('/auth/reset-password', {
      code: code,
      password: password,
      passwordConfirmation: password,
    }),
}

export const USERS_API = {
  getUsers: ({ search, page, limit }) =>
    axios.get('/users', {
      params: {
        _q: search,
        _start: (page - 1) * limit,
        _limit: limit,
      },
      ...securedFetchOptions(),
    }),

  getUser: (id) => axios.get(`/users/${id}`, securedFetchOptions()),

  addUser: (user) =>
    axios.post(`/clients/add-user`, user, securedFetchOptions()),

  getUsersCount: ({ client, search }) =>
    axios.get('/users/count', {
      params: {
        client: client,
        _q: search,
      },
      ...securedFetchOptions(),
    }),

  removeUser: (id) => axios.delete(`/users/${id}`, securedFetchOptions()),
}

export const CASES_API = {
  getCasesStat: () => axios.get('/use-cases/stats', securedFetchOptions()),
  getAvailableCases: ({ page, limit }) =>
    axios.get('/use-cases', {
      params: {
        _start: (page - 1) * limit,
        _limit: limit,
        type: 'available',
      },
      ...securedFetchOptions(),
    }),
  getAvailableCasesCount: () =>
    axios.get('/use-cases/count', {
      params: {
        type: 'available',
      },
      ...securedFetchOptions(),
    }),

  getInprogressCases: ({ page, limit }) =>
    axios.get('/use-cases', {
      params: {
        _start: (page - 1) * limit,
        _limit: limit,
        type: 'in-progress',
      },
      ...securedFetchOptions(),
    }),
  getInprogressCasesCount: () =>
    axios.get('/use-cases/count', {
      params: {
        type: 'in-progress',
      },
      ...securedFetchOptions(),
    }),

  getPlatformCases: ({ page, limit }) =>
    axios.get('/use-cases', {
      params: {
        _start: (page - 1) * limit,
        _limit: limit,
        type: 'rent',
      },
      ...securedFetchOptions(),
    }),

  getPlatformCasesCount: () =>
    axios.get('/use-cases/count', {
      params: {
        type: 'rent',
      },
      ...securedFetchOptions(),
    }),

  getOneCase: (id) => axios.get(`/use-cases/${id}`, securedFetchOptions()),

  getOneCaseBySessionId: (id, ip) =>
    axios.get(`/use-cases/${id}?bySession=true&ip=${ip}`),

  shareCase: (caseId, data) =>
    axios.post(`/use-cases/${caseId}/share`, data, securedFetchOptions()),

  requestCall: (data) => axios.post('/call-requests', data),
  sendFeedback: (data) => axios.post('/use-case-feedbacks', data),
}

export const RENT_API = {
  createRentRequest: (caseId) =>
    axios.post('/rent-requests', { useCase: caseId }, securedFetchOptions()),
}

export const CASES_CONSTRUCTOR_API = {
  getUseCase: (id) => axios.get(`/use-cases/${id}`, securedFetchOptions()),

  createCase: (data) => axios.post('/use-cases', data, securedFetchOptions()),

  editCase: (id, data) =>
    axios.put(`/use-cases/${id}`, data, securedFetchOptions()),

  deleteCase: (id) => axios.delete(`/use-cases/${id}`, securedFetchOptions()),

  createStage: (data) => axios.post('/stages', data, securedFetchOptions()),

  editStage: (id, data) =>
    axios.put(`/stages/${id}`, data, securedFetchOptions()),

  deleteStage: (id) => axios.delete(`/stages/${id}`, securedFetchOptions()),

  changeStepsOrder: (steps) =>
    axios.post('/stages/stepsOrder', { steps }, securedFetchOptions()),

  createStep: (data) => axios.post('/steps', data, securedFetchOptions()),

  editStep: (id, data) =>
    axios.put(`/steps/${id}`, data, securedFetchOptions()),

  deleteStep: (id) => axios.delete(`/steps/${id}`, securedFetchOptions()),

  createComment: (data) => axios.post('/comments', data, securedFetchOptions()),

  editComment: (id, data) =>
    axios.put(`/comments/${id}`, data, securedFetchOptions()),

  deleteComment: (id) => axios.delete(`/comments/${id}`, securedFetchOptions()),

  changeCommentsOrder: (comments) =>
    axios.post('/steps/commentsOrder', { comments }, securedFetchOptions()),

  createLink: (data) => axios.post('/links', data, securedFetchOptions()),

  editLink: (id, data) =>
    axios.put(`/links/${id}`, data, securedFetchOptions()),

  deleteLink: (id) => axios.delete(`/links/${id}`, securedFetchOptions()),

  loadFile: async (data, onUploadProgress) => {
    const res = await axios.post('/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + getToken(),
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.floor(
          progressEvent.loaded * 100 / progressEvent.total
        )
        onUploadProgress?.(percentCompleted)
      },
    })

    return Array.isArray(res) ? res[0] : res
  },

  removeFile: (fileId) =>
    axios.delete(`/upload/files/${fileId}`, securedFetchOptions()),
}

export const ANALYTICS_API = {
  getAnalytics: ({ link, page, limit, search }) =>
    axios.get('/analytics', {
      params: {
        session: link,
        _q: search ? search : null,
        _start: (page - 1) * limit,
        _limit: limit,
      },
      ...securedFetchOptions(),
    }),
  getAnalyticsCount: () => axios.get('/analytics/count', securedFetchOptions()),
  getAnalytic: (id) => axios.get(`/analytics/${id}`, securedFetchOptions()),
  getLink: (id) => axios.get(`/preview-sessions/${id}`, securedFetchOptions()),

  getAnalyticLinks: ({ useCase, page, limit }) =>
    axios.get('/preview-sessions', {
      params: {
        useCase,
        _start: (page - 1) * limit,
        _limit: limit,
      },
      ...securedFetchOptions(),
    }),
  getAnalyticLinksCount: ({ useCase }) =>
    axios.get('/preview-sessions/count', {
      params: {
        useCase,
      },
      ...securedFetchOptions(),
    }),

  getAnalyticSessions: ({ link, page, limit, date }) =>
    axios.get('/user-sessions', {
      params: {
        session: link,
        _start: (page - 1) * limit,
        _limit: limit,
        updatedAt_gte: Array.isArray(date) ? date[0] : undefined,
        updatedAt_lte: Array.isArray(date) ? date[1] : undefined,
      },
      ...securedFetchOptions(),
    }),
  getAnalyticSessionsCount: ({ link, date }) =>
    axios.get('/user-sessions/count', {
      params: {
        session: link,
        updatedAt_gte: Array.isArray(date) ? date[0] : undefined,
        updatedAt_lte: Array.isArray(date) ? date[1] : undefined,
      },
      ...securedFetchOptions(),
    }),

  saveTransitionAnalytic: (data) => axios.post('/analytics', data),
}
