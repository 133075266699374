import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { t } from 'i18next'
import { useDisclosure, useScrollLock } from '@mantine/hooks'
import { motion } from 'framer-motion'
import MenuToggle from './MenuToggle'
import NavLink from './NavLink'
import { AuthStore } from '../../store'

const MobileHeader = observer(() => {
  const [showMenu, { toggle: toggleMenu }] = useDisclosure(false)
  const currentUser = AuthStore.currentUser
  const isAnalytic = AuthStore.permissions.analytics
  const isUsers = AuthStore.permissions.users

  const logOut = () => {
    AuthStore.logOut()
  }

  useScrollLock(showMenu)

  return (
    <motion.header
      className="header"
      data-aos="fade-down"
      animate={showMenu ? { height: '100vh' } : { height: '3.5rem' }}
      transition={{ easing: 'ease-out', duration: 0.5 }}
    >
      <div className="header-top">
        <Link to="/" className="header__logo">
          <img src="/assets/icons/logo.svg" alt="Ustork Lab" />
        </Link>

        {currentUser && (
          <MenuToggle isOpen={showMenu} onClick={toggleMenu} />
        )}
      </div>

      {currentUser && (
        <nav className="header-nav">
          <NavLink
            url="/cases"
            action={toggleMenu}
            text={t('nav-cases')}
            icon="cases"
          />
          {isAnalytic && (
            <NavLink
              url="/analytics"
              action={toggleMenu}
              text={t('nav-analytic')}
              icon="analytic"
            />
          )}
          {isUsers && (
            <NavLink
              url="/users"
              action={toggleMenu}
              text={t('nav-users')}
              icon="users"
            />
          )}
          <NavLink
            url="/settings"
            action={toggleMenu}
            text={t('nav-settings')}
            icon="settings"
          />
          <NavLink
            action={() => {
              logOut()
              toggleMenu()
            }}
            text={t('nav-exit')}
            icon="exit"
          />
        </nav>
      )}
    </motion.header>
  )
})

export default MobileHeader
