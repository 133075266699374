import { useState, useEffect } from 'react'
import Modal from '../components/common/Modal'

export const useConfirmDialog = (action, { title, text, confirmText }) => {
  const [show, setShow] = useState(false)
  const [props, setProps] = useState(undefined)
  const [modal, setModal] = useState(null)
  const onClose = () => {
    setProps(null)
    setShow(false)
  }
  useEffect(() => {
    const modal = show ? (
      <Modal
        confirm={() => {
          action(props)
          onClose()
        }}
        confirmText={confirmText}
        close={onClose}
        show={show}
        title={title}
        text={typeof text === 'function' ? text(props) : text}
      />
    ) : null
    setModal(modal)
  }, [show])

  return {
    modal,
    action: (props) => {
      setProps(props)
      setShow(true)
    },
  }
}

export const useDocumentEvent = (type, listener, options) =>{
  useEffect(() => {
    document.addEventListener(type, listener, options)
    return () => window.removeEventListener(type, listener, options)
  }, [])
}
