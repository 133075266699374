import { useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'

const Text = ({ className, text, html }) => {
  const sanitizedHtml = useMemo(() => {
    return html ? sanitizeHtml(html) : null
  }, [html])

  if (sanitizedHtml) {
    return (
      <div
        className={className || 't-md'}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    )
  }
  return <div className={className}>{text}</div>
}

export default Text
