import PhoneInput from 'react-phone-input-2'

const Phone = (props) => {
  return (
    <div className={`custom-phone ${props.error ? 'error' : ''}`}>
      <PhoneInput
        placeholder={props.placeholder}
        value={
          typeof props.value === 'string' ? props.value : props.value?.value
        }
        onChange={(v, c, _, f) => {
          props.onChange(props.name, { value: v, country: c, formated: f })
        }}
      />
    </div>
  )
}

export default Phone
