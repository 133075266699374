import { useClickOutside } from '@mantine/hooks'
import { Icon } from '../common'

const SessionFeedbackCard = ({
  linkName,
  callRequest,
  feedback,
  sessions,
  selectedSession,
  setSelectedSession,
  onClose,
}) => {
  const cardRef = useClickOutside(() => {
    onClose?.()
  })

  const selectedSessionIndex = sessions.findIndex((session) => {
    return session.id === selectedSession
  })

  let prevSession
  let nextSession

  for (let i = selectedSessionIndex - 1; i >= 0; i--) {
    if (sessions[i].callRequest && sessions[i].feedback) {
      prevSession = sessions[i].id
      break
    }
  }

  for (let i = selectedSessionIndex + 1; i < sessions.length; i++) {
    if (sessions[i].callRequest && sessions[i].feedback) {
      nextSession = sessions[i].id
      break
    }
  }

  const setPrevSession = () => {
    if (prevSession) {
      setSelectedSession(prevSession)
    }
  }

  const setNextSession = () => {
    if (nextSession) {
      setSelectedSession(nextSession)
    }
  }

  return (
    <div ref={cardRef} className="feedback-card">
      <div
        className={`feedback-card__navigation left ${prevSession ? '' : 'disabled'}`}
        onClick={setPrevSession}
      >
        <Icon icon="chevron-left" size="1.5rem" />
      </div>
      <div
        className={`feedback-card__navigation right ${nextSession ? '' : 'disabled'}`}
        onClick={setNextSession}
      >
        <Icon icon="chevron-right" size="1.5rem" />
      </div>

      <div className="feedback-card-summary">
        <span>{linkName}</span>
        {feedback && (
          <div className="feedback-card-summary-rate">
            <span>{Number(feedback.rate).toFixed(1)}</span>
            <Icon icon="star" size="1rem" left="0.5rem" />
          </div>
        )}
      </div>

      {callRequest && (
        <div className="feedback-card-contacts">
          <Icon icon="mail" size="1.2rem" top="0.1rem" />
          <span>{callRequest.email}</span>

          <Icon icon="phone" size="1.2rem" top="0.1rem" />
          <span>{callRequest.phone}</span>
        </div>
      )}

      {feedback && (
        <div className="feedback-card-feedback">
          <Icon icon="comment" size="1.2rem" top="0.1rem" />
          <span>{feedback.text}</span>
        </div>
      )}
    </div>
  )
}

export default SessionFeedbackCard
