const UploadingIndicator = ({ filename, progress }) => {
  const clampedProgress = Math.min(Math.max(progress, 0), 100)

  return (
    <div className="uploading-indicator">
      <div className="uploading-indicator__filename">{filename}</div>
      <div className="uploading-indicator__progress">{clampedProgress}%</div>
      <div className="uploading-indicator__progress-bar">
        <div
          className="uploading-indicator__progress-bar-inner"
          style={{ width: `${clampedProgress}%` }}
        />
      </div>
    </div>
  )
}

export default UploadingIndicator
