import { makeAutoObservable } from 'mobx'
import { ANALYTICS_API } from '../../api'
class AnalyticsStore {
  analytic = null
  link = null
  tableData = []
  tableDataCount = 0
  currentTable = 'links'
  targetId = null
  isFetch = false

  filter = {
    page: 1,
    limit: 6,
    date: null,
  }

  constructor() {
    makeAutoObservable(this)
  }

  fetchTable(table, targetId) {
    this.tableData = []
    this.tableDataCount = 0
    this.currentTable = table
    this.filter.page = 1
    this.filter.date = null
    this.targetId = targetId

    this.fetchTableData()
    this.fetchTableDataCount()
  }

  changePage = async (page) => {
    this.filter.page = page
    await this.fetchTableData()
  }

  filterByDate = async (date) => {
    this.filter.date = date
    await this.fetchTableData()
  }

  async fetchAnalytic(id) {
    try {
      this.analytic = await ANALYTICS_API.getAnalytic(id)
    } catch (error) {
      console.log(error.message)
    }
  }

  async fetchLink(id) {
    try {
      this.link = await ANALYTICS_API.getLink(id)
    } catch (error) {
      console.log(error.message)
    }
  }

  async fetchTableData() {
    try {
      this.isFetch = true
      if (this.currentTable === 'links') {
        this.fetchAnalyticLinks()
      } else {
        this.fetchAnalyticSessions()
      }
    }
    catch (error) {
      console.log(error.message)
    }
    finally {
      this.isFetch = false
    }
  }

  async fetchTableDataCount() {
    try {
      this.isFetch = true
      if (this.currentTable === 'links') {
        this.fetchAnalyticLinksCount()
      } else {
        this.fetchAnalyticSessionsCount()
      }
    }
    catch (error) {
      console.log(error.message)
    }
    finally {
      this.isFetch = false
    }
  }

  async fetchAnalyticLinks() {
    this.tableData = await ANALYTICS_API.getAnalyticLinks({
      useCase: this.targetId,
      ...this.filter,
    })
  }

  async fetchAnalyticSessions() {
    this.tableData = await ANALYTICS_API.getAnalyticSessions({
      link: this.targetId,
      ...this.filter,
    })
  }

  async fetchAnalyticLinksCount() {
    this.tableDataCount = await ANALYTICS_API.getAnalyticLinksCount({
      useCase: this.targetId,
      ...this.filter,
    })
  }

  async fetchAnalyticSessionsCount() {
    this.tableDataCount = await ANALYTICS_API.getAnalyticSessionsCount({
      link: this.targetId,
      ...this.filter,
    })
  }
}

const store = new AnalyticsStore()

export default store
