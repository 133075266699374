import { useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Layout from './components/layout/Layout'
import { AuthStore, ResponsiveStore } from './store'
import { observer } from 'mobx-react-lite'

import routes from './routes'

const App = observer(() => {
  const isLogged = !!AuthStore.currentUser
  const isMobile = ResponsiveStore.breakpoint !== 'desktop'
  const [inited, setInited] = useState(false)
  const location = useLocation()

  useEffect(() => {
    AuthStore.relogUser().then(() => setInited(true))
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = process.env.REACT_APP_JIRA_COLLECTOR_SCRIPT

    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
      const AtlElements = document.querySelectorAll('[class^=atlwdg]')
      AtlElements.forEach((el) => el.remove())
    }
  }, [location.key])

  if (!inited) return null

  return (
    <Switch>
      {routes.map((route) => {
        return (
          <Route
            key={route.name}
            path={route.path}
            exact
            render={({ location }) => {
              if (!route.protected && isLogged && route.redirect)
                // Redirect
                return (
                  <Redirect
                    to={{
                      pathname: route.redirect,
                      state: { from: location },
                    }}
                  />
                )

              if (!route.mobileReady && isMobile) {
                return (
                  <Redirect
                    to={{
                      pathname: '/mobile-oh-no',
                      state: { from: location },
                    }}
                  />
                )
              }

              // Render if route is not protected or user logged
              if (!route.protected || isLogged) {
                return (
                  <Layout type={route.layout || null}>
                    <route.component />
                  </Layout>
                )
              }

              // Redirect
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: location },
                  }}
                />
              )
            }}
          />
        )
      })}
      <Redirect
        to={{
          pathname: '/error-404',
          state: { from: location },
        }}
      />
    </Switch>
  )
})

export default App
