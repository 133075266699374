import Header from './Header'
import Footer from './Footer'

import ConstructorHeader from '../constructor/Header'
import Notifications from './Notifications'

const Layout = ({ type, children }) => {
  switch (type) {
    case 'constructor':
      return <LayoutTemplate header={<Header />}>{children}</LayoutTemplate>
    case 'case':
      return <LayoutTemplate header={null}>{children}</LayoutTemplate>
    case 'clean':
      return <LayoutTemplate>{children}</LayoutTemplate>
    default:
      return (
        <LayoutTemplate header={<Header />} footer={<Footer />}>
          {children}
        </LayoutTemplate>
      )
  }
}

export default Layout

const LayoutTemplate = ({ header, children, footer }) => {
  return (
    <div className="wrapper">
      {header}
      <div className="content">{children}</div>
      {footer}
      <Notifications />
    </div>
  )
}
