import { Link } from 'react-router-dom'
import { Icon } from '../common'
import { doNothing } from '../../utils'

const Breadcrumbs = ({ path, children, back }) => {
  return (
    <div className="breadcrumbs" data-aos="fade-down" data-aos-delay="150">
      <Link to={back ? back : '/'} className="breadcrumbs__back">
        <Icon icon="chevron-left" />
      </Link>
      {path.map((item, i) => (
        <div className="breadcrumbs__element" key={i}>
          <PathPart part={item} />
          {i !== path.length - 1 && <span className="delimiter">/</span>}
        </div>
      ))}

      <div className="breadcrumbs-inner">{children}</div>
    </div>
  )
}

export default Breadcrumbs

const PathPart = ({ part }) => {
  if (typeof part === 'object' && part?.url) {
    return (
      <Link
        className="clickable"
        to={part.url}
        onClick={part.onClick ?? doNothing}
      >
        {part.text}{' '}
      </Link>
    )
  }

  if (typeof part === 'object' && part?.onClick) {
    return (
      <div
        className="clickable"
        onClick={part.onClick}
      >
        {part.text}{' '}
      </div>
    )
  }

  return (
    <>
      {part}{' '}
    </>
  )
}
