export const Switch = ({ value, onChange, label }) => {
  return (
    <div className={`switch-wrapper ${value ? 'active' : ''}`}>
      <div className="switch__label">{label}</div>
      <div className="switch" onClick={() => onChange(!value)}>
        <div className="switch__value" />
      </div>
    </div>
  )
}
