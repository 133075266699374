import { useState, useEffect } from 'react'

const Loader = ({ show, text = 'Loading', inline = false }) => {
  const [dots, setDots] = useState(0)

  useEffect(() => {
    const interval = setInterval(
      () => setDots((dots) => (dots !== 3 ? dots + 1 : 0)),
      300
    )
    return () => {
      clearInterval(interval)
    }
  }, [])
  const renderDots = new Array(dots)
    .fill(null)
    .map((item, i) => <span key={i}>.</span>)
  if (!show) return null

  return (
    <div
      className={`loader-wrapper ${inline ? 'inline' : ''}`}
      data-aos="zoom-out"
    >
      <div className="dots">
        <div className={`dots__item ${dots === 1 ? 'active' : ''}`}></div>
        <div className={`dots__item ${dots === 2 ? 'active' : ''}`}></div>
        <div className={`dots__item ${dots === 3 ? 'active' : ''}`}></div>
      </div>
      <div className="loader-label">{text}{renderDots}</div>
    </div>
  )
}

export default Loader
