import MainBlock from '../components/home/MainBlock'
import CasesBlock from '../components/home/CasesBlock'

const Home = () => {
  return (
    <div className="home-page">
      <MainBlock />

      <CasesBlock />
    </div>
  )
}

export default Home
