import Form from '../../components/common/form/Form'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../../store/index'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { useHistory, useLocation } from 'react-router-dom'

const Login = observer(() => {
  const isFetch = AuthStore.isFetch
  let history = useHistory()
  let location = useLocation()
  const formConfig = {
    fields: [
      {
        name: 'Email',
        label: 'Email',
        type: 'email',
        placeholder: 'Enter e-mail',
        required: true,
        disabled: isFetch,
      },
      {
        name: 'Password',
        label: 'Password',
        type: 'password',
        placeholder: 'Enter password',
        message: PasswordMessage,
        required: true,
        disabled: isFetch,
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject) => {
      AuthStore.login(data)
        .then(() => {
          let { from } = location.state || { from: { pathname: '/' } }
          history.replace(from)
        })
        .catch((err) => {
          reject({ global: err })
        })
    },
    submitSlot: (
      <div className="submit-row">
        <span className="required-message">Required fields</span>
        <button type="submit" className="btn-primary" disabled={isFetch}>
          {t('auth-sign-in')}
        </button>
      </div>
    ),
    requiredMessage: 'Field required',
  }

  return (
    <div className="auth-page">
      <div className="t-title c-primary" data-aos="fade-down">
        {t('auth-sign-in-title')}
      </div>
      <div data-aos="fade-right">
        <Form config={formConfig} />
      </div>
      <img
        className="page-back-image"
        src="/assets/images/work-table.png"
        alt="table"
        data-aos="fade-left"
      />
    </div>
  )
})

export default Login

const PasswordMessage = () => {
  return (
    <div className="password-message">
      <Link
        className="forget-link"
        to="/forget-password"
      >
        {t('auth-forgot-password')}
      </Link>
    </div>
  )
}
