import { computed, makeAutoObservable } from 'mobx'
import { CASES_CONSTRUCTOR_API as API } from '../../api'
import notifications from '../MessagesStore'

const newAreaTemplate = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

class StepStore {
  step = null
  onChange = null

  comment = null
  link = null
  area = null
  form = null // comment | comments-order | link

  constructor(store, step, onChange) {
    this.store = store
    this.step = step
    this.onChange = () => onChange(this.step)
    makeAutoObservable(this, {
      isHandleImageClick: computed,
    })
  }
  /**
   * Open Step Edit form
   */
  editStep() {
    this.store.activeForm = 'step-info'
  }
  saveStep(data) {
    this.step = { ...this.step, ...data }
    this.store.saveStep(data)
  }
  /**
   * Create new comment
   */
  createComment() {
    this.area = { ...newAreaTemplate }
    this.form = 'comment'
    notifications.addInfo(
      'Select the point or select the area you want to mark on the image.'
    )
  }
  /**
   * Create new link
   */
  createLink() {
    this.area = { ...newAreaTemplate }
    this.form = 'link'
    notifications.addInfo(
      'To create a new link, select the point or select the area to be marked in the image.'
    )
  }
  /**
   * Handle area data and open form
   */
  async handleAreaUpdate({ x, y, width, height }) {
    this.area = null
    if (this.form === 'comment') {
      let newComment = this.comment ? this.comment : {}
      this.comment = { ...newComment, x, y, width, height }
      return
    }

    if (this.form === 'link') {
      let newLink = this.link ? this.link : {}
      this.link = {
        ...newLink,
        x,
        y,
        width,
        height,
        stage: this.store.stage.id,
        toStage: this.store.stage.id,
        step: this.step.id,
      }
      return
    }

    // update
    if (this.comment) this.saveComment({ x, y, width, height })
    if (this.link) this.saveLink({ x, y, width, height })
  }
  /**
   * close area input
   */
  closeArea() {
    this.area = null
    this.form = null
  }
  /**
   * Change active element position
   */
  changePosition() {
    if (this.comment) {
      this.area = {
        x: this.comment.x,
        y: this.comment.y,
        width: this.comment.width,
        height: this.comment.height,
      }
    } else if (this.link) {
      this.area = {
        x: this.link.x,
        y: this.link.y,
        width: this.link.width,
        height: this.link.height,
      }
    }
  }
  toggleForm(form) {
    if (!form && this.form === 'comment') this.comment = null
    if (!form && this.form === 'comments-order') this.comment = null
    if (!form && this.form === 'link') this.link = null
    if (!form) this.store.closeForm()

    this.form = form
  }
  /**
   * Open comment or link
   */
  setActive(type, value) {
    if (type === 'comment') {
      this.comment = value
      this.link = null
    }
    if (type === 'link') {
      this.link = value
      this.comment = null
    }
  }
  /**
   * Save Comment (create or update)
   * @param {Object} - comment edited data
   */
  async saveComment(data) {
    const isNew = !this.comment.id
    if (isNew) {
      // create new comment
      this.store.API_CALL(
        API.createComment({
          ...this.comment,
          ...data,
          step: this.step.id,
          stage: this.store.stage.id,
          useCase: this.store.activeCase.id,
        }),
        (result) => {
          let comments = [...this.step.comments]
          comments = [...comments, result]
          this.step = { ...this.step, comments }
          this.form = null
          this.comment = null
          this.onChange()
        },
        'c-comment-create-error'
      )
    } else {
      // edit comment
      this.store.API_CALL(
        API.editComment(this.comment.id, data),
        (result) => {
          let comments = [...this.step.comments]
          let index = comments.findIndex((c) => c.id === this.comment.id)
          comments[index] = result
          this.step = { ...this.step, comments }
          this.form = null
          this.comment = null
          this.onChange()
        },
        'c-comment-edit-error'
      )
    }
  }
  /**
   * Remove Comment
   */
  async removeComment() {
    await this.store.API_CALL(
      API.deleteComment(this.comment.id),
      () => {
        let comments = [...this.step.comments].filter(
          (c) => c.id !== this.comment.id
        )
        API.changeCommentsOrder(comments.map((c) => c.id)).then(() => {
          this.step = { ...this.step, comments }
          this.form = null
          this.comment = null
          this.onChange()
        })
      },
      'c-comment-delete-error',
      'Removed'
    )
  }

  /**
   * Reorder comments
   * @param data
   * @returns {Promise<void>}
   */
  async reorderComments(comments) {
    const commentsIds = comments.map((comment) => comment.id)

    await this.store.API_CALL(
      API.changeCommentsOrder(commentsIds),
      () => {
        this.step = { ...this.step, comments }
        this.form = null
        this.comment = null
        this.onChange()
      },
      'c-comments-order-error',
      'Comments reordered'
    )
  }
  /**
   * Save Link (create or update)
   * @param {Object} - link edited data
   */
  async saveLink(data) {
    const isNew = !this.link.id
    if (isNew) {
      // create new link
      this.store.API_CALL(
        API.createLink({
          ...this.link,
          ...data,
          useCase: this.store.activeCase.id,
        }),
        (result) => {
          let links = [...this.step.links]
          links = [...links, result]
          this.step = { ...this.step, links }
          this.form = null
          this.link = null
          this.onChange()
        },
        'c-link-create-error'
      )
    } else {
      // edit link
      this.store.API_CALL(
        API.editLink(
          this.link.id,
          {
            ...this.link,
            ...data,
          }
        ),
        (result) => {
          let links = [...this.step.links]
          let index = links.findIndex((c) => c.id === this.link.id)
          links[index] = result
          this.step = { ...this.step, links }
          this.form = null
          this.link = null
          this.onChange()
        },
        'c-link-edit-error'
      )
    }
  }
  /**
   * Remove Link
   */
  async removeLink() {
    await this.store.API_CALL(
      API.deleteLink(this.link.id),
      () => {
        let links = [...this.step.links]
        let index = links.findIndex((c) => c.id === this.link.id)
        links.splice(index, 1)
        this.step.links = links
        this.link = null
        this.onChange()
      },
      'Error',
      'Removed'
    )
  }
  /**
   * Remove Step image
   */
  async removeImage(resetName = false) {
    // this.store.removeImage(this.step.image) // TODO: 403
    await this.store.saveStep({ image: null, name: resetName ? '' : undefined })
    this.step.image = null
    this.onChange()
  }
  /**
   * Save file to server and add file id to active step
   */
  async saveStepImage(image) {
    const videoName = image.videoName

    if (videoName) {
      delete image.videoName
    }

    let newStep = await API.editStep(this.step.id, {
      image: image.id,
      name: videoName,
    })

    this.step = newStep
    this.onChange(newStep)
  }
}

export default StepStore
