import ListStore from '../List'
import { ANALYTICS_API } from '../../api'

export default new ListStore({
  fetchData: ANALYTICS_API.getAnalytics,
  fetchDataCount: ANALYTICS_API.getAnalyticsCount,
  limit: 5,

  onLoadCallback: (res) => res,

  onErrorCallback: (error) => {
    console.log(error.message)
  },
})
