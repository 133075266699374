import { useScrollLock } from '@mantine/hooks'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { CASES_API as API } from '../../store/api'

import { t } from 'i18next'
import { isImageCached } from '../../utils'
import { Responsive, Skeleton } from '../common'

const MainBlock = () => {
  const [state, setState] = useState({
    views: null,
    cases: null,
    clientName: '',
    isFetch: false,
  })
  useEffect(() => {
    setState({ ...state, isFetch: true })
    API.getCasesStat().then((res) => setState({ ...res, isFetch: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [imageLoaded, setImageLoaded] = useState(false)
  const imageCached = useMemo(() => {
    return isImageCached('/assets/images/work-table.png')
  }, [])

  const onStart = () => {
    window.scroll(0, window.innerHeight + 50)
  }

  const onImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  const showPreloader = state.isFetch || (!imageCached && !imageLoaded)
  useScrollLock(showPreloader)

  return (
    <div className="home-main">
      <div
        className="home-main__subtitle"
        data-aos="fade-down"
        data-aos-delay="150"
      >
        {t('home-top-title-1')} {state.clientName}
      </div>

      <div
        className="home-main__title"
        data-aos="fade-down"
      >
        {t('home-top-title-2')}
      </div>

      <div className="home-main-dashboard">
        <DashboardCircle
          text={t('home-top-text-1')}
          count={state.cases}
          order={1}
        />
        <DashboardCircle
          text={t('home-top-text-2')}
          count={state.views}
          order={0}
        />
      </div>

      <div
        className="home-main__start"
        onClick={onStart}
        data-aos="fade-up"
        data-aos-offset="0"
      >
        {t('home-top-action')}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.0001 4H13.0001V16L18.5001 10.5L19.9201 11.92L12.0001 19.84L4.08008 11.92L5.50008 10.5L11.0001 16V4Z"
            fill="#1F4F80"
          />
        </svg>
      </div>

      <img
        className="page-back-image"
        src="/assets/images/work-table.png"
        alt="table"
        data-aos="fade-left"
        onLoad={onImageLoaded}
      />

      {showPreloader && <Loader />}
    </div>
  )
}

export default MainBlock

const Loader = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="home-main-preloader-background" />

      <div data-aos="fade-down">
        <Skeleton width="100%" height="3.5rem" />
      </div>

      <div className="home-main">
        <Responsive breakpoints={['desktop']}>
          <div
            className="home-main__subtitle"
            data-aos="fade-down"
            data-aos-delay="150"
          >
            <Skeleton width="35rem" height="1rem" radius="4rem" bottom="2.5rem" />
          </div>

          <div className="home-main__title" data-aos="fade-down">
            <Skeleton width="30rem" height="2rem" radius="4rem" bottom="4rem" />
          </div>
        </Responsive>

        <Responsive breakpoints={['mobile', 'tablet']}>
          <div
            className="home-main__subtitle"
            data-aos="fade-down"
            data-aos-delay="150"
          >
            <Skeleton width="100%" height="1.3rem" radius="4rem" bottom="1.5rem" />
          </div>

          <div className="home-main__title" data-aos="fade-down">
            <Skeleton width="100%" height="1.7rem" radius="4rem" bottom="1.15rem" />
          </div>
        </Responsive>

        <div className="home-main-dashboard">
          <DashboardCircleLoader order={1} />
          <DashboardCircleLoader order={0} left="3rem" />
        </div>

        <div
          className="home-main__start"
          data-aos="fade-up"
          data-aos-offset="0"
        >
          <Skeleton width="10rem" height="1rem" radius="4rem" bottom="0.7rem" />
        </div>
      </div>
    </div>
  )
}

const DashboardCircle = ({ text, count, order }) => {
  return (
    <div
      className="home-main-dashboard__item"
      data-aos="fade-right"
      data-aos-delay={order * 150}
    >
      <div className="home-main-dashboard__item-content">
        <b>{count}</b>
        <span>{text}</span>
      </div>
    </div>
  )
}

const DashboardCircleLoader = ({ order, left }) => {
  return (
    <div data-aos="fade-right" data-aos-delay={order * 150}>
      <Skeleton width="11.25rem" height="11.25rem" radius="50%" left={left} />
    </div>
  )
}
