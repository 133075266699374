import Form from '../../components/common/form/Form'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../../store/index'
import { t } from 'i18next'

const ForgetPassword = observer(() => {
  const formConfig = {
    fields: [
      {
        name: 'Email',
        label: 'E-mail',
        type: 'email',
        placeholder: 'enter e-mail',
        required: true,
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject, onSuccess) => {
      AuthStore.forgetPassword(data)
        .then(() => onSuccess())
        .catch((err) => {
          reject({ email: 'Ошибка' })
        })
    },
    submitSlot: (
      <div className="submit-row">
        <span className="required-message">Required fields</span>
        <button type="submit" className="btn-primary">
          {t('auth-forget-password')}
        </button>
      </div>
    ),
    submitedSlot: (
      <div className="submit-row">
        <span className="required-message">Required fields</span>
        <div className="form-submited-slot">
          {t('auth-forget-password-success-msg')}
        </div>
      </div>
    ),
  }

  return (
    <div className="auth-page">
      <div className="t-title c-primary" data-aos="fade-down">
        {t('auth-forget-password-title')}
      </div>
      <div data-aos="fade-right">
        <Form config={formConfig} />
      </div>
      <img
        className="page-back-image"
        src="/assets/images/work-table.png"
        alt="table"
        data-aos="fade-left"
      />
    </div>
  )
})

export default ForgetPassword
