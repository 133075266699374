import { useState, useMemo, useEffect, useRef } from 'react'
import notifications from '../../../store/modules/MessagesStore'
import { Modal, Icon } from '../../common'
import ReactCrop from 'react-image-crop'
import { CASES_CONSTRUCTOR_API as API } from '../../../store/api'
import UploadingIndicator from '../UploadingIndicator'

const ImageUploader = ({
  value,
  onChange,
  width,
  height,
  placeholder,
  isCrop,
  hideRemove,
  imageHolder,
  showIndicatorInside,
  haveBrowseButton,
}) => {
  const [image, setImage] = useState(value)
  const [modalImage, setModalImage] = useState(value)
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [uploadingProgress, setUploadingProgress] = useState(100)

  useEffect(() => {
    setImage(value)
    setIsCropModalOpen(false)
  }, [value])

  const onImageChange = (e) => {
    const image = e.target.files[0]

    if (isCrop) {
      setImage(null)
      setModalImage(image)
      setIsCropModalOpen(true)
    } else {
      setImage(image)
      onSubmit(null, image)
    }
  }

  const onSubmit = async (crop, image) => {
    let data = new FormData()
    data.append('files', image)

    if (crop) {
      data.append('crop', JSON.stringify(crop))
      data.append('resize', JSON.stringify({ width, height }))
    }

    if (imageHolder) {
      data.append('ref', imageHolder.ref)
      data.append('refId', imageHolder.refId)
      data.append('field', imageHolder.field)
    }

    try {
      onCloseCropModal()

      const res = await API.loadFile(data, (progress) => {
        setUploadingProgress(progress)
      })

      onChange({ ...res, isUpdated: true })
    } catch (err) {
      console.log(err.message)
    }
  }
  const onRemove = () => {
    onChange(null)
    setImage(null)
  }
  const onCloseCropModal = () => {
    setIsCropModalOpen(false)
  }

  const imageSrc = useMemo(() => {
    if (image instanceof File) {
      return URL.createObjectURL(image)
    } else if (image) {
      return process.env.REACT_APP_SERVER_URL + image.url
    }
    return null
  }, [image])

  const modalImageSrc = useMemo(() => {
    if (modalImage instanceof File) {
      return URL.createObjectURL(modalImage)
    } else if (modalImage) {
      return process.env.REACT_APP_SERVER_URL + modalImage.url
    }
    return null
  }, [modalImage])

  const uploadingFilename = useMemo(() => {
    const target = isCrop ? modalImage : image

    if (target instanceof File) {
      return target.name
    } else if (target) {
      const urlElements = target.url.split('/')
      return urlElements[urlElements.length - 1]
    }
    return null
  }, [isCrop, image, modalImage])

  const styles = {
    width: width ? width + 'px' : 'auto',
    height: height ? height + 'px' : '30rem',
  }

  return (
    <>
      {(!image || isCropModalOpen || uploadingProgress !== 100) ? (
        <Uploader
          onChange={onImageChange}
          styles={styles}
          uploadingProgress={uploadingProgress}
          filename={uploadingFilename}
          showIndicatorInside={showIndicatorInside}
          haveBrowseButton={haveBrowseButton}
        >
          {placeholder}
        </Uploader>
      ) : (
        <Preview
          image={imageSrc}
          onRemove={onRemove}
          styles={styles}
          hideRemov={hideRemove}
        />
      )}

      {isCropModalOpen && (
        <CropModal
          image={modalImageSrc}
          onClose={onCloseCropModal}
          onSubmit={(crop) => onSubmit(crop, modalImage)}
          width={width}
          height={height}
        />
      )}
    </>
  )
}

export default ImageUploader

const Preview = ({ image, onRemove, styles, hideRemove }) => {
  if (image) {
    image += '#chache-breaker=' + new Date().getTime()
  }

  return (
    <div className="file-uploader preview" style={styles} onClick={onRemove}>
      <img style={styles} src={image} alt="preview" />

      {!hideRemove && (
        <button
          className="btn btn-icon remove-btn"
          type="button"
          onClick={onRemove}
        >
          <Icon icon="close" size="1.5rem" />
        </button>
      )}
    </div>
  )
}

const Uploader = ({
  children,
  onChange,
  styles,
  uploadingProgress,
  filename,
  showIndicatorInside,
  haveBrowseButton,
}) => {
  const showIndicator = uploadingProgress !== 100

  const fileInputRef = useRef(null)

  const indicator = useMemo(() => (
    showIndicator && (
      <UploadingIndicator
        filename={filename}
        progress={uploadingProgress}
      />
    )
  ), [showIndicator, uploadingProgress, filename])

  return (
    <>
      <div className="file-uploader form" style={styles}>
        <input
          ref={fileInputRef}
          className={haveBrowseButton ? 'drag-only' : ''}
          type="file"
          accept="image/png, image/jpeg"
          onChange={onChange}
          onClick={(e) => {
            if (e.isTrusted && haveBrowseButton) {
              e.preventDefault()
            }
          }}
        />
        {showIndicator && showIndicatorInside ? indicator : (
          <>
            {children}
            {haveBrowseButton && (
              <div className="file-uploader form__browse-prologue">
                <span>Or, if you prefer</span>
                <button
                  className="btn btn-secondary file-uploader form__browse-btn"
                  onClick={(e) => {
                    e.preventDefault()
                    fileInputRef.current.click()
                  }}
                >
                  Choose an image to upload
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {!showIndicatorInside && indicator}
    </>
  )
}

const CropModal = ({ onSubmit, onClose, image, width, height }) => {
  const percentageError = 0.05 // 5%
  const aspect = width / height

  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    unit: 'px',
  })
  const imageRef = useRef()
  const handleSubmit = () => {
    const w = imageRef.current.naturalWidth
    const h = imageRef.current.naturalHeight

    const pxCrop = {
      x: Math.floor(w * (crop.x / 100)),
      y: Math.floor(h * (crop.y / 100)),
      width: Math.floor(w * (crop.width / 100)),
      height: Math.floor(h * (crop.height / 100)),
      unit: 'px',
    }

    if (pxCrop.width === 0 || pxCrop.height === 0) {
      const naturalAspect = w / h
      const minAspect = aspect * (1 - percentageError)
      const maxAspect = aspect * (1 + percentageError)

      pxCrop.width = w
      pxCrop.height = h

      if (naturalAspect < minAspect || naturalAspect > maxAspect) {
        notifications.addError('Image aspect ratio is not correct')
        return
      }
    }

    onSubmit(pxCrop)
  }
  const handleLoad = () => {
    const w = imageRef.current.width
    const h = imageRef.current.height
    const naturalAspect = w / h
    const minAspect = aspect * (1 - percentageError)
    const maxAspect = aspect * (1 + percentageError)

    if (naturalAspect < minAspect || naturalAspect > maxAspect) {
      let width, height

      if (aspect < naturalAspect) {
        // The picture is wider than it should be
        width = (h * aspect) / (w / 100)
        height = 100
      } else {
        // The picture is higher than it should be
        width = 100
        height = (w * aspect) / (h / 100)
      }

      const crop = { x: 0, y: 0, width, height, unit: '%' }
      setCrop(crop)
    }
  }
  const onChange = (_, crop) => setCrop(crop)

  return (
    <div className="crop">
      <Modal
        confirm={handleSubmit}
        confirmText="Load image"
        close={onClose}
        show={true}
        title="Crop image"
        text={
          <>
            <div className="t-md">
              "Select the area that will be shown on the logo."
            </div>
            <div className="crop-wrapper">
              <ReactCrop aspect={aspect} crop={crop} onChange={onChange}>
                <img
                  ref={imageRef}
                  src={image}
                  alt="crop"
                  onLoad={handleLoad}
                />
              </ReactCrop>
            </div>
          </>
        }
      />
    </div>
  )
}
