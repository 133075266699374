const LinkArea = ({ link, onOpen }) => {
  const getRelative = (v) => {
    return Number(v.toFixed(4)) + '%'
  }
  return (
    <div
      className="comment"
      style={{
        top: link.y && getRelative(link.y),
        left: link.x && getRelative(link.x),
        width: link.width && getRelative(link.width),
        height: link.height && getRelative(link.height),
      }}
    >
      <div className="link-activator" onClick={onOpen}></div>
    </div>
  )
}

export default LinkArea
