import { Form, Icon } from '../../common'
import { useTranslation } from 'react-i18next'
import CommentsOrderList from '../comment/CommentsOrderList'

const CommentsOrderForm = ({ comments, submit, close }) => {
  const { t } = useTranslation()
  const formConfig = {
    fields: [
      {
        name: 'comments',
        label: (
          <div className="comment-card-label">
            <p className="comment-card-label__description">
              Organize comments
            </p>
            <p className="comment-card-label__name">
              Arrange the comments simply by dragging them
            </p>
          </div>
        ),
        required: true,
        defaultValue: comments,
        customRender: ({ value, onChange }) => (
          <CommentsOrderList
            comments={value}
            onReorder={onChange}
          />
        ),
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject) => {
      submit(data.comments).catch((err) => {
        reject({ global: t(err.message) })
      })
    },
    submitSlot: (
      <div className="comment-card-submit-wrapper">
        <div className="comment-card-submit-wrapper-top-right">
          <button className="btn btn-icon" onClick={close} title="close">
            <Icon icon="close" size="1.5rem" />
          </button>
        </div>

        <button
          className="btn btn-white-text"
          onClick={close}
        >
          {t('c-form-cancel-btn')}
        </button>
        <button
          type="submit"
          className="btn-primary"
        >
          {t('c-form-update-btn')}
        </button>
      </div>
    ),
    requiredMessage: 'Field required',
  }

  return (
    <div className="comment-card">
      <Form config={formConfig} />
    </div>
  )
}

export default CommentsOrderForm
