import { useState, useEffect } from 'react'
import { useResizeObserver } from '@mantine/hooks'

const getContentAlign = (limiterRect, anchorRect, contentRect) => {
  const requiredWidth = contentRect.width + 16 // 8 * 2 px for margins
  const requiredHeight = contentRect.height + 16 // 8 * 2 px for margins

  const limiterTop = limiterRect.top + window.scrollY
  const limiterBottom = limiterRect.bottom + window.scrollY
  const limiterLeft = limiterRect.left + window.scrollX
  const limiterRight = limiterRect.right + window.scrollX

  const anchorTop = anchorRect.top + window.scrollY
  const anchorBottom = anchorRect.bottom + window.scrollY
  const anchorLeft = anchorRect.left + window.scrollX
  const anchorRight = anchorRect.right + window.scrollX

  let horizontalAlign = 'h-center'
  let verticalAlign = 'v-center'

  if (limiterRight - anchorRight >= requiredWidth) {
    horizontalAlign = 'h-right'
  } else if (anchorLeft - limiterLeft >= requiredWidth) {
    horizontalAlign = 'h-left'
  }

  if (horizontalAlign === 'h-center') {
    if (limiterBottom - anchorBottom >= requiredHeight) {
      verticalAlign = 'v-bottom'
    } else if (anchorTop - limiterTop >= requiredHeight) {
      verticalAlign = 'v-top'
    }
  } else {
    if (limiterBottom - anchorTop >= requiredHeight) {
      verticalAlign = 'v-bottom'
    } else if (anchorBottom - limiterTop >= requiredHeight) {
      verticalAlign = 'v-top'
    }
  }

  return [horizontalAlign, verticalAlign]
}

const ToolPositioner = ({ children, limiterRef, anchorRef }) => {
  const [alignClasses, setAlignClasses] = useState('hidden')
  const [contentRef, { width, height }] = useResizeObserver()

  useEffect(() => {
    const limiter = limiterRef?.current || document.body
    const { current: anchor } = anchorRef
    const { current: content } = contentRef

    if (anchor && content) {
      const limiterRect = limiter.getBoundingClientRect()
      const anchorRect = anchor.getBoundingClientRect()
      const contentRect = content.getBoundingClientRect()

      const classes = getContentAlign(limiterRect, anchorRect, contentRect)
      setAlignClasses(classes.join(' '))
    }
  }, [children, limiterRef, anchorRef, contentRef, width, height])

  return (
    <div
      ref={contentRef}
      className={`tool-positioner ${alignClasses}`}
    >
      {children}
    </div>
  )
}

export default ToolPositioner
