import { Link } from 'react-router-dom'
import { Icon } from '../common'
import { getDateYear, getHtmlContent, truncateString } from '../../utils'

const CardTemplate = ({ type, name, description, logo, topSlot, nameSlot, bottomSlot }) => {
  return (
    <div className="case-card">
      <div className="case-card-top">
        {logo && (
          <img
            src={process.env.REACT_APP_SERVER_URL + logo.url}
            alt={name}
            className="case-card__logo"
          />
        )}
        <div className="case-card-top-slot">
          <div className="case-card__type">
            {type === 'video' ? 'Video' : 'Click through'}
          </div>
          {topSlot}
        </div>
      </div>

      <div className="case-card__name">
        {name}
        {nameSlot && (
          <div className="case-card__name-slot ml-auto">{nameSlot}</div>
        )}
      </div>

      <div className="case-card__text">
        <p>{truncateString(getHtmlContent(description), 200)}</p>
      </div>

      <div className="case-card-bottom">{bottomSlot}</div>
    </div>
  )
}

export default CardTemplate

export const CaseCardAvailable = ({ type, data, isEditCase, onShareCase }) => (
  <CardTemplate
    type={type}
    name={data.name}
    description={data.description || 'No description'}
    logo={data.logo}
    topSlot={
      data.rented
        ? `Runs until ${getDateYear(data.rented)}`
        : (data.isPublished
           ? `Published ${getDateYear(data.published_at)}`
           : (!data.pending ? 'In draft' : '')
        )
    }
    nameSlot={
      (!data.rented && isEditCase) ? (
        <Link className="btn btn-icon" to={`/constructor/${data.id}`}>
          <Icon icon="edit" size="1.5rem" />
        </Link>
      ) : (
        <div className="btn btn-icon disabled">
          <Icon icon="edit" size="1.5rem" />
        </div>
      )
    }
    bottomSlot={
      <>
        <button className="case-card__btn" onClick={onShareCase}>
          <Icon icon="share" size="1.5rem" />
        </button>
        <Link className="case-card__btn" to={`/analytics/${data.id}`}>
          <Icon icon="chart" size="1.5rem" />
        </Link>
        <Link
          className="case-card__btn"
          to={`/analytics/${data.id}`}
        >
          {data.rate ? Number(data.rate).toFixed(1) : 0}
          <Icon icon="star" size="1.5rem" left="0.75rem" />
        </Link>
        {data.pending ? (
          <div className="btn-primary disabled">
            Pending
          </div>
        ) : (
           <Link
             className="btn-primary"
             to={`/cases/preview/${data.id}`}
             target="_blank"
           >
             Open
           </Link>
         )}
      </>
    }
  />
)

export const CaseCardInProgress = ({ type, data, isEditCase, onRemoveCase }) => (
  <CardTemplate
    type={type}
    name={data.name}
    description={data.description || 'No description'}
    logo={data.logo}
    topSlot={
      data.rented
        ? `Runs until ${getDateYear(data.rented)}`
        : (data.isPublished
           ? `Published ${getDateYear(data.published_at)}`
           : (!data.pending ? 'In draft' : '')
        )
    }
    nameSlot={
      <>
        {(!data.pending && isEditCase) ? (
          <Link className="btn btn-icon" to={`/constructor/${data.id}`}>
            <Icon icon="edit" size="1.5rem" right="1rem" />
          </Link>
        ) : (
          <div className="btn btn-icon disabled">
            <Icon icon="edit" size="1.5rem" right="1rem" />
          </div>
        )}

        {!data.rented ? (
          <button className="btn btn-icon" onClick={onRemoveCase}>
            <Icon icon="trash" size="1.5rem" />
          </button>
        ) : (
           <div className="btn btn-icon disabled">
             <Icon icon="trash" size="1.5rem" />
           </div>
         )}
      </>
    }
    bottomSlot={
      data.pending ? (
        <div className="btn-primary disabled">
          Pending
        </div>
      ) : (
        <Link
          className="btn-primary"
          to={`/cases/preview/${data.id}`}
          target="_blank"
        >
          Open
        </Link>
      )
    }
  />
)

export const CaseCardRent = ({ type, data, onRentCase }) => (
  <CardTemplate
    type={type}
    name={data.name}
    description={data.description || 'No description'}
    logo={data.logo}
    topSlot={
      <>
        {data.rate ? Number(data.rate).toFixed(1) : 0}
        <Icon icon="star" size="1.5rem" left="0.75rem" />
      </>
    }
    bottomSlot={
      <button className="btn-primary" onClick={onRentCase}>
        Request a rental
      </button>
    }
  />
)
