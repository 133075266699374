import { useScrollLock } from '@mantine/hooks'
import { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import {
  // Pagination,
  Skeleton,
} from '../../components/common'
import Breadcrumbs from '../../components/layout/Breadcrumbs'
import Search from '../../components/common/form/Search'
import { AnalyticsListStore } from '../../store'
import { observer } from 'mobx-react-lite'
import List from '../../components/common/List'
import CaseAnalyticCard, { CaseAnalyticCardLoader } from '../../components/analytics/CaseAnalyticCard'
import { t } from 'i18next'
import { getArrayWithKeys } from '../../utils'

const Analytics = observer(() => {
  useEffect(() => {
    AnalyticsListStore.fetchTable()
    AnalyticsListStore.getDataCount()
  }, [])

  const {
    data: users,
    changePage,
    filter: { page, limit },
    allDataCount: count,
    isFetchingTable: isFetch,
  } = AnalyticsListStore

  const maxPage = Math.ceil(count / limit)
  const onSearch = (v) => AnalyticsListStore.setSearch(v)

  useScrollLock(isFetch)

  return (
    <div className="analytic-page">
      <Breadcrumbs path={[t('nav-analytic')]}>
        <Search onSearch={onSearch} />
      </Breadcrumbs>

      <div className="analytic-list" data-aos="fade-up">
        <List
          isFetch={isFetch}
          data={users}
          changePage={changePage}
          page={page}
          maxPage={maxPage}
          renderItem={(item) => (
            <CaseAnalyticCard
              key={item.useCase.id}
              useCase={item.useCase}
              views={item.sessions}
              timeSum={item.time}
              lastView={item.lastDate}
              showMore
            />
          )}
        />
      </div>

      {isFetch && <Loader />}
    </div>
  )
})

const Loader = () => {
  return (
    <div className="wrapper skeleton-wrapper">
      <div data-aos="fade-down">
        <Skeleton width="100%" height="3.5rem" />
      </div>

      <div className="content analytic-page">
        <div className="breadcrumbs" data-aos="fade-down" data-aos-delay="150">
          <div className="breadcrumbs__back">
            <Skeleton width="1.5rem" height="1.5rem" radius="50%" />
          </div>

          <Skeleton width="4.5rem" height="1.5rem" radius="4rem" />

          <div className="breadcrumbs-inner">
            <div className="search">
              <Skeleton width="1.5rem" height="1.5rem" radius="50%" />
            </div>
          </div>
        </div>

        <div className="analytic-list" data-aos="fade-up">
          <List
            isFetch={false}
            data={getArrayWithKeys(3)}
            page={1}
            maxPage={10}
            changePage={() => {}}
            renderItem={(item) => (
              <CaseAnalyticCardLoader key={item.key} />
            )}
          />
        </div>
      </div>

      <div className="mb-auto" data-aos="fade-up" data-aos-offset="-1000">
        <Skeleton width="100%" height="2.25rem" />
      </div>
    </div>
  )
}

export default Analytics
