import { Link } from 'react-router-dom'
import { Icon } from '../common'
import { doNothing } from '../../utils'

const NavLink = ({ id, url, text, icon, action, iconRight }) => {
  const content = (
    <>
      {!iconRight && <Icon icon={icon} size="1.5rem" right="0.75rem" />}
      {text}
      {iconRight && <Icon icon={icon} size="1.5rem" left="auto" />}
    </>
  )

  if (url) {
    return (
      <Link
        to={url}
        className={`header-nav__link ${iconRight ? 'right' : ''}`}
        onClick={action || doNothing}
      >
        {content}
      </Link>
    )
  }

  if (action) {
    return (
      <div
        id={id}
        className={`header-nav__link ${iconRight ? 'right' : ''}`}
        onClick={action}
      >
        {content}
      </div>
    )
  }

  return null
}

export default NavLink
