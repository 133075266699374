import { useDisclosure } from '@mantine/hooks'
import { AnimatePresence, motion } from 'framer-motion'
import { Icon } from '../common'

const ToolBarHider = ({ children }) => {
  const [show, { toggle: toggleShow }] = useDisclosure(true)

  return (
    <div className="toolbar-hider">
      <div className="toolbar-hider__btn" onClick={toggleShow}>
        <motion.div
          animate={{ rotate: show ? '0deg' : '180deg' }}
          transition={{ type: 'spring', duration: 0.5 }}
        >
          <Icon icon="chevron-left" size="1.5rem" />
        </motion.div>
      </div>

      <AnimatePresence>
        {show && (
          <motion.div
            className="toolbar-hider-content"
            initial={{ opacity: 0, x: 0, y: '-50%' }}
            animate={{ opacity: 1, x: '-100%', y: '-50%' }}
            exit={{ opacity: 0, x: 0 }}
            transition={{ type: 'spring', duration: 0.5 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ToolBarHider
