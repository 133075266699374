import React, { useEffect } from 'react'
import { useWindowEvent } from '@mantine/hooks'
import { useDebouncedCallback } from 'use-debounce'
import { ResponsiveStore as store } from '../../../store'
import { useDocumentEvent } from '../../../utils/hooks'

const breakpoints = {
  mobile: {
    minWidth: 0,
    maxWidth: 576,
  },
  tablet: {
    minWidth: 576,
    maxWidth: 768,
  },
  desktop: {
    minWidth: 768,
    maxWidth: Infinity,
  },
}

const ResponsiveProvider = ({ children }) => {
  const refreshSize = useDebouncedCallback(() => {
    if (document.hidden && !store.isInitial) return
    const width = window.innerWidth || 0

    for (const [breakpointName, sizeBorders] of Object.entries(breakpoints)) {
      if (width >= sizeBorders.minWidth && width < sizeBorders.maxWidth) {
        if (store.breakpoint !== breakpointName) {
          store.setBreakpoint(breakpointName)
        }
        break
      }
    }
  }, 100, { leading: true })

  useWindowEvent('resize', refreshSize, { passive: true })
  useWindowEvent('orientationchange', refreshSize, { passive: true })
  useDocumentEvent('visibilitychange', refreshSize, { passive: true })

  useEffect(() => {
    refreshSize()
  }, [refreshSize])

  return <>{children}</>
}

export default ResponsiveProvider
