import { useEffect, useState } from 'react'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Form, Icon } from '../common'
import { t } from 'i18next'
import { useConfirmDialog } from '../../utils/hooks'

const UserForm = ({ id, onGet, onUpdate, onCreate, onDelete, roles }) => {
  const [user, setUser] = useState(false)
  let { search } = useLocation()
  const history = useHistory()

  const isNew = id === 'new'
  const isRead = isNew ? false : search.search('isWrite') === -1

  const getTitle = () => {
    if (isNew) return t('user-create-title')
    if (isRead) return t('user-read-title')
    return t('user-edit-title')
  }

  const getActions = () => {
    if (isNew)
      return (
        <Link className="users-form__btn" to="/users">
          <Icon icon="close" size="1.5rem" />
        </Link>
      )

    return (
      <>
        {isRead && (
          <Link className="users-form__btn" to={`/users/${id}?isWrite`}>
            <Icon icon="edit" size="1.5rem" />
          </Link>
        )}
        <button className="users-form__btn" onClick={() => removeUser(id)}>
          <Icon icon="trash" size="1.5rem" />
        </button>
        {!isRead && (
          <Link className="users-form__btn" to={`/users/${id}`}>
            <Icon icon="close" />
          </Link>
        )}
      </>
    )
  }
  const username = user?.firstName
    ? user.firstName + ' ' + (user.lastName ? user.lastName : ' ')
    : user?.username

  const formConfig = {
    fields: [
      {
        name: 'Role',
        type: isRead ? null : 'radio',
        label: 'User Role',
        required: true,
        defaultValue: user?.role?.name || 'User',
        options: roles,
      },
      {
        name: 'FirstName',
        type: 'text',
        label: 'First Name',
        placeholder: 'enter name',
        required: true,
        disabled: isRead,
        defaultValue: user?.firstName || '',
      },
      {
        name: 'LastName',
        type: 'text',
        label: 'Last Name',
        placeholder: 'enter last name',
        required: true,
        disabled: isRead,
        defaultValue: user?.lastName || '',
      },
      {
        name: 'Email',
        type: 'email',
        label: 'E-mail',
        placeholder: 'enter e-mail',
        required: true,
        disabled: isRead,
        defaultValue: user?.email || '',
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: async (data, reject) => {
      const user = {
        role: data.role,
        email: data.email,
        firstName: data.firstname,
        lastName: data.lastname,
      }
      let promise = isNew ? onCreate(user) : onUpdate(id, user)
      promise
        .then((user) => {
          setUser(user)
          history.push('/users/' + user.id)
        })
        .catch((error) => {
          let message = error.response.data.message
          if (typeof message === 'string') {
            reject({ email: t(message) })
          } else {
            message = message[0].messages[0]
            if (message.field.includes('email'))
              reject({ email: message.message })
          }
        })
    },
    submitSlot: !isRead && (
      <>
        <Link className="btn-white-text" to={`/users/${id}`}>
          {t('btn-cancel')}
        </Link>
        <button type="submit" className="btn-primary">
          {t('form-save')}
        </button>
      </>
    ),
    requiredMessage: 'Field required',
  }
  const { modal: deleteModal, action: removeUser } = useConfirmDialog(
    (id) => onDelete(id).then(() => history.push('/users/')),
    {
      title: 'Delete',
      text: (
        <div className="t-lg c-primary">
          Do you really want to delete {username}?
        </div>
      ),
      confirmText: 'Delete',
    },
    []
  )
  const getUser = async () => {
    setUser(null)
    let user = await onGet(id)
    setUser(user)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getUser(), [id])
  return (
    <>
      <div className="users-form" data-aos="zoom-out">
        <div className="users-form-top">
          <div className="t-title">{getTitle()}</div>
          {getActions()}
        </div>
        {user && <Form config={formConfig} />}
      </div>
      {deleteModal}
    </>
  )
}

export default UserForm
