import { getArrayWithKeys } from '../../utils'

const Pagination = ({ page, changePage, maxPage, maxInLine = 6 }) => {
  const maxInLineClamped = Math.max(maxInLine, 3)

  const renderBullet = (key, targetPage, dots = false) => (
    <span
      key={key}
      className={`pagination__bullet ${targetPage === page ? 'active' : ''}`}
      onClick={() => {
        if (targetPage !== page) {
          changePage(targetPage)
        }
      }}
    >
      {dots ? '...' : String(targetPage).padStart(2, '0')}
    </span>
  )

  const renderBulletsList = () => {
    if (maxPage < maxInLineClamped) {
      return getArrayWithKeys(maxPage).map((item, index) => {
        return renderBullet(item.key, index + 1)
      })
    }

    const isPageInRawExpandedView = (targetPage) => {
      const greaterThanMinimum = page - pagesAtLeft <= targetPage
      const lowerThanMaximum = page + pagesAtRight >= targetPage

      return greaterThanMinimum && lowerThanMaximum
    }

    const pageIsNotFirstOrLast = page !== 1 && page !== maxPage
    const specialPages = 2 + (pageIsNotFirstOrLast ? 1 : 0)
    const pagesExcludingCurrentAndBorders = maxInLineClamped - specialPages

    // Before balancing
    let pagesAtLeft = Math.floor(pagesExcludingCurrentAndBorders / 2)
    let pagesAtRight = Math.ceil(pagesExcludingCurrentAndBorders / 2)

    if (isPageInRawExpandedView(1)){
      let pagesDelta = pagesAtLeft - page + 1

      // Make an additional shift to the right due to the fact that the
      // first one is fixed and not included in the "expanded" stream.
      // But while we are on the first page, there is nowhere to shift anyway
      if (page > 1) {
        pagesDelta++
      }

      pagesAtLeft -= pagesDelta
      pagesAtRight += pagesDelta
    } else if (isPageInRawExpandedView(maxPage)) {
      let pagesDelta = page - (maxPage - pagesAtRight)

      if (page < maxPage) {
        pagesDelta++
      }

      pagesAtLeft += pagesDelta
      pagesAtRight -= pagesDelta
    }

    let pageBeforeExpanded = page - pagesAtLeft - 1
    let pageAfterExpanded = page + pagesAtRight + 1
    const showDotsAtLeft = pageBeforeExpanded > 1
    const showDotsAtRight = pageAfterExpanded < maxPage

    // Reserve space for dots
    if (showDotsAtLeft) {
      pagesAtLeft--
      pageBeforeExpanded++
    }

    if (showDotsAtRight) {
      pagesAtRight--
      pageAfterExpanded--
    }

    return (
      <>
        {renderBullet('first', 1)}
        {showDotsAtLeft && renderBullet('dots-left', pageBeforeExpanded, true)}
        {getArrayWithKeys(pagesAtLeft).map((item, index) => {
          const targetPage = pageBeforeExpanded + index + 1
          return renderBullet(`left-${item.key}`, targetPage)
        })}

        {pageIsNotFirstOrLast && renderBullet('current', page)}

        {getArrayWithKeys(pagesAtRight).map((item, index) => {
          const targetPage = page + index + 1
          return renderBullet(`left-${item.key}`, targetPage)
        })}
        {showDotsAtRight && renderBullet('dots-right', pageAfterExpanded, true)}
        {renderBullet('last', maxPage)}
      </>
    )
  }

  if (maxPage === 0) return null

  return (
    <div className="pagination">
      <button
        disabled={page === 1}
        className="pagination__arrow left"
        onClick={() => changePage(page - 1)}
      >
        <svg
          width="5"
          height="10"
          viewBox="0 0 5 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5 0L0 5L5 10V0Z" fill="#1F4F80"/>
        </svg>
      </button>

      <div className="pagination-bullets">
        {renderBulletsList()}
      </div>

      <button
        disabled={page === maxPage}
        className="pagination__arrow right"
        onClick={() => changePage(page + 1)}
      >
        <svg
          width="5"
          height="10"
          viewBox="0 0 5 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 10L5 5L0 0L0 10Z" fill="#1F4F80"/>
        </svg>

      </button>
    </div>
  )
}

export default Pagination
