import { useScrollLock } from '@mantine/hooks'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Skeleton } from '../../components/common'
import Breadcrumbs from '../../components/layout/Breadcrumbs'
import CaseAnalyticCard, { CaseAnalyticCardLoader } from '../../components/analytics/CaseAnalyticCard'
import { AnalyticsStore as store } from '../../store'
import { observer } from 'mobx-react-lite'
import { t } from 'i18next'
import LinksTable from '../../components/analytics/LinksTable'

const AnalyticsCase = observer(() => {
  const { id } = useParams()

  useEffect(() => {
    store.fetchAnalytic(id)
    store.fetchTable('links', id)
  }, [id])


  const showPreloader = !store.analytic
    || !store.tableData
    || store.currentTable !== 'links'
    || store.isFetch

  const analytic = store.analytic
  const { page, limit } = store.filter
  const changePage = store.changePage

  const data = store.tableData ?? []
  const dataCount = store.tableDataCount
  const maxPage = Math.ceil(dataCount / limit)

  useScrollLock(showPreloader)
  if (showPreloader) return <Loader />

  return (
    <div className="analytic-page">
      <Breadcrumbs
        path={[
          { text: t('nav-analytic'), url: '/analytics' },
          analytic?.name
        ]}
      />

      <div className="analytic-list">
        <CaseAnalyticCard
          useCase={analytic}
          views={analytic.views}
          timeSum={analytic.time}
          lastView={analytic.lastDate}
        />

        <LinksTable
          data={data}
          analyticId={analytic.id}
          changePage={changePage}
          page={page}
          maxPage={maxPage}
        />
      </div>
    </div>
  )
})

const Loader = () => {
  return (
    <div className="wrapper skeleton-wrapper">
      <div data-aos="fade-down">
        <Skeleton width="100%" height="3.5rem" />
      </div>

      <div className="content analytic-page">
        <div className="breadcrumbs" data-aos="fade-down" data-aos-delay="150">
          <div className="breadcrumbs__back">
            <Skeleton width="1.5rem" height="1.5rem" radius="50%" />
          </div>

          <Skeleton width="4.5rem" height="1.5rem" radius="4rem" />
        </div>

        <div className="analytic-list">
          <CaseAnalyticCardLoader />

          <div>
            <Skeleton
              width="100%"
              height="2.7rem"
              radius="4rem"
              top="2.5rem"
              bottom="0.8rem"
            />

            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" bottom="1px" />
            <Skeleton width="100%" height="3rem" radius="4rem" />
          </div>
        </div>
      </div>

      <div className="mb-auto" data-aos="fade-up" data-aos-offset="-1000">
        <Skeleton width="100%" height="2.25rem" />
      </div>
    </div>
  )
}

export default AnalyticsCase
