import { t } from 'i18next'
import { Text, Icon } from '../../common'
import { CaseConstructorStore as store } from '../../../store'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useConfirmDialog } from '../../../utils/hooks'

const CaseView = observer(() => {
  const h = useHistory()
  const activeCase = store.activeCase

  const { modal, action: onRemoveCase } = useConfirmDialog(
    () => store.removeCase().then(() => h.push('/cases')),
    {
      title: 'Delete',
      text: (
        <>
          <div className="t-lg c-primary">
            Do you really want to delete this case?
          </div>
          <div className="t-md c-grey">
            It will be impossible to restore it.
          </div>
        </>
      ),
      confirmText: 'Delete',
    }
  )
  if (!activeCase) return null
  const onEditCase = () => store.editCase()

  return (
    <>
      <div className="c-view" data-aos="fade-down">
        <div className="c-view-top border-bottom">
          {activeCase.logo && (
            <img
              className="c-view-top__logo"
              src={process.env.REACT_APP_SERVER_URL + activeCase.logo.url}
              alt="logo"
            />
          )}
          <div className="t-title c-primary">{activeCase?.name || ''}</div>
          <div className="c-view-top__type">
            {activeCase?.type === 'video' ? 'Video' : 'Click through'}
          </div>
          <div className="c-view-top__left">
            <button onClick={onEditCase} title={t('c-edit-case-btn')}>
              <Icon icon="edit" />
            </button>
            <button onClick={onRemoveCase} title={t('c-remove-case-btn')}>
              <Icon icon="trash" size="1.5rem" />
            </button>
          </div>
        </div>
        <div className="case-description-content">
          {activeCase.presentationImage && (
            <div className="case-description-content__left">
              <img
                src={
                  process.env.REACT_APP_SERVER_URL +
                  activeCase.presentationImage.url
                }
                alt="Presentation"
              />
            </div>
          )}
          <div className="case-description-content__right">
            {activeCase.description && (
              <div className="case-description__block">
                <Text text="Description" className="t-lg" />
                <Text html={activeCase.description} />
              </div>
            )}
            {activeCase.userStory && (
              <div className="case-description__block">
                <Text text="User story" className="t-lg" />
                <Text html={activeCase.userStory} />
              </div>
            )}
            {activeCase.worths && (
              <div className="case-description__block">
                <Text text="Values" className="t-lg" />
                <Text html={activeCase.worths} />
              </div>
            )}
          </div>
        </div>
      </div>
      {modal}
    </>
  )
})

export default CaseView
