import ListStore from '../List'
import AuthStore from '../AuthStore'
import Notifications from '../MessagesStore'
import { CASES_API, RENT_API, CASES_CONSTRUCTOR_API } from '../../api'
import { makeAutoObservable, computed } from 'mobx'
import clipboardCopy from 'clipboard-copy'

class CasesBlockStore {
  tabs = []
  activeTab = null

  constructor({ tabs }) {
    this.tabs = tabs
    makeAutoObservable(this, {
      tab: computed,
    })
  }
  // /**
  //  * filter tabs by role
  //  */
  // get tabs() {
  //   const role = AuthStore.role
  //   let tabsKeys = []
  //   switch (role) {
  //     case 'Admin':
  //       tabsKeys = ['admin-platform']
  //       break
  //     case 'Administrator':
  //       tabsKeys = ['available-cases', 'in-progress-cases']
  //       break
  //     case 'Contributor':
  //       tabsKeys = ['available-cases', 'in-progress-cases']
  //       break
  //     case 'User':
  //       tabsKeys = ['available-cases']
  //       break
  //     default:
  //       tabsKeys = ['in-progress-cases']
  //   }
  //   return this.all_tabs.filter((tab) => tabsKeys.includes(tab.key))
  // }
  get tab() {
    return this.tabs.find((tab) => tab.key === this.activeTab)
  }
  /**
   * Change tab
   */
  changeTab(tab) {
    this.activeTab = tab
  }

  /**
   * Load tabs data
   */
  init() {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].store.fetchTable()
      this.tabs[i].store.getDataCount()
    }
    this.activeTab = this.tabs[0].key
  }

  async handleSendShare({ name }, activeCase) {
    const link = await CASES_API.shareCase(activeCase.id, { name })
    return link
  }
  async handleCopyShareLink(link) {
    await clipboardCopy(link)
  }
  async rentCase(activeCase) {
    try {
      await RENT_API.createRentRequest(activeCase.id)
      this.tab.store.setSearch('')
      return true
    } catch (error) {
      Notifications.addWarning(error.response.data.message)
    }
  }
  async handleRemoveDemoCase(id) {
    try {
      await CASES_CONSTRUCTOR_API.deleteCase(id)
      this.tab.store.changePage(1)
    } catch (error) {
      console.log(error.message)
    }
  }
}

const PlatformCases = new ListStore({
  fetchData: CASES_API.getPlatformCases,
  fetchDataCount: CASES_API.getPlatformCasesCount,
  limit: 6,
  onLoadCallback: (res) => res,
  onErrorCallback: (error) => {
    console.log(error.message)
  },
})

const AvailableCases = new ListStore({
  fetchData: CASES_API.getAvailableCases,
  fetchDataCount: CASES_API.getAvailableCasesCount,
  limit: 6,
  onLoadCallback: (res) => res,
  onErrorCallback: (error) => {
    console.log(error.message)
  },
})

const InprogressCases = new ListStore({
  fetchData: CASES_API.getInprogressCases,
  fetchDataCount: CASES_API.getInprogressCasesCount,
  limit: 6,
  onLoadCallback: (res) => res,
  onErrorCallback: (error) => {
    console.log(error.message)
  },
})

export const homeStore = new CasesBlockStore({
  tabs: [
    {
      title: (
        <>
          <div className="t-xl">Ready to rent</div>
          Demo cases
        </>
      ),
      store: PlatformCases,
      key: 'platform-cases',
    },
  ],
})
export const casesStore = new CasesBlockStore({
  tabs: [
    {
      title: 'Available',
      store: AvailableCases,
      key: 'available-cases',
    },
    {
      title: 'In progress',
      store: InprogressCases,
      key: 'in-progress-cases',
    },
  ],
})
