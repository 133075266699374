import Form from '../../components/common/form/Form'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../../store/index'
import { t } from 'i18next'
import { useHistory, useLocation } from 'react-router-dom'

const Registration = observer(() => {
  let history = useHistory()
  let location = useLocation()
  const formConfig = {
    fields: [
      {
        name: 'Plan',
        type: null,
        customRender: () => (
          <div>
            Plan <span className="c-primary">"On Demand"</span>
          </div>
        ),
      },
      {
        name: 'Name',
        label: 'First name',
        type: 'text',
        placeholder: 'Enter first name',
        required: true,
      },
      {
        name: 'Surname',
        label: 'Surname',
        type: 'text',
        placeholder: 'Enter surname',
        required: true,
      },
      {
        name: 'Company',
        label: 'Company',
        type: 'text',
        placeholder: 'Enter company name',
        required: true,
      },
      {
        name: 'Email',
        label: 'E-mail',
        type: 'email',
        placeholder: 'Enter e-mail',
        required: true,
      },
      {
        name: 'Password',
        label: 'Password',
        type: 'password',
        placeholder: 'Create password',
        message: 'Password must be at least 6 characters',
        required: true,
      },
      {
        name: 'RepeatPassword',
        label: 'Repeat password',
        type: 'password',
        placeholder: 'Repeat password',
        message: 'Password must be at least 6 characters',
        required: true,
      },
    ],
    validate: (data, accept, reject) => {
      if (data.password !== data.repeatpassword) {
        reject({ repeatpassword: 'Пароли не совпадают' })
      } else {
        accept()
      }
    },
    submit: (data, reject) => {
      AuthStore.register(data)
        .then(() => {
          let { from } = location.state || { from: { pathname: '/' } }
          history.replace(from)
        })
        .catch((err) => {
          const errorCode = err.response.data.message
          if (errorCode === 'company_not_unique') {
            reject({ company: 'This company name is already in use' })
          } else if (errorCode === 'email_not_unique') {
            reject({ email: 'This email is already in use' })
          }
        })
    },
    submitSlot: (
      <div className="submit-row">
        <span className="required-message">Required fields</span>
        <button type="submit" className="btn-primary">
          {t('auth-sign-up')}
        </button>
      </div>
    ),
    requiredMessage: 'Field required',
  }

  return (
    <div className="auth-page">
      <div className="t-title c-primary" data-aos="fade-down">
        {t('auth-sign-up-title')}
      </div>
      <div data-aos="fade-right">
        <Form config={formConfig} />
      </div>
      <img
        className="page-back-image"
        src="/assets/images/work-table.png"
        alt="table"
        data-aos="fade-left"
      />
    </div>
  )
})

export default Registration
