const Skeleton = ({ width, height, radius, top, left, right, bottom }) => {
  return (
    <div
      className="skeleton"
      style={{
        width: width,
        height: height,
        borderRadius: radius,
        marginTop: top || 0,
        marginLeft: left || 0,
        marginRight: right || 0,
        marginBottom: bottom || 0,
      }}
    />
  )
}

export default Skeleton
