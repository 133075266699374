import React from 'react'
import { observer } from 'mobx-react-lite'
import { ResponsiveStore as store } from '../../../store'

const Responsive = observer(({ children, breakpoints = [] }) => {
  const breakpoint = store.breakpoint

  if (!breakpoints.includes(breakpoint)) {
    return null
  }

  return <>{typeof children === 'function' ? children(breakpoint) : children}</>
})

export default Responsive
export { default as ResponsiveProvider } from './ResponsiveProvider'
