import StepsList from '../step/StepsList'
import { Icon, EditableField } from '../../common'
import { t } from 'i18next'
import { CaseConstructorStore as store } from '../../../store'
import { observer } from 'mobx-react-lite'
import { useConfirmDialog } from '../../../utils/hooks'

const StageView = observer(() => {
  const stage = store.stage
  if (!stage) return null

  const onRemoveStage = () => store.removeStage()
  const { modal: deleteModal, action: removeStage } = useConfirmDialog(
    onRemoveStage,
    {
      title: 'Delete',
      text: (
        <>
          <div className="t-lg c-primary">
            Do you really want to delete this stage?
          </div>
          <div className="t-md c-grey">
            It will be impossible to restore it.
          </div>
        </>
      ),
      confirmText: 'Delete',
    },
    []
  )

  return (
    <div className="c-view">
      <div className="c-view-top">
        <EditableField
          key={stage.id}
          field={stage.name}
          onChange={(name) => store.saveStage({ name })}
          maxLength={30}
        />
        <div className="c-view-top__left">
          <button onClick={removeStage} title={t('c-remove-stage-btn')}>
            <Icon icon="trash" />
          </button>
        </div>
      </div>

      <StepsList steps={stage.steps} />

      {deleteModal}
    </div>
  )
})

export default StageView
