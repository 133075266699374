import { OverlayLink, Icon, Responsive, Skeleton } from '../common'
import { getTime, getDate } from '../../utils'

const CaseAnalyticCard = ({ useCase, views, timeSum, lastView, showMore }) => {
  const image = useCase.presentationImage
    ? process.env.REACT_APP_SERVER_URL + useCase.presentationImage.url
    : null

  const imageComponent = (
    image ? (
      <img
        className="analytic-list-item__img"
        src={image}
        alt={useCase.name}
      />
    ) : (
      <div className="analytic-list-item__img">
        <div className="analytic-list-item__img-placeholder">
          <Icon icon="image" />
          <p className="analytic-list-item__img-placeholder__text">
            There is no Picture
          </p>
        </div>
      </div>
    )
  )

  return (
    <div className="analytic-list-item">
      {showMore ? (
        <OverlayLink linkText="Show more" to={`/analytics/${useCase.id}`}>
          {imageComponent}
        </OverlayLink>
      ) : (
        imageComponent
      )}

      <div className="analytic-list-item-content">
        <div className="analytic-list-item-top">
          <div className="analytic-list-item__title">
            {useCase.name}
          </div>
          <div className="analytic-list-item__statistics">
            {views} views
          </div>
        </div>

        <Responsive breakpoints={['desktop']}>
          <div className="analytic-list-item-row">
            <p className="analytic-list-item__statistics">
              {getTime(timeSum)}
            </p>
            {lastView && (
              <>
                <div className="analytic-list-item-row__separator" />
                <p className="analytic-list-item__statistics">
                  Last viewing {getDate(lastView)}
                </p>
              </>
            )}
          </div>
        </Responsive>
      </div>
    </div>
  )
}

export default CaseAnalyticCard

export const CaseAnalyticCardLoader = () => {
  return (
    <div className="analytic-list-item">
      <div className="analytic-list-item__img">
        <Skeleton width="100%" height="100%" />
      </div>

      <div className="analytic-list-item-content">
        <div className="analytic-list-item-top">
          <div className="analytic-list-item__title">
            <Skeleton
              width="8rem"
              height="1.5rem"
              radius="4rem"
              bottom="0.5rem"
            />
          </div>
          <div className="analytic-list-item__statistics">
            <Skeleton width="3.5rem" height="0.8rem" radius="4rem" />
          </div>
        </div>

        <Responsive breakpoints={['desktop']}>
          <div className="analytic-list-item-row">
            <Skeleton width="20rem" height="1rem" radius="4rem" />
          </div>
        </Responsive>
      </div>
    </div>
  )
}
