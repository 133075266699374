import {
  NotFoundError,
  ServerSideError,
  MobileOhNo,
  Login,
  Registration,
  RegistrationFinish,
  ForgetPassword,
  ResetPassword,
  Home,
  Users,
  User,
  Analytics,
  AnalyticsCase,
  AnalyticsCaseSessions,
  Settings,
  CasePreview,
  Constructor,
  Cases,
} from './pages/index'

const routes = [
  {
    name: 'Not Found Error',
    path: '/error-404',
    protected: false,
    mobileReady: true,
    component: NotFoundError,
  },
  {
    name: 'Server Side Error',
    path: '/error-500',
    protected: false,
    mobileReady: true,
    component: ServerSideError,
  },
  {
    name: 'Mobile Oh No',
    path: '/mobile-oh-no',
    protected: false,
    mobileReady: true,
    component: MobileOhNo,
    layout: 'clean',
  },
  {
    name: 'Login',
    path: '/login',
    protected: false,
    mobileReady: true,
    component: Login,
    redirect: '/',
  },
  {
    name: 'Complete Registration',
    path: '/registration/:code',
    protected: false,
    mobileReady: true,
    component: RegistrationFinish,
    redirect: '/',
  },
  {
    name: 'Registration',
    path: '/registration',
    protected: false,
    mobileReady: true,
    component: Registration,
    redirect: '/',
  },
  {
    name: 'Forget Password',
    path: '/forget-password',
    protected: false,
    mobileReady: true,
    component: ForgetPassword,
    redirect: '/',
  },
  {
    name: 'Reset Password',
    path: '/reset-password/:code',
    protected: false,
    mobileReady: true,
    component: ResetPassword,
    redirect: '/',
  },
  {
    name: 'Case Preview',
    path: '/cases/preview/:id',
    protected: false,
    mobileReady: false,
    component: CasePreview,
    layout: 'case',
  },
  {
    name: 'Constructor New Case',
    path: '/constructor/new',
    protected: true,
    mobileReady: false,
    component: Constructor,
    layout: 'constructor',
  },
  {
    name: 'Cases',
    path: '/cases',
    protected: true,
    mobileReady: true,
    component: Cases,
  },
  {
    name: 'Constructor',
    path: '/constructor/:id',
    protected: true,
    mobileReady: false,
    component: Constructor,
    layout: 'constructor',
  },
  {
    name: 'Constructor',
    path: '/constructor',
    protected: true,
    mobileReady: false,
    component: Constructor,
  },
  {
    name: 'User',
    path: '/users/:id',
    protected: true,
    mobileReady: true,
    component: User,
  },
  {
    name: 'Users',
    path: '/users',
    protected: true,
    mobileReady: true,
    component: Users,
  },
  {
    name: 'Case Analytic',
    path: '/analytics/:id',
    protected: true,
    mobileReady: true,
    component: AnalyticsCase,
  },
  {
    name: 'Case Analytic (Sessions)',
    path: '/analytics/:id/:linkId',
    protected: true,
    mobileReady: true,
    component: AnalyticsCaseSessions,
  },
  {
    name: 'Analytics',
    path: '/analytics',
    protected: true,
    mobileReady: true,
    component: Analytics,
  },
  {
    name: 'Settings',
    path: '/settings',
    protected: true,
    mobileReady: true,
    component: Settings,
  },
  {
    name: 'Home',
    path: '/',
    protected: true,
    mobileReady: true,
    component: Home,
  },
]

export default routes
