import { observer } from 'mobx-react-lite'

import StepImageForm from '../step/StepImageForm'
import StepVideoForm from '../step/StepVideoForm'
import PreviewStep from '../step/PreviewStep'
import CommentArea from '../comment/CommentArea'
import LinkArea from '../comment/LinkArea'
import Comment from '../comment/Comment'
import CommentForm from '../forms/CommentForm'
import CommentsOrderForm from '../forms/CommentsOrderForm'
import LinkForm from '../forms/LinkForm'

import ToolBarHider from '../ToolBarHider'
import ToolBar from '../ToolBar'
import CustomCrop from '../CustomCrop'

/**
 * STEP VIEW
 * - File input    							(when step has no image)
 * - Step preview								(all links and comments)
 * - Open comment with tooltip	(comment area with tooltip)
 * - Open link with tooltip			(link area with tooltip)
 * - Area input 								(when create comment or link or change area)
 * - Comment form								(when create/update comment)
 * - Link form									(when create/update link)
 */

const StepView = observer(({ type, store }) => {
  const step = store.step
  if (!step) return null

  // File input
  if (!step.image) {
    if (type === 'video') {
      return <StepVideoForm onChange={(data) => store.saveStepImage(data)} />
    } else {
      return <StepImageForm onChange={(data) => store.saveStepImage(data)} />
    }
  }

  // Area input
  if (store.area)
    return (
      <CustomCrop
        area={store.area}
        onClose={() => store.closeArea()}
        onSubmit={(crop) => store.handleAreaUpdate(crop)}
      >
        <PreviewStep type={type} step={step} />
      </CustomCrop>
    )

  const comment = store.comment || null
  const link = store.link || null
  const form = store.form

  const lastAddedStage = store.store.lastAddedStage
  const lastAddedStep = store.store.lastAddedStep

  const StepCommentsAndLinksList = () => (
    <>
      <ToolBarHider>
        <ToolBar
          items={[
            {
              icon: 'step-description',
              onClick: () => store.editStep(),
              label: 'Step Description',
            },
            {
              icon: 'comment-check',
              onClick: () => store.createComment(),
              label: 'Add Comment',
            },
            {
              icon: 'link',
              onClick: () => store.createLink(),
              label: 'Add link',
            },
            {
              icon: 'trash',
              onClick: () => store.removeImage(),
              label: 'Remove Image',
            },
          ]}
        />
      </ToolBarHider>
      {step.comments.map((comment, i) => (
        <CommentArea
          key={comment.id}
          comment={{ ...comment }}
          onOpen={() => store.setActive('comment', comment)}
        />
      ))}
      {step.links.map((link, i) => (
        <LinkArea
          key={link.id}
          link={{ ...link }}
          onOpen={() => store.setActive('link', link)}
        />
      ))}
    </>
  )

  const VideoStepControls = () => (
    <ToolBarHider>
      <ToolBar
        items={[
          {
            icon: 'edit',
            onClick: () => store.editStep(),
            label: 'Change video name',
          },
          {
            icon: 'trash',
            onClick: () => {
              store.removeImage(true)
            },
            label: 'Remove video',
          },
        ]}
      />
    </ToolBarHider>
)

  const OpenedComment = () => (
    <Comment comment={comment} close={() => store.setActive('comment', null)}>
      <ToolBar
        items={[
          {
            icon: 'link',
            onClick: () => store.changePosition(),
            label: 'Change position',
          },
          {
            icon: 'edit',
            onClick: () => store.toggleForm('comment'),
            label: 'Edit Comment',
          },
          {
            icon: 'list',
            onClick: () => store.toggleForm('comments-order'),
            label: 'Organize comments',
          },
          {
            icon: 'trash',
            onClick: () => store.removeComment(),
            label: 'Remove Comment',
          },
        ]}
      />
    </Comment>
  )

  const OpenedCommentForm = () => (
    <Comment comment={comment}>
      <CommentForm
        isNew={!comment?.id}
        comment={comment}
        submit={(data) => store.saveComment(data)}
        close={() => store.toggleForm(null)}
      />
    </Comment>
  )

  const OpenedCommentsOrderForm = () => (
    <Comment comment={comment}>
      <CommentsOrderForm
        comments={step.comments}
        submit={(data) => store.reorderComments(data)}
        close={() => store.toggleForm(null)}
      />
    </Comment>
  )

  const OpenedLink = () => (
    <Comment comment={link} close={() => store.setActive('link', null)}>
      <ToolBar
        items={[
          {
            icon: 'position',
            onClick: () => store.changePosition(),
            label: 'Change position',
          },
          {
            icon: 'link',
            onClick: () => store.toggleForm('link'),
            label: 'Edit Link',
          },
          {
            icon: 'trash',
            onClick: () => store.removeLink(),
            label: 'Remove Link',
          },
        ]}
      />
    </Comment>
  )
  const stages = store.store.activeCase.stages
  const OpenedLinkForm = () => (
    <Comment comment={link}>
      <LinkForm
        isNew={!link?.id}
        link={link}
        stages={stages}
        lastAddedStage={lastAddedStage}
        lastAddedStep={lastAddedStep}
        submit={(data) => store.saveLink(data)}
        close={() => store.toggleForm(null)}
        onNewStage={() => store.store.createStage(false)}
        onNewStep={(stage) => store.store.createStep(stage.id, false)}
      />
    </Comment>
  )

  const renderContent = () => {
    // Show links and comment
    if (comment === null && link === null && !form) {
      if (type === 'video') {
        return <VideoStepControls />
      } else {
        return <StepCommentsAndLinksList />
      }
    }
    // Show active comment
    if (comment !== null && link === null && !form) return <OpenedComment />
    // Show active link
    if (comment === null && link !== null && !form) return <OpenedLink />
    // Show active comment form
    if (form === 'comment') return <OpenedCommentForm />
    // Show comment order form
    if (form === 'comments-order') return <OpenedCommentsOrderForm />
    // Show active link form
    if (form === 'link') return <OpenedLinkForm />
  }

  return (
    <PreviewStep type={store.store.activeCase.type} step={step}>
      {renderContent()}
    </PreviewStep>
  )
})

export default StepView
