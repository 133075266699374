import VideoUploader from '../forms/VideoUploader'
import { Icon } from '../../common'

const StepVideoForm = ({ onChange }) => (
  <VideoUploader
    onChange={onChange}
    hideRemove
    showIndicatorInside
    haveBrowseButton
    placeholder={
      <>
        <Icon icon="new-video" size="2.5rem" bottom="0.5rem" />
        <div className="c-primary t-center">
          Drag a video here
        </div>
      </>
    }
  />
)

export default StepVideoForm
