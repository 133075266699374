import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { CasePreviewStore as store } from '../../store'
import { observer } from 'mobx-react-lite'

import PreviewStep from '../constructor/step/PreviewStep'
import { Icon, Text } from '../common'
import Header from './Header'
import CommentArea from '../constructor/comment/CommentArea'
import LinkArea from '../constructor/comment/LinkArea'
import Comment from '../constructor/comment/Comment'
import { preloadImages } from '../../utils'

const Preview = observer(() => {
  const [showDescription, setShowDescription] = useState(false)

  const activeCase = store.activeCase
  const stage = store.stage
  const step = store.step
  const comment = store.comment
  const next = (stageId, stepId) => store.next(stageId, stepId)

  const changePage = (page) => {
    const targetComment = step.comments.find(({ order }) => order === page)
    store.toggleComment(targetComment.id)
  }

  useEffect(() => {
    if (step.description) {
      setShowDescription(true)

      const hideDescriptionTimer = setTimeout(() => {
        setShowDescription(false)
      }, 5000)

      return () => {
        clearTimeout(hideDescriptionTimer)
      }
    }
  }, [step.description, step.id])

  useEffect(() => {
    if (showDescription && comment) {
      setShowDescription(false)
    }
  }, [showDescription, comment])

  useEffect(() => {
    if (activeCase.type === 'video') return

    if (Array.isArray(step.links) && step.links.length > 0) {
      const images = step.links.map((link) => {
        let targetStage

        if (stage.id === link.toStage) {
          targetStage = stage
        } else {
          targetStage = activeCase.stages.find(({ id }) => id === link.toStage)
        }

        const targetStep = targetStage.steps.find(({ id }) => id === link.toStep)
        return targetStep.image?.url
          ? process.env.REACT_APP_SERVER_URL + targetStep.image.url
          : null
      })

      const notEmptyImages = images.filter((image) => image !== null)
      return preloadImages(notEmptyImages)
    }
  }, [step.links, activeCase.type, activeCase.stages, stage])

  return (
    <>
      <Header
        showDescription={showDescription}
        setShowDescription={setShowDescription}
      />
      <PreviewStep type={activeCase.type} step={step} autoPlay>
        <StepDescription
          name={step.name}
          description={step.description}
          actionText={step.actionText}
          show={showDescription}
          onHideDescription={() => setShowDescription(false)}
        />
        {comment ? (
          <Comment
            comment={comment}
            commentsCount={step.comments.length}
            close={() => store.toggleComment(comment.id)}
            changePage={changePage}
          />
        ) : (
          <>
            {step.comments.map((comment, i) => (
              <CommentArea
                key={comment.id}
                comment={{ ...comment }}
                onOpen={() => store.toggleComment(comment.id)}
              />
            ))}
            {step.links.map((link, i) => (
              <LinkArea
                key={link.id}
                link={{ ...link }}
                onOpen={() => next(link.toStage, link.toStep)}
              />
            ))}
          </>
        )}
      </PreviewStep>
    </>
  )
})

export default Preview

const StepDescription = ({ name, description, actionText, show, onHideDescription }) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="step-description"
          initial={{ opacity: 0, x: '100%' }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: '100%' }}
          transition={{ type: 'spring', duration: 0.5 }}
        >
          <button className="step-description__close" onClick={onHideDescription}>
            <Icon icon="close" size="1.5rem" />
          </button>
          <div className="step-description__name">{name}</div>
          <div className="step-description__content">
            <p>{actionText}</p>
            <Text html={description} />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
