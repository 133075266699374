import { makeAutoObservable, computed } from 'mobx'
class ListStore {
  data = null
  isFetchingTable = false
  allDataCount = 0
  mode = 'pages'
  cardsModeOffset = 0

  filter = {
    page: 1,
    card: 1,
    search: '',
  }

  async loadMethod() {}
  async loadCount() {}
  async onLoadCallback() {}

  constructor({
    fetchData,
    fetchDataCount,
    limit,
    onLoadCallback,
    onErrorCallback,
  }) {
    makeAutoObservable(this, { isLoadMore: computed })
    this.filter = { ...this.filter, limit: limit }
    this.loadMethod = fetchData
    this.loadCount = fetchDataCount
    this.onLoadCallback = onLoadCallback
    this.onErrorCallback = onErrorCallback

    this.data = []
  }

  changeFilter(filter) {
    this.filter = filter
    this.allDataCount = this.fetchDataCount(this.filter)
    this.data = []
  }

  changeMode(mode) {
    this.mode = mode
    this.fetchTable()
  }

  async getDataCount() {
    const data = await this.loadCount(this.filter)
    this.allDataCount = data
  }

  async fetchTable() {
    this.isFetchingTable = true

    try {
      if (this.mode === 'pages') {
        const pageRawData = await this.loadMethod(this.filter)
        const pageData = this.onLoadCallback(pageRawData)
        this.data = pageData
      } else if (this.mode === 'cards') {
        const firstIndex = this.cardsModeOffset
        const lastIndex = this.cardsModeOffset + this.data.length - 1
        const targetIndex = this.filter.card - 1
        const targetPage = this.filter.page
        const lastPage = Math.ceil(this.allDataCount / this.filter.limit)
        const pageRawData = this.data

        if (targetIndex >= firstIndex - 1 && targetIndex <= lastIndex + 1) {
          // EXPANDING MODE
          if (targetPage > 1 && targetIndex <= firstIndex + 1) {
            // [EXPAND] Load data BEFORE current page
            const expansionData = await this.loadMethod({
              ...this.filter,
              page: targetPage - 1,
            })

            pageRawData.unshift(...expansionData)
            this.cardsModeOffset -= expansionData.length
          } else if (targetPage < lastPage && targetIndex >= lastIndex - 1) {
            // [EXPAND] Load data AFTER current page
            const expansionData = await this.loadMethod({
              ...this.filter,
              page: targetPage + 1,
            })

            pageRawData.push(...expansionData)
          } else {
            // Normal browsing
          }
        } else {
          // RANDOM ACCESS MODE
          const overrideData = await this.loadMethod({
            ...this.filter,
            page: targetPage,
            // limit,
          })

          pageRawData.length = 0
          pageRawData.push(...overrideData)
          this.cardsModeOffset = (targetPage - 1) * this.filter.limit

          // Load additional page if we're at the edge of current page
          this.fetchTable()
        }

        const pageData = this.onLoadCallback(pageRawData)
        this.data = pageData
      }
    } catch (err) {
      this.allDataCount = 0
      this.data = []
      if (this.onErrorCallback) {
        this.onErrorCallback(err)
      } else {
        return Promise.reject(err)
      }
    }

    this.isFetchingTable = false
  }

  changePage = (page) => {
    this.filter.page = page
    this.filter.card = (page - 1) * this.filter.limit + 1
    this.fetchTable()
  }

  changeCard = (card) => {
    this.filter.page = Math.floor((card - 1) / this.filter.limit) + 1
    this.filter.card = card
    this.fetchTable()
  }

  setSearch = (val) => {
    this.filter = {
      ...this.filter,
      page: 1,
      search: val,
    }
    this.fetchTable()
    this.getDataCount()
  }

  get isLoadMore() {
    return this.data && this.data.length < this.allDataCount
  }
}

export default ListStore
