import ImageUploader from '../forms/ImageUploader'
import { Icon } from '../../common'

const StepImageForm = ({ onChange }) => (
  <ImageUploader
    onChange={onChange}
    hideRemove
    showIndicatorInside
    haveBrowseButton
    placeholder={
      <>
        <Icon icon="file" size="2.5rem" bottom="0.5rem" />
        <div className="c-primary t-center">
          Drag an image here
        </div>
      </>
    }
  />
)

export default StepImageForm
