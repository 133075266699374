import { useState } from 'react'
import { Table, Icon, Responsive } from '../common'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

const UsersTable = ({ users, removeUser, changePage, page, maxPage }) => {
  return (
    <div className="users-list" data-aos="zoom-out">
      <Responsive breakpoints={['mobile', 'tablet', 'desktop']}>
        {(size) => (
          <Table
            className="users-table"
            data={users}
            changePage={changePage}
            page={page}
            maxPage={maxPage}
            columns={[
              {
                name: 'users',
                key: 'users',
                render: (row) => `${row?.firstName} ${row.lastName}`,
              },
              {
                name: 'role',
                key: 'role',
                render: (row) => row.role?.name || 'none',
              },
              {
                name: 'email',
                key: 'email',
                hidden: size !== 'desktop',
                render: (row) => row.email,
              },
              {
                name: 'Last Activity',
                key: 'last-activity',
                hidden: size !== 'desktop',
                render: (row) => new Date(row.updatedAt).toLocaleDateString(),
              },
              {
                name: '',
                key: 'more',
                width: 'auto',
                render: (user) => (
                  <MoreBtn id={user.id} removeUser={() => removeUser(user.id)} />
                ),
              },
            ]}
          />
        )}
      </Responsive>
    </div>
  )
}

export default UsersTable

const MoreBtn = ({ id, removeUser }) => {
  const [open, setOpen] = useState(false)
  return (
    <div className="more-btn" onClick={() => setOpen(!open)}>
      <Icon icon="more" size="1.5rem" />

      {open && (
        <div className="more-btn-content" data-aos="zoom-out">
          <Link className="more-btn__link" to={`/users/${id}`}>
            {t('user-detail-btn')}
          </Link>
          <Link className="more-btn__link" to={`/users/${id}?isWrite`}>
            {t('user-edit-btn')}
          </Link>
          <div
            className="more-btn__link"
            onClick={() => {
              if (removeUser) removeUser()
            }}
          >
            {t('user-remove-btn')}
          </div>
        </div>
      )}
    </div>
  )
}
