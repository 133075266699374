import { useState } from 'react'
import Breadcrumbs from '../components/layout/Breadcrumbs'
import { Form, Icon /* Text */ } from '../components/common'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../store'
import { t } from 'i18next'

const Settings = observer(() => {
  const currentUser = AuthStore.currentUser
  const [isRead, setIsRead] = useState(true)
  if (!currentUser) return null

  // const showPlan = currentUser.role.name === 'Administrator'

  let formConfig = {
    autocomplete: 'off',
    fields: [
      {
        name: 'Subscription',
        type: null,
        label: 'Subscription',
        customRender: (props) => (
          <div className="user-plan">
            <div className="user-plan__value">
              {currentUser.client ? (
                <>
                  {currentUser.client.clientType.name}
                  <span>
                    ( $ {currentUser.client.clientType.price} / month )
                  </span>
                </>
              ) : (
                'Admin'
              )}
            </div>
          </div>
        ),
      },
      // {
      //   name: 'payment',
      //   type: null,
      //   label: showPlan && <span className="c-primary">Next payment:</span>,
      //   customRender: (props) =>
      //     showPlan ? (
      //       <div className="user-plan">
      //         <div className="user-plan__date">01.05.2022</div>
      //         <MoreBtn />
      //       </div>
      //     ) : (
      //       ''
      //     ),
      // },
      {
        name: 'Role',
        type: 'text',
        label: 'Role of User',
        defaultValue: currentUser.role.name,
        disabled: true,
      },
      {
        name: 'FirstName',
        type: 'text',
        label: 'Name',
        placeholder: 'Enter name',
        defaultValue: currentUser.firstName,
        disabled: isRead,
        required: true,
      },
      {
        name: 'LastName',
        type: 'text',
        label: 'Surname',
        placeholder: 'Enter surname',
        defaultValue: currentUser.lastName,
        disabled: isRead,
        required: true,
      },
      {
        name: 'Email',
        type: 'email',
        label: 'E-mail',
        placeholder: 'enter e-mail',
        defaultValue: currentUser.email,
        disabled: isRead,
        required: true,
      },
      // {
      //   name: 'Password',
      //   type: null,
      //   label: 'Password',
      //   customRender: () => (
      //     <>
      //       <Icon icon="lock" size="1rem" left="0.75rem" right="0.5rem" />{' '}
      //       <Text
      //         text="Change Password"
      //         className="t-md fw-500 op-50 c-primary"
      //       />
      //     </>
      //   ),
      // },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject) => {
      delete data.role
      AuthStore.updateUser({ ...data })
        .then(() => setIsRead(true))
        .catch((err) => {
          reject('Error')
        })
    },
    submitSlot: isRead ? null : (
      <button type="submit" className="btn-primary">
        {t('form-save')}
      </button>
    ),
    requiredMessage: 'Field required',
  }

  return (
    <div className="users-page settings">
      <Breadcrumbs path={[t('nav-settings')]} />

      <div className="users-form">
        <div className="users-form-top">
          <div className="t-title">
            {t(isRead ? 'settings-title' : 'settings-edit-title')}
          </div>

          {isRead && (
            <button
              className="users-form__btn"
              onClick={() => setIsRead(false)}
              title={t('btn-edit')}
            >
              <Icon icon="edit" size="1.5rem" />
            </button>
          )}

          {!isRead && (
            <button
              className="users-form__btn"
              onClick={() => setIsRead(true)}
              title={t('btn-cancel')}
            >
              <Icon icon="close" size="1.5rem" />
            </button>
          )}
        </div>

        <Form key={isRead} config={formConfig} />
      </div>
    </div>
  )
})

export default Settings

// const MoreBtn = ({ id }) => {
//   const [open, setOpen] = useState(false)
//   return (
//     <div className="more-btn" onClick={() => setOpen(!open)}>
//       <Icon icon="more" size="1.5rem" />

//       {open && (
//         <div className="more-btn-content" data-aos="zoom-out">
//           <div className="more-btn__link">{t('Pay subscription')}</div>
//           <div className="more-btn__link">{t('Choose Plan')}</div>
//           <div className="more-btn__link">{t('Delete card')}</div>
//         </div>
//       )}
//     </div>
//   )
// }
