import { useEffect } from 'react'
import Form from '../../components/common/form/Form'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../../store/index'
import { useParams, useHistory } from 'react-router-dom'
import { t } from 'i18next'

const Registration = observer(() => {
  useEffect(() => AuthStore.logOut(), [])
  const { code } = useParams()
  let history = useHistory()
  const formConfig = {
    fields: [
      {
        name: 'Password',
        label: 'Password',
        type: 'password',
        placeholder: 'create password',
        message: 'Passwords must ba at least 6  characters long',
        required: true,
      },
      {
        name: 'RepeatPassword',
        label: 'Repeat password',
        type: 'password',
        placeholder: 'repeat password',
        message: 'Passwords must ba at least 6  characters long',
        required: true,
      },
    ],
    validate: (data, accept, reject) => {
      if (data.password !== data.repeatpassword) {
        reject({ repeatpassword: 'Passwords don\'t match' })
      } else {
        accept()
      }
    },
    submit: (data, reject) => {
      AuthStore.finishRegistration(data, code)
        .then(() => {
          history.push('/')
        })
        .catch((err) => {
          reject({ email: 'Error' })
        })
    },
    submitSlot: (
      <div className="submit-row">
        <span className="required-message">Required fields</span>
        <button type="submit" className="btn-primary">
          Enter
        </button>
      </div>
    ),
  }

  return (
    <div className="auth-page">
      <div className="t-title c-primary" data-aos="fade-down">
        {t('auth-sign-up-title')}
      </div>
      <div data-aos="fade-right">
        <Form config={formConfig} />
      </div>
      <img
        className="page-back-image"
        src="/assets/images/work-table.png"
        alt="table"
        data-aos="fade-left"
      />
    </div>
  )
})

export default Registration
