import { useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Icon, Pagination } from '../../components/common'
// import ContentLoader from 'react-content-loader'

const CarouselList = ({
  renderItem,
  data,
  dataOffset,
  isFetch,
  changeCard,
  card,
  maxCard,
  limit,
  noDataIcon = 'folder-art',
  noDataText = 'There is no data yet',
}) => {
  const swiperRef = useRef(null)

  const setSwiperRef = (swiper) => {
    swiperRef.current = swiper
  }

  const handleChangeCard = () => {
    const { current: swiper } = swiperRef
    changeCard(swiper.activeIndex + dataOffset + 1)
  }

  useEffect(() => {
    if (swiperRef.current) {
      const { current: swiper } = swiperRef
      const currentCard = swiper.activeIndex + dataOffset + 1

      if (currentCard !== card && !isFetch) {
        swiper.slideTo(card - dataOffset - 1)
      }
    }
  }, [card, dataOffset, isFetch, data])

  return (
    <div className="list-wrapper">
      <Swiper
        spaceBetween={4}
        slidesPerView="auto"
        centeredSlides
        onSwiper={setSwiperRef}
        onSlideChange={handleChangeCard}
      >
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <SwiperSlide key={index}>
              {renderItem(item)}
            </SwiperSlide>
          ))
        ) : (
           <SwiperSlide>
             <div className="list__no-item">
               <Icon icon={noDataIcon} />
               {noDataText}
             </div>
           </SwiperSlide>
         )}
      </Swiper>

      <Pagination changePage={changeCard} page={card} maxPage={maxCard} />
    </div>
  )
}

export default CarouselList

// const Loader = () => {
//   return (
//     <ContentLoader
//       speed={2}
//       width="100%"
//       height="8rem"
//       viewBox="0 0 100% 8rem"
//       backgroundColor="#f3f3f3"
//       foregroundColor="#ecebeb"
//     >
//       <rect x="3%" y="3rem" rx="3" ry="3" width="20%" height="2rem" />
//       <rect x="25%" y="3rem" rx="3" ry="3" width="20%" height="2rem" />
//       <rect x="47%" y="3rem" rx="3" ry="3" width="20%" height="2rem" />
//       <rect x="69%" y="3rem" rx="3" ry="3" width="20%" height="2rem" />
//       <rect x="91%" y="3rem" rx="3" ry="3" width="2rem" height="2rem" />
//     </ContentLoader>
//   )
// }
