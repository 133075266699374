import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useDisclosure, useScrollLock } from '@mantine/hooks'
import { Link } from 'react-router-dom'
import { Icon, Skeleton } from '../common'

const PreviewCard = ({ isFetch, activeCase, next, handleImageLoaded }) => {
  const [openDropdown, setOpenDropdown] = useState(null)
  const [showHelp, { toggle: toggleShowHelp }] = useDisclosure(true)
  const open = (name) => setOpenDropdown(name === openDropdown ? null : name)

  // in case when no image provided
  if (!activeCase.presentationImage) handleImageLoaded()

  useScrollLock(isFetch)

  return (
    <div className="case-preview">
      {isFetch && <Loader />}
      <div className="case-preview-left">
        <Link to="/">
          <Icon icon="chevron-left" />
          Back
        </Link>
      </div>
      <div className="case-preview-ilustration">
        {activeCase.presentationImage && (
          <img
            src={
              process.env.REACT_APP_SERVER_URL +
              activeCase.presentationImage.url
            }
            alt="back"
            onLoad={handleImageLoaded}
          />
        )}
      </div>
      <div className="case-preview-content">
        <div className="case-preview-content__subtitle">
          Familiarity with
          <div className="case-preview-content__type">
            {activeCase.type === 'video' ? 'Video' : 'Click through'}
          </div>
        </div>
        <div className="case-preview-content__title">{activeCase.name}</div>
        <div
          className="case-preview-content__text"
          dangerouslySetInnerHTML={{
            __html: activeCase.description || 'No description',
          }}
        ></div>
        {activeCase.userStory && (
          <DropdownList
            title="User story"
            open={() => open('d1')}
            isOpen={openDropdown === 'd1'}
          >
            <div
              dangerouslySetInnerHTML={{ __html: activeCase.userStory }}
            ></div>
          </DropdownList>
        )}

        {activeCase.worths && (
          <DropdownList
            title="Values"
            open={() => open('d2')}
            isOpen={openDropdown === 'd2'}
          >
            <div dangerouslySetInnerHTML={{ __html: activeCase.worths }}></div>
          </DropdownList>
        )}

        <div className="case-preview-bottom">
          <HelpWidget
            show={showHelp && activeCase.type !== 'video'}
            toggleShow={toggleShowHelp}
          />
          <div className="case-preview-bottom-controls">
            {activeCase.type !== 'video' && (
              <button
                className={`btn btn-icon ${showHelp ? 'btn-icon--active' : ''}`}
                onClick={toggleShowHelp}
              >
                <Icon icon="bulb" />
              </button>
            )}
            <button className="btn-primary" onClick={next}>
              Open
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewCard

const HelpWidget = ({ show, toggleShow }) => {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="case-preview-help-widget"
          initial={{ opacity: 0, y: 70 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 70 }}
          transition={{ type: 'ease-in-out', duration: 0.3 }}
        >
          <div className="case-preview-help-widget-top">
            <p className="case-preview-help-widget-top__title">
              Elements for navigating in this demo case
            </p>
            <div
              className="case-preview-help-widget-top__close"
              onClick={toggleShow}
            >
              <Icon icon="close" />
            </div>
          </div>

          <div className="case-preview-help-widget-content">
            <div className="link-activator" />
            <p>— use highlighted button to click through</p>

            <div className="comment-activator">
              <Icon icon="info" size="1.1rem" />
            </div>
            <p>— check available information</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const Loader = () => {
  return (
    <div className="skeleton-wrapper case-preview">
      <div className="case-preview-left">
        <Skeleton
          width="1.5rem"
          height="1.5rem"
          radius="50%"
          top="2rem"
          left="4.5rem"
        />
      </div>
      <div className="case-preview-ilustration">
        <Skeleton width="100%" height="100%" />
      </div>
      <div className="case-preview-content">
        <Skeleton width="30%" height="1rem" radius="4rem" bottom="1.5rem" />
        <Skeleton width="75%" height="2rem" radius="4rem" bottom="4rem" />

        <Skeleton height="1rem" radius="4rem" bottom=".5rem" />
        <Skeleton width="60%" height="1rem" radius="4rem" bottom="2rem" />

        <Skeleton height="1rem" radius="4rem" bottom=".5rem" />
        <Skeleton height="1rem" radius="4rem" bottom=".5rem" />
        <Skeleton width="75%" height="1rem" radius="4rem" bottom=".5rem" />
        <Skeleton width="35%" height="1rem" radius="4rem" bottom="5rem" />

        <div className="dropdown-list">
          <div className="dropdown-list-top">
            <Skeleton width="10rem" height="1rem" radius="4rem" left="2rem" />

            <Skeleton width="1.5rem" height="1.5rem" radius="50%" left="auto" />
          </div>
        </div>
        <div className="dropdown-list">
          <div className="dropdown-list-top">
            <Skeleton width="6rem" height="1rem" radius="4rem" left="2rem" />

            <Skeleton width="1.5rem" height="1.5rem" radius="50%" left="auto" />
          </div>
        </div>

        <Skeleton
          width="8rem"
          height="3rem"
          radius="4rem"
          left="auto"
          top="auto"
        />
      </div>
    </div>
  )
}

const DropdownList = ({ title, children, open, isOpen }) => {
  return (
    <div className="dropdown-list">
      <div className="dropdown-list-top">
        {title}{' '}
        <div className="dropdown-list__activator" onClick={open}>
          <Icon icon={isOpen ? 'minus' : 'plus'} />
        </div>
      </div>
        <motion.div
          className="dropdown-list-content"
          initial={false}
          animate={
            isOpen ? {
              height: 'auto',
              marginBottom: '1rem',
            } : {
              height: 0,
              marginBottom: 0,
            }
          }
        >
          {children}
        </motion.div>
    </div>
  )
}
