module.exports = {
  loading: 'LOADING',
  back: 'Back',

  // Navigation
  'nav-constructor': 'Constructor',
  'nav-cases': 'Demo Cases',
  'nav-analytic': 'Analytics',
  'nav-users': 'Users',
  'nav-user': 'Details',
  'nav-settings': 'Settings',
  'nav-exit': 'Log out',
  'nav-support': 'Support',

  // Form
  'form-save': 'Save',
  'btn-search': 'Search',
  'btn-create': 'Create',
  'btn-edit': 'Edit',
  'btn-remove': 'Remove',
  'btn-cancel': 'Cancel',

  // Errors
  'answer-required': 'The field is required',
  'no-stages-error': 'Use case has no stages',
  'no-steps-error': 'Use case has no steps',
  'step-circular-link': 'Circular link',
  'step-no-image': 'Image required',
  email_not_unique: 'This email already in use',

  // Home Page
  'home-top-title-1': 'Welcome to your ',
  'home-top-title-2': 'workspace',
  'home-top-text-1': 'available demos',
  'home-top-text-2': 'views',
  'home-top-action': 'Catalog',
  'home-cases-title': 'Demo cases',
  'home-my-cases-title': 'My Cases',
  'home-my-cases': 'My demo cases',
  'home-rent-cases-title': 'Demo Cases',
  'home-rent-cases': 'Available for rent',

  // Settings Page
  'settings-title': 'SETTINGS',
  'settings-edit-title': 'EDIT',

  // Users Page
  'users-title': 'Users',
  'users-count': "all user's count:",
  'users-last-activity': 'last activity',
  'user-edit-title': 'EDIT',
  'user-create-title': 'CREATE',
  'user-read-title': 'DETAILS',
  'user-detail-btn': 'Details',
  'user-edit-btn': 'Update',
  'user-remove-btn': 'Remove',
  'btn-create-user': 'Create user',

  // Authorization pages
  'auth-sign-in-title': 'Sign in to your account',
  'auth-sign-in': 'Sign In',
  'auth-forgot-password': 'Forgot Password?',

  'auth-sign-up-title': 'Account registration',
  'auth-sign-up': 'Sign Up',

  'auth-forget-password-title': 'Password recovery',
  'auth-forget-password': 'Reset',
  'auth-forget-password-success-msg': 'Recovery message sent to email',

  'auth-reset-password-title': 'Reset password',
  'auth-reset-password': 'Reset',

  // Constructor page
  'c-edit-case-btn': 'Edit Case',
  'c-remove-case-btn': 'Remove Case',
  'edit-use-case-title': 'Update Case',
  'create-use-case-title': 'Create New Case',

  'c-form-update-btn': 'Save',
  'c-form-cancel-btn': 'Cancel',
  'c-form-create-btn': 'Create',

  'c-edit-stage-btn': 'Edit Stage',
  'c-remove-stage-btn': 'Remove Stage',
  'c-edit-stage-title': 'Update Stage',
  'c-create-stage-title': 'Create Stage',

  'c-edit-step-btn': 'Edit Step',
  'c-remove-step-btn': 'Remove Step',
  'c-edit-step-title': 'Update Step',
  'c-create-step-title': 'Create Step',

  'c-stage-create-error': 'Stage Create Error',
  'c-stage-edit-error': 'Stage Edit Error',
  'c-step-create-error': 'Step Create Error',
  'c-step-edit-error': 'Step Edit Error',
  'c-step-reorder-error': 'Step Reorder Error',

  'c-comment-delete-error': 'Comment Remove Error',
  'c-comments-order-error': 'Comments Order Error',

  'invalid-email': 'Wrong e-mail format',
}
