import { Link } from 'react-router-dom'
import { useState } from 'react'
import NavLink from './NavLink'
import { AuthStore } from '../../store'
import { observer } from 'mobx-react-lite'
import { t } from 'i18next'
import { useClickOutside } from '@mantine/hooks'
import { Icon } from '../common'

const DesktopHeader = observer(() => {
  const currentUser = AuthStore.currentUser
  const isAnalytic = AuthStore.permissions.analytics
  const isUsers = AuthStore.permissions.users
  const logOut = () => {
    AuthStore.logOut()
  }

  return (
    <header className="header" data-aos="fade-down">
      <Link to="/" className="header__logo">
        <img src="/assets/icons/logo.svg" alt="Ustork Lab" />
      </Link>

      {currentUser && (
        <nav className="header-nav">
          <NavLink url="/cases" text={t('nav-cases')} icon="cases" />
          {isAnalytic && (
            <NavLink
              url="/analytics"
              text={t('nav-analytic')}
              icon="analytic"
            />
          )}
          {isUsers && (
            <NavLink url="/users" text={t('nav-users')} icon="users" />
          )}
          <NavDropDown username={getUsername(currentUser)} logOut={logOut} />
        </nav>
      )}
    </header>
  )
})

export default DesktopHeader

const NavDropDown = ({ username, logOut }) => {
  const [open, setOpen] = useState(false)
  const ref = useClickOutside(() => setOpen(false))
  return (
    <div className="header-nav-dropdown" ref={ref}>
      <div className="header-nav__link" onClick={() => setOpen(!open)}>
        {username}
        <Icon icon={open ? 'menu-up' : 'menu-down'} size="1.5rem" left="auto" />
      </div>
      {open && (
        <div
          className="header-nav-dropdown-content"
          data-aos="zoom-out"
          onClick={() => setOpen(!open)}
        >
          <NavLink
            url="/settings"
            text={t('nav-settings')}
            icon="settings"
            iconRight
          />
          <NavLink
            action={() => window.showCollectorDialog()}
            id="support"
            text={t('nav-support')}
            icon="support"
            iconRight
          />
          <NavLink action={logOut} text={t('nav-exit')} icon="exit" iconRight />
        </div>
      )}
    </div>
  )
}

const getUsername = (currentUser) => {
  let username = ''
  if (currentUser.firstName) {
    username = currentUser.firstName
    if (currentUser.lastName) username += ' ' + currentUser.lastName[0] + '.'
  } else {
    username = currentUser.username
  }
  return username
}
