import { t } from 'i18next'
import CasesBlock from '../../components/home/CasesBlock'
import Breadcrumbs from '../../components/layout/Breadcrumbs'

const CasesPage = () => {
  return (
    <div className="home-page">
      <Breadcrumbs path={[t('nav-cases')]} />
      <CasesBlock isCasesPage />
    </div>
  )
}

export default CasesPage
