import { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { getFullDate, getTime } from '../../utils'
import { Table, Icon, Responsive } from '../common'

const LinksTable = ({ data, analyticId, changePage, page, maxPage }) => {
  const [selectedLink, setSelectedLink] = useState(null)

  return (
    <Responsive breakpoints={['mobile', 'tablet', 'desktop']}>
      {(size) => (
        <Table
          className="links-table"
          data={data}
          changePage={changePage}
          page={page}
          maxPage={maxPage}
          columns={[
            {
              name: 'Link Name',
              key: 'name',
              render: (row) => row.name,
            },
            {
              name: 'Views',
              key: 'views',
              align: 'center',
              hidden: size !== 'desktop',
              render: (row) => row.views,
            },
            {
              name: 'Average Time',
              key: 'average-time',
              align: 'center',
              render: (row) => getTime(row.averageTime),
            },
            {
              name: 'Average Content Views',
              key: 'average-content-views',
              align: 'center',
              render: (row) => {
                const averageContentViews = Math.round(row.averageContentViews)
                return `${averageContentViews} / ${row.allContent}`
              }
            },
            {
              name: '',
              key: 'more',
              width: 'auto',
              render: (row) => (
                <ActivityBtn
                  id={row.id}
                  selectedLink={selectedLink}
                  setSelectedLink={setSelectedLink}
                />
              ),
            },
          ]}
          subRow={(row) => {
            if (row.id !== selectedLink) return null

            return (
              <div className="sessions-widget">
                <div className="sessions-widget-top">
                  {size === 'desktop' ? (
                    <span>
                      Last activity
                    </span>
                  ) : (
                    <Link
                      className="sessions-widget-top__see-more"
                      to={`/analytics/${analyticId}/${row.id}`}
                    >
                      See All activity
                      <Icon icon="chevron-right" />
                    </Link>
                  )}
                  <div
                    className="sessions-widget-top__close"
                    onClick={() => setSelectedLink(null)}
                  >
                    <Icon icon="close-white" />
                  </div>
                </div>
                {row.sessions.length > 0 ? (
                  <div className="sessions-widget-list">
                    {row.sessions.slice(0, size === 'desktop' ? 3 : 2)
                                 .map((session, i) => (
                    <div
                      key={i}
                      className="sessions-widget-list-item"
                    >
                      <div className="sessions-widget-list-item-identity">
                        {session.country ? (
                          session.city ? (
                            <span>{session.country}, {session.city}</span>
                          ) : (
                            <span>{session.country}</span>
                          )
                        ) : (
                          <span className="c-grey">Undefined</span>
                        )}

                        {session.lastActivity ? (
                          <span
                            className="sessions-widget-list-item-identity-time"
                          >
                            {getFullDate(session.lastActivity)}
                          </span>
                        ) : (
                          <span className="c-grey">Undefined</span>
                        )}
                      </div>
                        <span>
                          {session.contentViewCounter} / {row.allContent}
                        </span>
                        <span>{getTime(session.totalTime)}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="sessions-widget-list">
                    <div className="sessions-widget-list-item">
                      <span>No activity</span>
                    </div>
                  </div>
                )}
                {size === 'desktop' && (
                  <div className="sessions-widget-bottom">
                    <Link
                      className="sessions-widget-bottom__see-more"
                      to={`/analytics/${analyticId}/${row.id}`}
                    >
                      See All activity
                      <Icon icon="chevron-right" />
                    </Link>
                  </div>
                )}
              </div>
            )
          }}
        />
      )}
    </Responsive>
  )
}

export default LinksTable

const ActivityBtn = ({ id, selectedLink, setSelectedLink, }) => {
  const open = selectedLink === id
  const toggle = () => setSelectedLink(open ? null : id)

  return (
    <motion.div
      onClick={toggle}
      animate={{ rotate: open ? '90deg' : '-90deg' }}
      transition={{ type: 'spring', duration: 0.5 }}
    >
      <Icon icon="chevron-left" size="1.5rem" />
    </motion.div>
  )
}
