import { useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import { ToolPositioner } from '../common'
import ToolBar from './ToolBar'

const CustomCrop = ({ children, area, onSubmit, onClose }) => {
  const limiterRef = useRef(null)
  const cropRef = useRef(null)
  const [crop, setCrop] = useState({
    x: area?.x || 0,
    y: area?.y || 0,
    width: area?.width,
    height: area?.height,
    unit: '%',
  })
  const isArea = crop.x >= 0 && crop.y >= 0 && crop.width > 0 && crop.height > 0

  const [showSubmitBtns, setShowSubmitBtns] = useState(false)
  return (
    <div ref={limiterRef} style={{ position: 'relative' }}>
      {(isArea && showSubmitBtns) && (
        <div
          ref={cropRef}
          className="crop-area"
          style={{
            top: crop.y + '%',
            bottom: (100 - (crop.y + crop.height)) + '%',
            left: crop.x + '%',
            right: (100 - (crop.x + crop.width)) + '%',
          }}
        >
          <ToolPositioner limiterRef={limiterRef} anchorRef={cropRef}>
            <ToolBar
              items={[
                {
                  icon: 'close',
                  onClick: onClose,
                  label: 'Cancel',
                },
                {
                  icon: 'check',
                  onClick: () => onSubmit(crop),
                  label: 'Confirm',
                },
              ]}
            />
          </ToolPositioner>
        </div>
      )}
      <ReactCrop
        crop={crop}
        onChange={(_, crop) => setCrop(crop)}
        onDragStart={() => setShowSubmitBtns(false)}
        onDragEnd={() => setShowSubmitBtns(true)}
      >
        {children}
      </ReactCrop>
    </div>
  )
}

export default CustomCrop
