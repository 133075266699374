import { useState, useMemo } from 'react'
import { Icon } from '../../common'

const LinkForm = ({
  isNew,
  link,
  submit,
  close,
  stages,
  lastAddedStage,
  lastAddedStep,
  onNewStage,
  onNewStep,
}) => {
  const [state, setState] = useState(() => {
    let stage = lastAddedStage || ''
    let step = lastAddedStep || ''

    if (link) {
      if (!stage && link.toStage) stage = link.toStage
      if (!step && link.toStep) step = link.toStep
    }

    return {
      toStage: stage,
      toStep: step,
    }
  })

  const steps = useMemo(
    () =>
      state.toStage
        ? stages.find((stage) => stage.id === state.toStage).steps
        : [],
    [stages, state]
  )

  return (
    <div className="comment-card link-card">
      <div className="comment-card__top">
        <div className="comment-card__title">Add link</div>

        <button className="btn btn-icon" onClick={close} title="close">
          <Icon icon="close" />
        </button>
      </div>

      <div className="t-md">
        Select the path to which the link will be redirected
      </div>

      <div className="link-select-wrapper">
        <LinkSelectList
          value={state.toStage}
          onChange={(value) => {
            setState({
              toStage: value,
              toStep: '',
            })
          }}
          options={stages}
          isIcon
          onAddNew={onNewStage}
          addNewText="Add stage"
        />
        <LinkSelectList
          value={state.toStep}
          onChange={(value) => {
            setState({
              ...state,
              toStep: value === state.toStep ? '' : value,
            })
          }}
          options={steps}
          noValueLabel="Select stage"
          disabledValues={[link.step]}
          hideAddIcon
          onAddNew={() => {
            let stage = stages.find((stage) => stage.id === state.toStage)
            onNewStep(stage)
          }}
          addNewText="Add step"
        />
      </div>

      <div className="comment-card-bottom">
        <button
          className="btn-primary"
          onClick={() => submit(state)}
          title="Save"
          disabled={!state.toStage || !state.toStep}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default LinkForm

const LinkSelectList = ({
  value,
  onChange,
  options,
  noValueLabel,
  isIcon,
  disabledValues = [],
  onAddNew,
  addNewText,
  hideAddIcon,
}) => {
  if (!options) return <div className="t-md">{noValueLabel}</div>

  return (
    <div className="link-select">
      {onAddNew && (
        <div className="link-select__item add" onClick={onAddNew}>
          {!hideAddIcon && <Icon icon="plus" size="1rem" right="0.5rem" />}
          {addNewText || 'Add'}
        </div>
      )}

      {options.map((item, i) => (
        <div
          key={item.id}
          className={`link-select__item ${value === item.id ? 'active' : ''} ${
            disabledValues.includes(item.id) ? 'disabled' : ''
          }`}
          onClick={() => {
            if (!disabledValues.includes(item.id)) {
              onChange(item.id)
            }
          }}
        >
          <span>{item.name}</span>
          {isIcon && <Icon icon="menu-down" size="1.5rem" left="auto" />}
        </div>
      ))}
    </div>
  )
}
