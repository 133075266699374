import ListStore from '../List'
import { USERS_API } from '../../api'

export default new ListStore({
  fetchData: USERS_API.getUsers,
  fetchDataCount: USERS_API.getUsersCount,
  limit: 10,

  onLoadCallback: (res) => res,

  onErrorCallback: (error) => {
    console.log(error.message)
  },
})
