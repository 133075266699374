import Form from '../../components/common/form/Form'
import { observer } from 'mobx-react-lite'
import { AuthStore } from '../../store/index'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'

const ResetPassword = observer(() => {
  const { code } = useParams()
  const formConfig = {
    fields: [
      {
        name: 'Password',
        label: 'Password',
        type: 'password',
        placeholder: 'Create password',
        message: 'Passwords must ba at least 6  characters long',
        required: true,
      },
      {
        name: 'RepeatPassword',
        label: 'Repeat password',
        type: 'password',
        placeholder: 'Repeat password',
        message: 'Passwords must ba at least 6  characters long',
        required: true,
      },
    ],
    validate: (data, accept, reject) => {
      if (data.password !== data.repeatpassword) {
        reject({ repeatpassword: 'Пароли не совпадают' })
      } else {
        accept()
      }
    },
    submit: (data, reject) => {
      AuthStore.resetPassword(data, code)
        .then(() => {})
        .catch((err) => {
          reject({ email: 'Ошибка' })
        })
    },
    submitSlot: (
      <div className="submit-row">
        <span className="required-message">Required fields</span>
        <button type="submit" className="btn-primary">
          {t('auth-forget-password')}
        </button>
      </div>
    ),
    requiredMessage: 'Field required',
  }

  return (
    <div className="auth-page">
      <div className="t-title c-primary" data-aos="fade-down">
        {t('auth-forget-password-title')}
      </div>
      <div data-aos="fade-right">
        <Form config={formConfig} />
      </div>
      <img
        className="page-back-image"
        src="/assets/images/work-table.png"
        alt="table"
        data-aos="fade-left"
      />
    </div>
  )
})

export default ResetPassword
