import { Link } from 'react-router-dom'
import { useState } from 'react'
import { CasePreviewStore as store } from '../../store'
import { observer } from 'mobx-react-lite'
import { Icon } from '../common'
import { useClickOutside } from '@mantine/hooks'

const Header = observer(({ showDescription, setShowDescription }) => {
  const [showDescriptionTip, setShowDescriptionTip] = useState(false)
  const [showInfoTip, setShowInfoTip] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const activeCase = store.activeCase
  const step = store.step
  const isNext = step.links && step.links.length > 0

  const next = (stageId) => {
    store.next(stageId)
    setShowInfo(false)
  }

  const finish = () => {
    store.finish()
    setShowInfo(false)
  }

  const hideInfoModals = () => {
    // setShowDescription(false)
    setShowInfo(false)
  }

  const toggleDescription = () => {
    setShowInfo(false)
    setShowDescription(!showDescription)
  }

  const toggleInfo = () => {
    setShowDescription(false)
    setShowInfo(!showInfo)
  }

  const headerRef = useClickOutside(hideInfoModals)

  return (
    <header className="header case-header" data-aos="fade-down">
      <div className="header__logo">{activeCase?.name}</div>

      {activeCase && (
        <div className="header-nav" ref={headerRef}>
          {showInfo && (
            <div className="header-nav-dropdown-content" data-aos="zoom-out">
              <span className="header-nav-dropdown__title">info</span>
              {activeCase.stages.map((stage, index) => (
                <div className="info-link" key={stage.id}>
                  <div className="info-link__content">
                    <div className="info-link-text">{stage.name}</div>
                    <div className="info-link-subtext">
                      {stage?.description || '...'}
                    </div>
                  </div>
                  <div className="info-link__step">
                    <div className="info-link-text">{stage.steps.length}</div>
                    <div className="info-link-subtext">
                      {stage.steps.length > 1 ? 'Steps' : 'Step'}
                    </div>
                  </div>
                  <div
                    className="info-link__icon"
                    onClick={() => next(stage.id)}
                  >
                    <Icon icon="arrowRight" size="1.5rem" />
                  </div>
                </div>
              ))}
            </div>
          )}
          {step.description && (
            <div
              onMouseEnter={() => setShowDescriptionTip(true)}
              onMouseLeave={() => setShowDescriptionTip(false)}
            >
              <NavLink
                icon="step-description"
                action={toggleDescription}
              >
                {showDescriptionTip && (
                  <div
                    className="header-nav-tip"
                    data-aos="zoom-out"
                  >
                    Show description
                  </div>
                )}
              </NavLink>
            </div>
          )}
          <div
            onMouseEnter={() => setShowInfoTip(true)}
            onMouseLeave={() => setShowInfoTip(false)}
          >
            <NavLink action={toggleInfo} icon="info">
              {showInfoTip && (
                <div
                  className="header-nav-tip"
                  data-aos="zoom-out"
                >
                  Show info
                </div>
              )}
            </NavLink>
          </div>
          <NavLink className="header-nav__finish-btn" action={finish}>
            Finish
          </NavLink>
        </div>
      )}
    </header>
  )
})

export default Header

const NavLink = ({ className = '', children, icon, url, action}) => {
  if (url) {
    return (
      <Link
        to={url}
        className={`header-nav__link ${icon ? 'icon' : ''} ${className}`}
        onClick={action || (() => {
        })}
      >
        {icon && <Icon icon={icon} size="1.5rem" />}
        {children}
      </Link>
    )
  }

  return (
    <div
      className={`header-nav__link ${icon ? 'icon' : ''} ${className}`}
      onClick={action || (() => {})}
    >
      {icon && <Icon icon={icon} size="1.5rem" />}
      {children}
    </div>
  )
}
