import { AnimatePresence, motion } from 'framer-motion'

const Lightbox = ({ active }) => {
  return (
    <AnimatePresence>
      {(active) && (
        <motion.div
          className="lightbox"
          initial={{ opacity: 0, scale: 1.1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1.1 }}
        />
      )}
    </AnimatePresence>
  )
}

export default Lightbox
