import { Link } from 'react-router-dom'

const MobileOhNo = () => {
  return (
    <div
      className="mobile-oh-no"
      data-aos="zoom-out"
    >
      <span
        className="mobile-oh-no__title"
        data-aos="fade-down"
      >
        Uh, oh!
      </span>

      <p
        className="mobile-oh-no__message"
        data-aos="zoom-in"
        data-aos-delay="150"
      >
        This app is not compatible with your device!
      </p>

      <Link
        to="/"
        className="mobile-oh-no__link"
        data-aos="zoom-in"
        data-aos-delay="150"
        data-aos-offset={-1000}
      >
        Back Home
      </Link>
    </div>
  )
}

export default MobileOhNo
