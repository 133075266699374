import Loader from '../Loader'

const UploaderStatus = ({ children }) => {
  return (
    <div className="file-uploader form status">
      <Loader show inline text={children} />
    </div>
  )
}

export default UploaderStatus
