import { useState, useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { getFullDate } from '../../../utils'
import { Icon, EditableField, ToolPositioner } from '../../common'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Reorder } from 'framer-motion'
import { t } from 'i18next'
import { observer } from 'mobx-react-lite'
import { debounce } from 'lodash'
import { CaseConstructorStore as store } from '../../../store'
import { useConfirmDialog } from '../../../utils/hooks'
import { CommentsInfoCard, LinksInfoCard } from './InfoCards'

const commitReorderSteps = debounce(async (items) => {
  await store.reorderSteps(items)
}, 1000)

const StepsList = observer(({ steps }) => {
  const [items, setItems] = useState(steps)
  const [draggingDisabled, setDraggingDisabled] = useState(false)

  const addStep = () => store.createStep()
  const openStep = (i) => store.changeNavigation('step', i)
  const editStep = (name, stepId) => store.saveStep({ name }, stepId)

  const reorderSteps = (items) => {
    setItems(items)
    commitReorderSteps(items)
  }

  useEffect(() => {
    setItems(steps)
  }, [steps])

  const { modal: deleteModal, action: removeStep } = useConfirmDialog(
    (stepId) => store.removeStep(stepId),
    {
      title: 'Delete',
      text: (
        <>
          <div className="t-lg c-primary">
            Do you really want to delete this step?
          </div>
          <div className="t-md c-grey">
            It will be impossible to restore it.
          </div>
        </>
      ),
      confirmText: 'Delete',
    },
    []
  )
  const duplicateStep = (order) => store.createStep(undefined, false, order)
  return (
    <>
      <div className="steps-container">
        <div className="order-list">
          {steps.map((step, i) => (
            <div key={step.id} className="step-order">
              <span>{i + 1}</span>
            </div>
          ))}
        </div>
        <Reorder.Group
          as="ol"
          className="steps-list"
          axis="y"
          values={items}
          onReorder={reorderSteps}
        >
          {items && items.map((step, i) => (
            <StepCard
              key={step.id}
              step={step}
              onOpen={() => openStep(i)}
              onEdit={(name) => editStep(name, step.id)}
              onRemove={() => removeStep(step.id)}
              onDuplicate={() => duplicateStep(i + 2)}
              draggingDisabled={draggingDisabled}
              setDraggingDisabled={setDraggingDisabled}
            />
          ))}
        </Reorder.Group>
      </div>
      <div className="new-step__btn" onClick={() => addStep()}>
        <Icon icon="plus" />
        <div className="t-lg">Add Step</div>
      </div>
      {deleteModal}
    </>
  )
})

export default StepsList

const StepCard = ({
  step,
  onOpen,
  onEdit,
  onRemove,
  onDuplicate,
  draggingDisabled,
  setDraggingDisabled,
}) => {
  const [isDragging, setDragging] = useState(false)

  return (
    <Reorder.Item
      className="step-item"
      key={step.id}
      value={step}
      onDragStart={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
      dragListener={!draggingDisabled}
    >
      <div
        className={`step-card ${isDragging ? 'step-card_dragging' : ''}`}
        onClick={onOpen}
      >
        {step.image ? (
          <LazyLoadImage
            src={process.env.REACT_APP_SERVER_URL + step.image.url}
            alt={step.name}
            className="step-card__image"
            placeholderSrc="/assets/icons/loading.svg"
          />
        ) : (
           <div className="step-card__image">
             <Icon icon="file" size="2.5rem" />
             <div>No File</div>
           </div>
         )}
        <div
          className="step-card-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="step-card-content-top">
            <EditableField
              field={step.name}
              onChange={onEdit}
              onStartEditing={() => setDraggingDisabled(true)}
              onEndEditing={() => setDraggingDisabled(false)}
              maxLength={30}
            />
          </div>
          <div className="step-card-content-bottom">
            <InfoCardBtn
              CardComponent={CommentsInfoCard}
              content={step.comments}
              icon="comment"
            />
            <InfoCardBtn
              CardComponent={LinksInfoCard}
              content={step.links}
              icon="link"
            />
            <div className="t-md c-blue">
              create: {getFullDate(step.published_at)}
            </div>
          </div>
        </div>
      </div>
      <div className="step-actions">
        <button
          className="btn btn-icon"
          onClick={onRemove}
          title={t('c-remove-step-btn')}
        >
          <Icon icon="close" size="1.5rem" />
        </button>
        <button className="btn btn-icon" onClick={onDuplicate}>
          <Icon icon="duplicate" size="1.5rem" />
        </button>
      </div>
    </Reorder.Item>
  )
}

const InfoCardBtn = ({ CardComponent, content, icon }) => {
  const btnRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const count = Array.isArray(content) ? content.length : 0

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={btnRef}
        className={`step-card-content__btn ${count === 0? 'no-content' : ''}`}
        onClick={() => setIsOpen(count > 0)}
      >
        <b>{count}</b>
        <Icon icon={icon} size="1.5rem" left="0.5rem" />
      </div>

      <ToolPositioner anchorRef={btnRef}>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 1.1 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 1.1 }}
            >
              <CardComponent
                content={content}
                onClose={() => setIsOpen(false)}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </ToolPositioner>
    </div>
  )
}
