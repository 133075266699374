import { Fragment } from 'react'
import { Icon, Pagination } from '../../components/common'
// import ContentLoader from 'react-content-loader'

const List = ({
  renderItem,
  data,
  isFetch,
  changePage,
  page,
  maxPage,
  limit,
  noDataIcon = 'folder-art',
  noDataText = 'There is no data yet',
}) => {
  return (
    <div className="list-wrapper">
      <div className="list">
        {data && data.length > 0 ? (
          data.map((item, index) => (
            <Fragment key={index}>{renderItem(item)}</Fragment>
          ))
        ) : (
           <div className="list__no-item">
             <Icon icon={noDataIcon} />
             {noDataText}
           </div>
         )}
      </div>

      <Pagination changePage={changePage} page={page} maxPage={maxPage} />
    </div>
  )
}

export default List

// const Loader = () => {
//   return (
//     <ContentLoader
//       speed={2}
//       width="100%"
//       height="8rem"
//       viewBox="0 0 100% 8rem"
//       backgroundColor="#f3f3f3"
//       foregroundColor="#ecebeb"
//     >
//       <rect x="3%" y="3rem" rx="3" ry="3" width="20%" height="2rem" />
//       <rect x="25%" y="3rem" rx="3" ry="3" width="20%" height="2rem" />
//       <rect x="47%" y="3rem" rx="3" ry="3" width="20%" height="2rem" />
//       <rect x="69%" y="3rem" rx="3" ry="3" width="20%" height="2rem" />
//       <rect x="91%" y="3rem" rx="3" ry="3" width="2rem" height="2rem" />
//     </ContentLoader>
//   )
// }
