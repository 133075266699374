import { useState, useEffect, useRef, useMemo } from 'react'
import { motion } from 'framer-motion'
import { useScrollLock } from '@mantine/hooks'
import useMediaRecorder from '@wmik/use-media-recorder'
import { useTimer } from 'react-timer-hook'
import mimeDB from 'mime-db'
import { secondsToTimeStr } from '../../utils'

const VideoRecorder = ({
  children,
  onFinished,
  onCancel,
  countdown = 10,
  limit = 600,
}) => {
  const [showCountdown, setShowCountdown] = useState(false)
  const [recording, setRecording] = useState(false)
  const videoRef = useRef(null)

  const {
    status,
    error,
    getMediaStream,
    clearMediaStream,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    liveStream,
  } = useMediaRecorder({
    recordScreen: true,
    mediaStreamConstraints: {
      audio: true,
    },
    onStop: (blob) => {
      const videoMimeType = blob.type.split(';')[0]
      const fileExt = mimeDB[videoMimeType].extensions[0]
      const file = new File(
        [blob],
        `screen-record.${fileExt}`,
        { type: blob.type },
      )

      setRecording(false)
      onFinished(file)
    },
  })

  useEffect(() => {
    if (error) {
      onCancel(error)
    }
  }, [error, onCancel])

  useEffect(() => {
    if (videoRef.current && liveStream) {
      videoRef.current.srcObject = liveStream
    }
  }, [liveStream])

  useScrollLock(true)

  const handleRequestPermissions = () => {
    getMediaStream().then((mediaStream) => {
      if (mediaStream) {
        setRecording(true)
        setShowCountdown(true)
      }
    })
  }

  const handleStart = () => {
    startRecording()
  }

  const handleCancel = () => {
    clearMediaStream()
    setRecording(false)
    onCancel()
  }

  return (
    <>
      <div onClick={handleRequestPermissions}>
        {children}
      </div>
      {recording && (
        <RecorderScreen
          countdown={countdown}
          limit={limit}
          status={status}
          showCountdown={showCountdown}
          videoRef={videoRef}
          liveStream={liveStream}
          onStart={handleStart}
          onCancel={handleCancel}
          onPauseRecording={pauseRecording}
          onResumeRecording={resumeRecording}
          onStopRecording={stopRecording}
        />
      )}
    </>
  )
}

export default VideoRecorder

const RecorderScreen = ({
  countdown,
  limit,
  status,
  showCountdown,
  videoRef,
  liveStream,
  onStart,
  onCancel,
  onPauseRecording,
  onResumeRecording,
  onStopRecording,
}) => {
  return (
    <div className="video-recorder">
      {(showCountdown || liveStream) && (
        <>
          <div className="video-recorder__background" />
          <video ref={videoRef} autoPlay muted />
        </>
      )}

      {showCountdown && (
        <StartCountdown
          onStart={onStart}
          onCancel={onCancel}
          countdown={countdown}
        />
      )}

      {(status === 'recording' || status === 'paused') && liveStream && (
        <VideoControls
          onPause={onPauseRecording}
          onPlay={onResumeRecording}
          onStop={onStopRecording}
          limit={limit}
        />
      )}
    </div>
  )
}

const StartCountdown = ({ onStart, onCancel, countdown }) => {
  const startRecordingTimestamp = useMemo(() => {
    const time = new Date()
    time.setSeconds(time.getSeconds() + countdown)
    return time
  }, [countdown])

  const {
    seconds: toStart,
    isRunning: isCountdownRunning,
  } = useTimer({
    expiryTimestamp: startRecordingTimestamp,
    autoStart: true,
    onExpire: onStart,
  })

  if (toStart <= 0 || !isCountdownRunning) {
    return null
  }

  return (
    <button
      className="btn btn-secondary video-recorder-cancel"
      onClick={() => onCancel()}
    >
      <span className="video-recorder-cancel__timer">{toStart}</span>
      <span className="video-recorder-cancel__text">Click a cancel</span>
    </button>
  )
}

const VideoControls = ({ onPause, onPlay, onStop, limit }) => {
  const endRecordingTimestamp = useMemo(() => {
    const time = new Date()
    time.setSeconds(time.getSeconds() + limit)
    return time
  }, [limit])

  const {
    minutes: toEndMinutes,
    seconds: toEndSeconds,
    pause: pauseTimer,
    resume: resumeTimer,
  } = useTimer({
    expiryTimestamp: endRecordingTimestamp,
    autoStart: true,
    onExpire: onStop,
  })

  const passed = limit - (toEndMinutes * 60 + toEndSeconds)

  const handlePause = () => {
    onPause()
    pauseTimer()
  }

  const handleResume = () => {
    onPlay()
    resumeTimer()
  }

  return (
    <div className="video-recorder-controls">
      <div className="video-recorder-controls-time">
        <PlayPause onPause={handlePause} onPlay={handleResume} />
        <Timer passed={passed} limit={limit} />
      </div>
      <button
        className="btn btn-secondary video-recorder-controls__finish"
        onClick={onStop}
      >
        Finish
      </button>
    </div>
  )
}

const PlayPause = ({ onPause, onPlay }) => {
  const [isPlaying, setIsPlaying] = useState(true)

  const handleToggle = () => {
    if (isPlaying) {
      onPause()
      setIsPlaying(false)
    } else {
      onPlay()
      setIsPlaying(true)
    }
  }

  return (
    <div
      className="video-recorder-controls-play-pause"
      onClick={handleToggle}
    >
      <motion.div
        className="video-recorder-controls-play-pause__icon"
        initial={false}
        animate={
          isPlaying ? {
            width: '1rem',
            height: '1rem',
            borderRadius: '0.1rem',
          } : {
            width: '100%',
            height: '100%',
            borderRadius: '1.25rem',
          }
        }
      />
    </div>
  )
}

const Timer = ({ passed, limit }) => {
  return (
    <span className="video-recorder-controls-time__timer">
      {secondsToTimeStr(passed)}{limit < Infinity ? ` / ${secondsToTimeStr(limit)}` : ''}
    </span>
  )
}
