import { useState, useEffect } from 'react'
import { Text } from '../../common'
import { Reorder } from 'framer-motion'
import { truncateString } from '../../../utils'

const CommentsOrderList = ({ comments, onReorder }) => {
  const [items, setItems] = useState(comments)

  const reorderComments = (items) => {
    setItems(items)
    onReorder?.(items)
  }

  useEffect(() => {
    if (items !== comments) {
      setItems(comments)
    }
  }, [items, comments])

  return (
    <Reorder.Group
      as="ol"
      className="comments-order-list"
      axis="y"
      values={items}
      onReorder={reorderComments}
      layoutScroll
    >
      {items.map((comment) => (
        <CommentItem key={comment.id} comment={comment} />
      ))}
    </Reorder.Group>
  )
}

const CommentItem = ({ comment }) => {
  const [isDragging, setDragging] = useState(false)

  return (
    <Reorder.Item
      className={`comments-order-list-item ${isDragging ? 'comments-order-list-item_dragging' : ''}`}
      key={comment.id}
      value={comment}
      onDragStart={() => setDragging(true)}
      onDragEnd={() => setDragging(false)}
    >
      <p className="comments-order-list-item__title">
        {truncateString(comment.title, 69)}
      </p>
      <div className="comments-order-list-item__description">
        <Text
          html={truncateString(comment.description, 69)}
        />
      </div>
      <div className="comments-order-list-item__underline" />
    </Reorder.Item>
  )
}

export default CommentsOrderList
