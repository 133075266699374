import { CaseConstructorStore as store } from '../../store'
import { observer } from 'mobx-react-lite'
import { Icon } from '../common'
import { Fragment } from 'react'

const CaseNavigation = observer(() => {
  if (!store.activeCase) {
    return <div className="constructor-content-left" data-aos="fade-right" />
  }

  if (store.activeCase.type === 'video') {
    return <VideoCaseNavigation />
  }

  const stages = store.activeCase.stages
  const activeStage =
    store.activeStage !== null ? Number(store.activeStage) : null
  const activeStep = store.activeStep !== null ? Number(store.activeStep) : null

  return (
    <div className="constructor-content-left" data-aos="fade-right">
      <div className="overflow-area">
        {stages.map((stage, i) => (
          <Fragment key={i}>
            <NavLink
              title={stage.name}
              onClick={() => store.changeNavigation('stage', i)}
              isActive={i === activeStage}
              type="stage"
              isIcon
            />
            {i === activeStage && (
              <>
                {stage.steps.map((step, j) => (
                  <Fragment key={i + ' ' + j}>
                    <NavLink
                      title={`${j + 1}. ${step.name}`}
                      onClick={() => store.changeNavigation('step', j)}
                      isActive={j === activeStep}
                      type="step"
                    />
                  </Fragment>
                ))}
                <button
                  className="nav-item add step"
                  onClick={() => store.createStep()}
                >
                  <Icon icon="plus" />
                </button>
              </>
            )}
          </Fragment>
        ))}
      </div>
      <button
        className="nav-item add stage"
        onClick={() => store.createStage()}
      >
        Add Stage
      </button>
    </div>
  )
})

export const VideoCaseNavigation = observer(() => {
  const videoStep = store.activeCase.stages[0].steps[0]

  return (
    <div className="constructor-content-left" data-aos="fade-right">
      <div className="overflow-area">
        <NavLink
          title={videoStep.name || 'Record or choose video'}
          onClick={() => {
            store.changeNavigation('stage', 0)
            store.changeNavigation('step', 0)
          }}
          isActive
          type="stage"
        />
      </div>
      <button
        className="nav-item add stage"
        onClick={() => store.changeNavigation('stage', null)}
      >
        Description
      </button>
    </div>
  )
})

const NavLink = ({ title, onClick, isActive, type, isIcon }) => (
  <button
    className={`nav-item ${type || ''} ${isActive ? 'active' : ''}`}
    onClick={onClick}
  >
    {isIcon && <Icon icon={isActive ? 'menu-down' : 'menu-up'} size="1.5rem" />}
    <span>{title}</span>
  </button>
)

export default CaseNavigation
