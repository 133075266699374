import { useState, useEffect, useCallback } from 'react'
import { useScrollLock } from '@mantine/hooks'
import { Spinner } from '../../common'
import UploaderStatus from '../forms/UploaderStatus'

const PreviewStep = ({ type, step, children, autoPlay = false }) => {
  const [showContent, setShowContent] = useState(false)

  return (
    <div className={`case-preview-step ${type}`}>
      {type === 'video' ? (
        <LazyLoadVideo
          key={step.id}
          id={step.id}
          src={
            !step.image?.converting && step.image?.url
              ? process.env.REACT_APP_SERVER_URL + step.image.url
              : null
          }
          onShowContentChange={setShowContent}
          autoPlay={autoPlay}
        />
      ) : (
        <LazyLoadImage
          key={step.id}
          id={step.id}
          src={
            step.image?.url
              ? process.env.REACT_APP_SERVER_URL + step.image.url
              : null
          }
          alt={step.name}
          onShowContentChange={setShowContent}
        />
      )}
      {showContent && children}
    </div>
  )
}

export default PreviewStep

const LazyLoadImage = ({ id, src, alt, onShowContentChange }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  const onImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  const showSpinner = !imageLoaded

  useEffect(() => {
    onShowContentChange?.(!showSpinner)

    if (showSpinner) {
      window.scrollTo({ top: 0, behavior: 'instant' })
    }
  }, [src, onShowContentChange, showSpinner])
  useScrollLock(showSpinner)

  return (
    <>
      <img
        id={id}
        src={src}
        alt={alt}
        onLoad={onImageLoaded}
      />
      {showSpinner && <Spinner />}
    </>
  )
}

const LazyLoadVideo = ({ id, src, onShowContentChange, autoPlay }) => {
  const [videoLoaded, setVideoLoaded] = useState(false)

  const onVideoLoaded = useCallback(() => {
    setVideoLoaded(true)
  }, [])

  const videoConverting = src === null
  const showSpinner = !videoLoaded && !videoConverting

  useEffect(() => {
    onShowContentChange?.(!showSpinner)

    if (showSpinner) {
      window.scrollTo({ top: 0, behavior: 'instant' })
    }
  }, [src, onShowContentChange, showSpinner])
  useScrollLock(showSpinner)

  return (
    <>
      {videoConverting ? (
        <UploaderStatus>
          Video is converting. It may take some time, please wait
        </UploaderStatus>
      ) : (
        <video
          id={id}
          controls
          src={src}
          onCanPlay={onVideoLoaded}
          autoPlay={autoPlay}
        />
      )}
      {showSpinner && <Spinner />}
    </>
  )
}
