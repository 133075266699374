import { useState } from 'react'
import { Form } from '../common'
import { Input } from '../common/form/Form'

const ShareForm = ({ generateLink, copyLink, close }) => {
  const [link, setLink] = useState('')
  const [linkCopied, setLinkCopied] = useState(false)

  const generationConfig = {
    autocomplete: 'off',
    fields: [
      {
        label: (
          <div className="share-form-label">
            <p className="share-form-label__description">
              Specify the name of the user to whom the demo will be sent, then generate a unique link for it.
            </p>
            <p className="share-form-label__name">
              Name
            </p>
          </div>
        ),
        name: 'name',
        type: 'text',
        required: true,
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: async (data, reject, onSuccess) => {
      try {
        const link = await generateLink(data)
        setLink(link)
        onSuccess()
      } catch (error) {
        reject({ global: `An error occurred while copying: ${error.message}` })
      }
    },
    submitSlot: (
      <button type="submit" className="btn-secondary">
        Generate Link
      </button>
    ),
    requiredMessage: 'Field required',
  }

  const copingConfig = {
    ...generationConfig,
    submit: async (data, reject, onSuccess) => {
      try {
        await copyLink(link)
        onSuccess()

        if (!linkCopied) {
          setLinkCopied(true)
        }
      } catch (error) {
        reject({ global: `An error occurred while copying: ${error.message}` })
      }
    },
    submitSlot: (
      <div className="share-form-copy">
        <Input value={link} />
        <button type="submit" className="btn-secondary">
          Copy
        </button>
      </div>
    ),
  }

  const copingConfigWithNotification = {
    ...copingConfig,
    submitSlot: (
      <div className="share-form-copy">
        <Input value={link} />
        <button type="submit" className="btn-secondary">
          Copy
        </button>
        <div className="share-form-copy__notification" data-aos="zoom-in">
          Link copied
        </div>
      </div>
    ),
  }

  let config = generationConfig

  if (linkCopied) {
    config = copingConfigWithNotification
  } else if (link) {
    config = copingConfig
  }

  return <Form config={config} />
}

export default ShareForm
