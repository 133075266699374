import { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { CasePreviewStore as store } from '../../store'
import { observer } from 'mobx-react-lite'

import PreviewCard from '../../components/cases/PreviewCard'
import Preview from '../../components/cases/Preview'
import FeedBackModal from '../../components/cases/FeedBackModal'

const CasePreview = observer(() => {
  const { id } = useParams()
  const { search } = useLocation()
  useEffect(() => {
    store.fetchUseCase(id, search)
  }, [id, search])

  const activeCase = store.activeCase
  const stage = store.activeStage
  const showFeedbackModal = store.isFinished && !store.isPreview
  const next = () => store.next()

  if (!activeCase) return null

  return (
    <div className="case-preview-page">
      {stage === null ? (
        <PreviewCard
          activeCase={activeCase}
          next={next}
          isFetch={store.isFetch}
          handleImageLoaded={() => store.handleImageLoaded()}
        />
      ) : (
        <Preview />
      )}

      {showFeedbackModal && (
        <FeedBackModal
          sendFeedback={(data) => store.sendFeedback(data)}
          sendCallRequest={(data) => store.sendCallRequest(data)}
          onClose={() => store.unfinish()}
        />
      )}
    </div>
  )
})

export default CasePreview
