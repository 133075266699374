import { Form, Icon } from '../../common'
import { useTranslation } from 'react-i18next'

const CommentForm = ({ isNew, comment, submit, close }) => {
  const { t } = useTranslation()
  const formConfig = {
    fields: [
      {
        name: 'Title',
        type: 'text',
        placeholder: 'Enter Title',
        required: true,
        label: 'Title',
        defaultValue: isNew ? '' : comment.title,
        maxLength: 30,
      },
      {
        name: 'Description',
        type: 'richtext',
        placeholder: 'Enter Text',
        required: false,
        label: 'Text',
        defaultValue: isNew ? '' : comment.description,
        maxLength: 400,
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject) => {
      submit(data).catch((err) => {
        reject({ global: t(err.message) })
      })
    },
    submitSlot: (
      <div className="comment-card-submit-wrapper-top-right">
        <button
          type="submit"
          className="btn btn-icon"
          title={t(isNew ? 'c-form-create-btn' : 'c-form-update-btn')}
        >
          <Icon icon="check" size="1.5rem" />
        </button>

        <button className="btn btn-icon" onClick={close} title="close">
          <Icon icon="close" size="1.5rem" />
        </button>
      </div>
    ),
    requiredMessage: 'Field required',
  }

  return (
    <div className="comment-card">
      <Form config={formConfig} />
    </div>
  )
}

export default CommentForm
