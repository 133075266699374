import { useState } from 'react'
import { Form, Icon } from '../common'

const FeedbackModal = ({ sendFeedback, sendCallRequest, onClose }) => {
  const [state, setState] = useState({
    isCallRequest: false,
    isCallRequestSent: false,
    show: true,
  })

  const close = () => {
    setState({ ...state, show: false })
    onClose?.()
  }

  const onCallRequestSubmit = (data, reject) => {
    sendCallRequest(data)
      .then(() => {
        setState({ ...state, isCallRequest: false, isCallRequestSent: true })
      })
      .catch((err) => {
        reject({ global: 'Error' })
      })
  }
  const onFeedbackSubmit = (data, reject) => {
    sendFeedback(data)
      .then(() => {
        close()
      })
      .catch((err) => {
        reject({ feedback: 'Error Occured' })
      })
  }
  if (!state.show) return null

  return (
    <div className="modal-wrapper" data-aos="zoom-out">
      <div className="modal case-feedback">
        <div className="modal__close" onClick={close}>
          <Icon icon="close" />
        </div>

        <div className="case-feedback__title">Feedback</div>
        {state.isCallRequest ? (
          <>
            <div className="case-feedback__title">
              To order a call, choose how you want to be contacted.
            </div>
            <CallRequestForm
              close={() => setState({ ...state, isCallRequest: false })}
              onSubmit={onCallRequestSubmit}
            />
          </>
        ) : (
          <FeedbackForm
            close={
              !state.isCallRequestSent
                ? () => setState({ ...state, isCallRequest: true })
                : null
            }
            onSubmit={onFeedbackSubmit}
          />
        )}
      </div>
    </div>
  )
}

export default FeedbackModal

const RateInput = ({ value, onChange }) => {
  return (
    <div className="rate-input">
      {new Array(5).fill(null).map((_, i) => (
        <div
          className={`rate-square ${i + 1 <= value ? 'active' : ''}`}
          onClick={() => onChange(i + 1)}
          key={i}
        >
          {i + 1}
        </div>
      ))}
      <Icon icon="star" size="1rem" right="0.5rem" left="0.5rem" />
      Stars
    </div>
  )
}

const FeedbackForm = ({ close, onSubmit }) => {
  const config = {
    fields: [
      {
        name: 'Rate',
        type: null,
        label: `Rate your experience with demo case`,
        customRender: ({ field, value, onChange }) => (
          <RateInput value={value} onChange={onChange} />
        ),
      },
      {
        name: 'feedback',
        type: 'textarea',
        placeholder: 'Your feedback (Optional)',
        label: 'General impressions of this demo case',
        maxLength: 500,
      },
    ],
    validate: (data, accept, reject) => {
      accept()
    },
    submit: (data, reject) => {
      onSubmit(data, reject)
    },
    submitSlot: (
      <>
        {close && (
          <button className="btn-white-text" onClick={close} type="button">
            Request a call
          </button>
        )}
        <button type="submit" className="btn-primary">
          Submit
        </button>
      </>
    ),
  }
  return <Form config={config} />
}
const CallRequestForm = ({ close, onSubmit }) => {
  const config = {
    fields: [
      {
        name: 'Email',
        type: 'email',
        label: 'E-mail',
      },
      {
        name: 'Phone',
        type: 'phone',
        label: 'Phone number',
        placeholder: '( _ _ _ ) _ _ _ - _ _ - _ _',
      },
    ],
    validate: (data, accept, reject) => {
      if (data.phone && (!data.phone.country || !data.phone.country.name)) {
        reject({ phone: 'Choose Country' })
        return
      }
      accept()
    },
    submit: (data, reject) => {
      onSubmit({ email: data.email, phone: data.phone.formated }, reject)
    },
    submitSlot: (
      <>
        <button className="btn-white-text" onClick={close} type="button">
          Back to feedback
        </button>
        <button type="submit" className="btn-primary">
          Submit
        </button>
      </>
    ),
  }
  return <Form config={config} />
}
