import { makeAutoObservable } from 'mobx'

const MESSAGE_TIMEOUT = 2000
const TYPES = {
  success: {
    defaultTitle: 'Successful result',
  },
  warning: {
    defaultTitle: 'Warning',
  },
  error: {
    defaultTitle: 'Error',
  },
  info: {
    defaultTitle: 'Information',
  },
}
/*
	Example
	{
		id: 1,
		type: 'success',
		message: {
			message: 'Success message',
			title: TYPES.success.defaultTitle,
		},
	},
	{
		id: 2,
		type: 'info',
		message: {
			message:
				'info message many text too many tecxt and one more text too many text',
			title: TYPES.info.defaultTitle,
		},
	},
	{
		id: 3,
		type: 'warning',
		message: {
			message: 'warning message',
			title: TYPES.warning.defaultTitle,
		},
	},
	{
		id: 4,
		type: 'error',
		message: {
			message: 'error message',
			title: TYPES.error.defaultTitle,
		},
	},
*/
class MessagesStore {
  messages = []

  constructor() {
    makeAutoObservable(this)
  }

  /**
   * Show new message that will be removed after some time
   *
   * @param {String} message - The message
   * @param {Strins} type - message type 'error, info, success'
   */
  addMessage(message, type) {
    let id = setTimeout(() => {
      this.messages = this.messages.filter((message) => message.id !== id)
    }, MESSAGE_TIMEOUT)
    this.messages = [
      ...this.messages,
      {
        message: {
          title: message.message?.title || TYPES[type].defaultTitle,
          message: message.message || message,
        },
        type: type || null,
        id,
      },
    ]
  }

  /**
   * Remove message from message list
   * @param {String} messageId - message id
   */
  removeMessage(messageId) {
    clearTimeout(messageId)
    this.messages = this.messages.filter((message) => message.id !== messageId)
  }

  addError(message) {
    this.addMessage(message, 'error')
  }

  addInfo(message) {
    this.addMessage(message, 'info')
  }

  addSuccess(message) {
    this.addMessage(message, 'success')
  }

  addWarning(message) {
    this.addMessage(message, 'warning')
  }
}

const store = new MessagesStore()

export default store
