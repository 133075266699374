import { useState } from 'react'
import { useClickOutside } from '@mantine/hooks'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Icon, Text } from '../../common'
import { CaseConstructorStore as store } from '../../../store'

export const CommentsInfoCard = ({ content, onClose }) => {
  const [selectedComment, setSelectedComment] = useState(null)
  const ref = useClickOutside(() => onClose())

  return (
    <div ref={ref} className="info-card">
      <div className="info-card-top">
        <span className="info-card-top__title">Comments</span>
        <div className="info-card-top__close" onClick={onClose}>
          <Icon icon="close" size="1.5rem" />
        </div>
      </div>
      <div className="info-card-list">
        {content.map((comment, index) => {
          const isOpen = selectedComment === comment.id
          const toggle = () => setSelectedComment(isOpen ? null : comment.id)

          return (
            <div key={comment.id} className="info-card-list__item">
              <div className="info-card-list-comment-top" onClick={toggle}>
                <p>{index + 1}. {comment.title}</p>
                <motion.div
                  animate={{ rotate: isOpen ? '90deg' : '-90deg' }}
                  transition={{ type: 'spring', duration: 0.5 }}
                >
                  <Icon icon="chevron-left" size="1rem" />
                </motion.div>
              </div>
              <motion.div
                className="info-card-list-comment__details"
                initial={false}
                animate={
                  selectedComment === comment.id ? {
                    height: 'auto',
                    marginTop: '0.5rem',
                  } : {
                    height: 0,
                    marginTop: 0,
                  }
                }
              >
                <Text html={comment.description} />
              </motion.div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const LinksInfoCard = observer(({ content, onClose }) => {
  const ref = useClickOutside(() => onClose())

  const activeCase = store.activeCase

  const getLinkInfo = (link) => {
    const stageIndex = activeCase.stages.findIndex(({ id }) => id === link.toStage)
    const stageInfo = activeCase.stages[stageIndex]

    const stepIndex = stageInfo.steps.findIndex(({ id }) => id === link.toStep)
    const stepInfo = stageInfo.steps[stepIndex]

    return { stageIndex, stepIndex, stageInfo, stepInfo }
  }

  const sortedContent = [...content].sort((a, b) => {
    return a.toStage > b.toStage
  })

  return (
    <div ref={ref} className="info-card">
      <div className="info-card-top">
        <span className="info-card-top__title">Transition to</span>
        <div className="info-card-top__close" onClick={onClose}>
          <Icon icon="close" size="1.5rem" />
        </div>
      </div>
      <div className="info-card-list">
        {sortedContent.map((link, index) => {
          const { stageIndex, stepIndex, stageInfo, stepInfo } = getLinkInfo(link)

          return (
            <Link
              key={link.id}
              className="info-card-list__item link"
              to={`/constructor/${activeCase.id}?stage=${stageIndex}&step=${stepIndex}`}
              target="_blank"
            >
              {index + 1}. {stageInfo.name} / {stepInfo.name}
            </Link>
          )
        })}
      </div>
    </div>
  )
})
