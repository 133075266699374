import NavLink from '../layout/NavLink'
import { CaseConstructorStore as store } from '../../store'
import { observer } from 'mobx-react-lite'

const Header = observer(() => {
  return (
    <header className="header" data-aos="fade-down">
      <div className="header__logo c-white">
        {store.activeCase?.name || 'Create new Case'}
      </div>
      <div className="header-nav">
        <NavLink url="/" text="Выйти из демо кейса" icon="exit" />
      </div>
    </header>
  )
})

export default Header
