import { makeObservable, observable, action } from 'mobx'

const breakpoints = ['mobile', 'tablet', 'desktop']

class ResponsiveStore {
  breakpoint = 'mobile'
  isInitial = true

  constructor() {
    makeObservable(this, {
      breakpoint: observable,
      isInitial: observable,
      setBreakpoint: action,
    })
  }

  setBreakpoint(breakpoint) {
    if (breakpoints.includes(breakpoint)) {
      this.breakpoint = breakpoint
      this.isInitial = false
    } else {
      throw new Error(`Invalid breakpoint: ${breakpoint}`)
    }
  }
}

const store = new ResponsiveStore()

export default store
