import { Link } from 'react-router-dom'
import { Responsive } from '../../components/common'

const NotFoundError = () => {
  return (
    <div
      className="error-code-page"
      data-aos="zoom-out"
    >
      <Responsive breakpoints={['desktop']}>
        <img
          className="error-code-page__art"
          src="/assets/images/errors/error-404-desktop.svg"
          alt="404"
        />
      </Responsive>

      <Responsive breakpoints={['mobile', 'tablet']}>
        <img
          className="error-code-page__art"
          src="/assets/images/errors/error-404-mobile.svg"
          alt="404"
        />
      </Responsive>

      <div className="error-code-page-content">
        <h2
          className="error-code-page-content__title"
          data-aos="fade-down"
        >
          Page Not Found
        </h2>
        <p
          className="error-code-page-content__message"
          data-aos="zoom-in"
          data-aos-delay="150"
        >
          Unfortunately the content you’re looking for isn’t here.
          There may be a misspelling in your web address or you may
          have clicked a link for content that no longer exists.
        </p>

        <Link
          to="/"
          className="error-code-page-content__link"
          data-aos="zoom-in"
          data-aos-delay="150"
          data-aos-offset={-1000}
        >
          Back Home
        </Link>
      </div>
    </div>
  )
}

export default NotFoundError
