import { useState } from 'react'
import Icon from './Icon'
import { MessagesStore } from '../../store'
import { useClickOutside } from '@mantine/hooks'

const EditableField = ({
  field,
  onChange,
  onStartEditing,
  onEndEditing,
  maxLength,
}) => {
  const [state, setState] = useState({
    readonly: true,
    value: field,
    error: false,
  })
  const onEditStart = () => {
    setState({ ...state, readonly: false })
    onStartEditing?.()
  }
  const onEditEnd = () => {
    setState({ ...state, readonly: true, value: field, error: false })
    onEndEditing?.()
  }
  const onFieldChange = (e) =>
    setState({ ...state, value: e.target.value, error: false })
  const onSubmit = () => {
    if (!state.value) {
      setState({ ...state, error: true })
      MessagesStore.addError('Field required')
      return
    }
    onChange(state.value)
    setState({ ...state, readonly: true, error: false })
  }

  const ref = useClickOutside(() => {
    if (!state.readonly) {
      onEditEnd()
    }
  })

  return (
    <div
      className={`editable-field-wrapper ${state.error ? 'error' : ''}`}
      ref={ref}
    >
      <div className="editable-field">
        <input
          type="text"
          value={state.value}
          onChange={onFieldChange}
          disabled={state.readonly}
          maxLength={maxLength}
        />
        {!state.readonly && maxLength && (
          <div className="form__maxlength">
            {state.value.length}/{maxLength}
          </div>
        )}
      </div>

      {state.readonly ? (
        <button className="editable-field__btn edit" onClick={onEditStart}>
          <Icon icon="edit" size="1.5rem" />
        </button>
      ) : (
        <>
          <button className="editable-field__btn" onClick={onEditEnd}>
            <Icon icon="close" size="1.5rem" />
          </button>
          <button className="editable-field__btn" onClick={onSubmit}>
            <Icon icon="check" size="1.5rem" />
          </button>
        </>
      )}
    </div>
  )
}

export default EditableField
