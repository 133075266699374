import { Fragment } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Icon, Pagination } from '../../common'

// columns - {name, key, render?}
const Table = ({
  columns,
  subRow,
  data,
  className,
  noDataIcon = 'folder-art',
  noDataText = 'There is no data yet',
  changePage,
  page,
  maxPage,
}) => {
  const visibleColumns = columns.filter((column) => !column.hidden)
  const columnsWidths = visibleColumns.map((column) => {
    const width = column.width ?? '1fr'
    const minWidth = column.minWidth ?? width
    const maxWidth = column.maxWidth ?? width

    return minWidth !== maxWidth ? `minmax(${minWidth}, ${maxWidth})` : width
  })

  const columnsGridTemplate = columnsWidths.join(' ')
  const fillRow =  `1 / ${visibleColumns.length + 1}`
  const columnsAlign = visibleColumns.map(({ align = 'left' }) => align)

  return (
    <div className="table-wrapper">
      <table
        className={`table ${className || ''}`}
        style={{ gridTemplateColumns: columnsGridTemplate }}
      >
        <thead>
          <tr>
            {visibleColumns.map((column, i) => (
              <th key={column.key} className={`cell-align-${columnsAlign[i]}`}>
                <span className="title">{column.name}</span>
                {column.filter && <div className="filter">{column.filter}</div>}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, i) => {
              let subRowContent = subRow

              if (typeof subRow === 'function') {
                subRowContent = subRow(row)
              }

              return (
                <Fragment key={i}>
                  <tr>
                    {visibleColumns.map((column, i) => (
                      <td
                        key={column.key + i}
                        className={`cell-align-${columnsAlign[i]}`}
                      >
                        {column.render ? column.render(row) : row[column.key]}
                      </td>
                    ))}
                  </tr>
                  <tr className="sub-row">
                    <AnimatePresence>
                      {subRowContent != null && (
                        <motion.td
                          style={{ gridColumn: fillRow }}
                          initial={{ height: 0 }}
                          animate={{ height: 'auto' }}
                          exit={{ height: 0 }}
                          transition={{ duration: 0.3, easing: 'ease-in-out' }}
                        >
                          {subRowContent}
                        </motion.td>
                      )}
                    </AnimatePresence>
                  </tr>
                </Fragment>
              )
            })
          ) : (
            <tr>
              <td className="cell-align-center" style={{ gridColumn: fillRow }}>
                <div className="table__no-item">
                  <Icon icon={noDataIcon} />
                  {noDataText}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination changePage={changePage} page={page} maxPage={maxPage} />
    </div>
  )
}

export default Table
